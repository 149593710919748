import React, { useEffect, useState } from 'react'

import LoadingButton from '@mui/lab/LoadingButton';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField, Tooltip } from '@mui/material';
import { isEmptyOrUndefined } from '@zippeditoolsjs/blocks';
import { IconComponent } from '@zippeditoolsjs/zippedi-icons';

export default function AddEditDialog(props) {
  const {
    t,
    openAddEditDialog,
    setOpenAddEditDialog,
    changeType,
    selectedCategory,
    postParameterCategories,
    isLoadingParameterCategoriesPostResponse,
    patchParameterCategories,
    isLoadingParameterCategoriesPatchResponse,
  } = props;
  // States
  const [name, setName] = useState('');

  // Lifecycle methods

  // Set the values of the existing category when editing
  useEffect(() => {
    if (changeType === 'edit') {
      setName(selectedCategory.name);
    }
  }, [changeType, selectedCategory])

  // Methods

  const handleSaveChanges = () => {
    let data = {
      parameter_categories: []
    }

    // PATCH function for editing an existing parameter
    if (changeType === 'edit') {
      data["parameter_categories"].push({ category_id: selectedCategory.category_id, name: name })
      patchParameterCategories(data)
    } else {
      // POST function for adding a new parameter
      data["parameter_categories"].push({ name: name })
      postParameterCategories(data)
    }
  };

  const handleClose = () => {
    setName('');
    setOpenAddEditDialog(false);
  };

  return (
    <Dialog
      fullWidth
      maxWidth='sm'
      open={openAddEditDialog}
      onClose={handleClose}
    >
      {/* Dialog title */}
      {changeType === 'edit' ?
        <DialogTitle>{t('overseer_app.parameters.Edit_category', 'Edit Category')}</DialogTitle>
        :
        <DialogTitle>{t('overseer_app.parameters.New_category', 'New Category')}</DialogTitle>
      }
      {/* Dialog content */}
      <DialogContent dividers>
        {/* Name */}
        <TextField
          fullWidth
          value={name}
          label={t('overseer_app.parameters.Name', 'Name')}
          onChange={(event) => setName(event.target.value)}
        />
      </DialogContent>
      {/* Dialog actions */}
      <DialogActions>
        <Button autoFocus onClick={handleClose}>
          {t('overseer_app.general.Cancel', 'Cancel')}
        </Button>
        <Tooltip
          title={
            isEmptyOrUndefined(name, 'id') ?
              t('overseer_app.parameters.Missing_required_values', 'Missing required values')
              :
              ''
          }
        >
          <span>
            <LoadingButton
              loading={isLoadingParameterCategoriesPostResponse || isLoadingParameterCategoriesPatchResponse}
              onClick={handleSaveChanges}
              loadingPosition="start"
              startIcon={
                <IconComponent
                  iconName={'save'}
                  style={{ fontSize: "20px" }}
                />}
              disabled={isEmptyOrUndefined(name, 'id')}
            >
              {t('overseer_app.general.Save', 'Save')}
            </LoadingButton>
          </span>
        </Tooltip>
      </DialogActions>
    </Dialog>
  )
}
