import { useCallback, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setSnackAlert } from "core/slices/snackAlert";
import { transformPixels2Robot } from '../Utils';
import useCanvas from "./useCanvas";
import { drawRectangleOnCanvas, canvasDrawOnLine, clearCanvas, drawPinOnCanvas } from '../utils/canvasFunctions';

export const LAYOUT_TOOLS = {
    MOVE: 'move',
    ZONE: 'zones',
    AISLE: 'aisles',
    EXCLUSION_ZONE: 'exclusion_zones',
    AISLE_SIDE: 'aisle_side',
    HOME: 'home'
}

const MIN_DISTANCE_BETWEEN_POINTS = 50; // TODO: Check Area or distance between points to draw a zone / aisle / etc

function isDistanceBetweenPoints(point1, point2) {
    const distance = Math.sqrt(Math.pow(point1.x - point2.x, 2) + Math.pow(point1.y - point2.y, 2));
    return distance > MIN_DISTANCE_BETWEEN_POINTS;
}

export default function useLayoutAnnotations(storeMapCanvasRef, mapMetadata, colissionLayers) {
    const [canvasDrawRef, canvasDraw] = useCanvas();
    const [temporalDraw, setTemporalDraw] = useState(null);
    const [activeTool, setActiveTool] = useState(null);
    const [drawColor, setDrawColor] = useState('#FD7E14');

    const dispatch = useDispatch();

    useEffect(() => {
        if (canvasDrawRef?.current && mapMetadata) {
            canvasDrawRef.current.width = mapMetadata.width;
            canvasDrawRef.current.height = mapMetadata.height;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [canvasDrawRef, mapMetadata]);

    const transformToRobot = useCallback((data) => {
        if (!mapMetadata) return;
        const origin = [mapMetadata?.origin?.x, mapMetadata?.origin?.y];
        const resolution = mapMetadata?.resolution;
        const originalHeight = mapMetadata?.height
        return transformPixels2Robot(data.x, data.y, origin, resolution, originalHeight);
    }, [mapMetadata]);

    const handleDragMove = useCallback(() => {
        if (!storeMapCanvasRef?.current || !storeMapCanvasRef.current.parentElement) return;
        let isDragging = false;
        let isOutside = false;
        let autoScrollInterval = null;
        let start = { x: 0, y: 0 };
        let startScroll = { left: 0, top: 0 };
        let outsidePosition = { x: 0, y: 0 };
        const storeMapParent = storeMapCanvasRef.current.parentElement;
        storeMapCanvasRef.current.style.cursor = 'grab';


        const startAutoScroll = () => {
            if (autoScrollInterval) return;
            autoScrollInterval = setInterval(() => {
                if (!isOutside) {
                    clearInterval(autoScrollInterval);
                    autoScrollInterval = null;
                    return;
                }
                // TODO: Do logic to auto scroll to the outside position in same direction
            }, 10);
        }
        const onMouseDown = (event) => {
            isDragging = true;
            start = { x: event.clientX, y: event.clientY };
            storeMapParent.style.overflow = 'auto';
            startScroll = { left: storeMapParent.scrollLeft, top: storeMapParent.scrollTop };
            event.preventDefault();
        };
        const onMouseMove = (event) => {
            if (!isDragging) return;
            const deltaX = start.x - event.clientX;
            const deltaY = start.y - event.clientY;
            const newScrollLeft = Math.max(0, Math.min(startScroll.left + deltaX, storeMapParent.scrollWidth - storeMapParent.offsetWidth));
            const newScrollTop = Math.max(0, Math.min(startScroll.top + deltaY, storeMapParent.scrollHeight - storeMapParent.offsetHeight));
            storeMapParent.scrollLeft = newScrollLeft;
            storeMapParent.scrollTop = newScrollTop;
        };
        const onMouseUp = () => {
            isDragging = false;
            storeMapParent.style.overflow = 'hidden';
        };
        const onMouseLeave = () => {
            isOutside = true;
            isDragging = false;
        }
        const onMouseEnter = () => {
            isOutside = false;
            isDragging = false;
            storeMapParent.style.overflow = 'hidden';
            if (autoScrollInterval) {
                clearInterval(autoScrollInterval);
                autoScrollInterval = null;
            }
        }
        const onMouseMoveOutside = (event) => {
            outsidePosition = { x: event.clientX, y: event.clientY };
            startAutoScroll();
        }
        storeMapCanvasRef.current.addEventListener('mousedown', onMouseDown);
        storeMapCanvasRef.current.addEventListener('mouseup', onMouseUp);
        storeMapCanvasRef.current.addEventListener('mousemove', onMouseMove);
        storeMapCanvasRef.current.addEventListener('mouseleave', onMouseLeave);
        storeMapCanvasRef.current.addEventListener('mouseenter', onMouseEnter);
        document.addEventListener('mousemove', onMouseMoveOutside);
        return () => {
            storeMapCanvasRef?.current?.removeEventListener('mousedown', onMouseDown);
            storeMapCanvasRef?.current?.removeEventListener('mousemove', onMouseMove);
            storeMapCanvasRef?.current?.removeEventListener('mouseup', onMouseUp);
            storeMapCanvasRef?.current?.removeEventListener('mouseleave', onMouseLeave);
            storeMapCanvasRef?.current?.removeEventListener('mouseenter', onMouseEnter);
            document.removeEventListener('mousemove', onMouseMoveOutside);
            if (storeMapCanvasRef?.current) {
                storeMapCanvasRef.current.style.cursor = 'default';
            }
        };
    }, [storeMapCanvasRef]);

    const handleNewHome = useCallback((callbackFunction) => {
        if (!storeMapCanvasRef?.current || !storeMapCanvasRef.current.parentElement) return;
        let isDragging = false;
        let handleAng = false;
        const bounding = storeMapCanvasRef?.current?.getBoundingClientRect();
        let startPoint = { x: 0, y: 0, ang: 0 };
        let clickCount = 0;

        const onMouseDown = (event) => {
            if (clickCount === 0) {
                startPoint = { x: event.clientX - bounding.left, y: event.clientY - bounding.top, ang: -Math.PI / 2, name: 'dock' };
                isDragging = true;
                setTemporalDraw({ type: 'pose', bbox: [startPoint] });
            } else if (clickCount === 1) {
                isDragging = false;
                handleAng = true;
                dispatch(setSnackAlert({
                    open: true,
                    severity: 'info',
                    message: 'Adjust angle of the home point and press again to confirm',
                }));
            } else if (clickCount === 2) {
                isDragging = false;
                handleAng = false;
                const newX = startPoint.x + 30 * Math.cos(startPoint.ang + Math.PI)
                const newY = startPoint.y + 30 * Math.sin(startPoint.ang + Math.PI)
                const base = { x: newX, y: newY, ang: startPoint.ang + Math.PI, name: 'base' };
                const exit_jail = { x: newX, y: newY, ang: startPoint.ang, name: 'exit_jail' };
                setTemporalDraw(prev => ({
                    ...prev,
                    bbox: [
                        ...prev.bbox,
                        base, exit_jail
                    ]
                }));
                dispatch(setSnackAlert(null));
                const transformDock = transformToRobot(startPoint);
                const transformBase = transformToRobot(base);
                const transformExitJail = transformToRobot(exit_jail);
                callbackFunction?.({
                    dock: { x: transformDock[0], y: transformDock[1], ang: startPoint.ang + Math.PI / 2, name: 'dock' },
                    base: { x: transformBase[0], y: transformBase[1], ang: startPoint.ang + 1.5 * Math.PI, name: 'base' },
                    exit_jail: { x: transformExitJail[0], y: transformExitJail[1], ang: startPoint.ang + Math.PI / 2, name: 'exit_jail' }
                });
            }
            clickCount += 1
            clickCount = clickCount % 3
        }
        const onMouseMove = (event) => {
            if (isDragging) {
                startPoint = { x: event.clientX - bounding.left, y: event.clientY - bounding.top, ang: -Math.PI / 2, name: 'dock' };
                setTemporalDraw({ type: 'pose', bbox: [startPoint] });
                return;
            }
            if (handleAng) {
                const newX = event.clientX - bounding.left;
                const newY = event.clientY - bounding.top;
                const dx = newX - startPoint.x;
                const dy = newY - startPoint.y;
                const newAngle = Math.atan2(dy, dx) + Math.PI;
                startPoint = { ...startPoint, ang: newAngle };
                setTemporalDraw(prev => ({
                    ...prev,
                    bbox: [{ ...prev.bbox[0], ang: newAngle }]
                }));
                return;
            }
        }
        storeMapCanvasRef.current.addEventListener('mousedown', onMouseDown);
        storeMapCanvasRef.current.addEventListener('mousemove', onMouseMove);
        return () => {
            storeMapCanvasRef?.current?.removeEventListener('mousedown', onMouseDown);
            storeMapCanvasRef?.current?.removeEventListener('mousemove', onMouseMove);
            setTemporalDraw(null);
            dispatch(setSnackAlert(null));
        }
    }, [storeMapCanvasRef, dispatch, transformToRobot]);

    const handleNewDraw = useCallback((callbackFunction, type = 'rectangle', needColission = false, colissionLayer = null) => {
        if (!storeMapCanvasRef?.current) return;
        const bounding = storeMapCanvasRef?.current?.getBoundingClientRect();
        let startPoint = { x: 0, y: 0 };
        let endPoint = { x: 0, y: 0 };
        let isDrawing = false;

        const onMouseDown = (event) => {
            isDrawing = true;
            setTemporalDraw(null);
            startPoint = {
                x: event.clientX - bounding.left,
                y: event.clientY - bounding.top,
            };
        };

        const onMouseMove = (event) => {
            if (!isDrawing) return;
            endPoint = { x: event.clientX - bounding.left, y: event.clientY - bounding.top };
            setTemporalDraw({ type, bbox: [startPoint, endPoint] });
        };

        const onMouseUp = (event) => {
            onMouseMove(event);
            isDrawing = false;
            if (isDistanceBetweenPoints(startPoint, endPoint)) {
                if (needColission && colissionLayer) {
                    const start = transformToRobot(startPoint);
                    const end = transformToRobot(endPoint);
                    const colissionZones = colissionLayers(colissionLayer, { start: { x: start[0], y: start[1] }, end: { x: end[0], y: end[1] } }, type, 'partial');
                    if (colissionZones.length === 0) {
                        const snack = {
                            open: true,
                            message: 'No collision with an aisle was found in the area of ​​the drawing',
                            severity: 'error',
                        };
                        dispatch(setSnackAlert(snack));
                        setTemporalDraw(null);
                        return;
                    }
                }
                callbackFunction?.({
                    start: transformToRobot(startPoint),
                    end: transformToRobot(endPoint)
                });
                startPoint = { x: 0, y: 0 };
                endPoint = { x: 0, y: 0 };
                return;
            }
            setTemporalDraw(null);
        };

        const onMouseLeave = (event) => {
            if (!isDrawing) return;
            if (isDistanceBetweenPoints(startPoint, endPoint)) {
                callbackFunction?.({
                    start: transformToRobot(startPoint),
                    end: transformToRobot(endPoint)
                });
                startPoint = { x: 0, y: 0 };
                endPoint = { x: 0, y: 0 };
            } else {
                setTemporalDraw(null);
            }
            isDrawing = false;
        }

        storeMapCanvasRef.current.addEventListener('mousedown', onMouseDown);
        storeMapCanvasRef.current.addEventListener('mousemove', onMouseMove);
        storeMapCanvasRef.current.addEventListener('mouseup', onMouseUp);
        storeMapCanvasRef.current.addEventListener('mouseleave', onMouseLeave);

        return () => {
            storeMapCanvasRef?.current?.removeEventListener('mousedown', onMouseDown);
            storeMapCanvasRef?.current?.removeEventListener('mousemove', onMouseMove);
            storeMapCanvasRef?.current?.removeEventListener('mouseup', onMouseUp);
            storeMapCanvasRef?.current?.removeEventListener('mouseleave', onMouseLeave);
            setTemporalDraw(null);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [storeMapCanvasRef, transformToRobot, colissionLayers]);

    useEffect(() => {
        if (!activeTool) return;
        let handlerFunction = null;
        const [tool, callbackFunction] = Array.isArray(activeTool) ? activeTool : [activeTool, null];
        switch (tool) {
            case LAYOUT_TOOLS.MOVE:
                handlerFunction = handleDragMove();
                break;
            case LAYOUT_TOOLS.ZONE:
                handlerFunction = handleNewDraw(callbackFunction);
                break;
            case LAYOUT_TOOLS.AISLE:
                handlerFunction = handleNewDraw(callbackFunction);
                break;
            case LAYOUT_TOOLS.EXCLUSION_ZONE:
                handlerFunction = handleNewDraw(callbackFunction);
                break;
            case LAYOUT_TOOLS.AISLE_SIDE:
                handlerFunction = handleNewDraw(callbackFunction, 'line', true, 'capture');
                break;
            case LAYOUT_TOOLS.HOME:
                dispatch(setSnackAlert({
                    open: true,
                    message: 'Select a home point and press again to confirm initial point',
                    severity: 'info',
                }));
                handlerFunction = handleNewHome(callbackFunction);
                break;
            default:
                break;
        }
        return () => {
            // Way to remove listeners from the canvas
            handlerFunction?.();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTool]);

    const drawMultiplesPoses = useCallback((ctx, poses) => {
        if (!poses) return;
        poses?.filter(pose => pose)?.forEach(pose => {
            const { x, y, ang = -Math.PI / 2 } = pose;
            const angle = ang + Math.PI / 2;
            drawPinOnCanvas(ctx, x, y, angle);
        });
    }, []);

    const drawTemporal = useCallback(() => {
        if (!temporalDraw) return;
        const { type, bbox } = temporalDraw;
        const [firstPoint, secondPoint] = bbox;
        let drawerFunction = null;
        let args = null;
        switch (type) {
            case 'rectangle':
                const width = secondPoint.x - firstPoint.x;
                const height = secondPoint.y - firstPoint.y;
                const color = drawColor || '#212529EE';
                const colorFill = drawColor ? `${drawColor}55` : 'rgba(255, 164, 0, 0.15)'
                drawerFunction = drawRectangleOnCanvas;
                args = [firstPoint.x, firstPoint.y, width, height, colorFill, color];
                break;
            case 'line':
                drawerFunction = canvasDrawOnLine;
                const pxInit = { x: firstPoint.x, y: firstPoint.y }
                const pxEnd = { x: secondPoint.x, y: secondPoint.y }
                args = [{ pxInit, pxEnd }, drawColor, 4]
                break;
            case 'pose':
                drawerFunction = drawMultiplesPoses;
                const [dock = null, base = null, exit_jail = null] = bbox;
                args = [[dock, base, exit_jail]];
                break;
            default:
                break;
        }
        if (!drawerFunction) return;
        canvasDraw(drawerFunction, args);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [temporalDraw, drawColor]);

    useEffect(() => {
        if (!mapMetadata || !canvasDrawRef?.current) return;
        const { width, height } = mapMetadata;
        canvasDraw(clearCanvas, [width, height]);
        drawTemporal();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [canvasDrawRef, temporalDraw, drawTemporal, drawColor, mapMetadata]);

    const resetDraw = useCallback(() => {
        setTemporalDraw(null);
    }, []);

    return {
        canvasDrawRef,
        activeTool,
        setActiveTool,
        updateDrawColor: setDrawColor,
        resetDraw
    }
}