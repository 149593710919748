import {
  activeAlerts,
  detailedUserInfo,
  getActiveHardwareAlertsList,
  getAvailableRobotStateMotives,
  getAvailableStatusData,
  getBrokerUrl,
  getCallMotivesResponse,
  getCallerHistoryList,
  getChangeMotivesList,
  getFeaturePermission,
  getHeatMapMappingAlerts,
  getHeatMapMappingAlertsLoading,
  getHeatmapSignal,
  getIsLoadingMapSignalInfo,
  getIsLoadingStoreInfo,
  getLeaveRobotResponse,
  getNewCallerActionResponse,
  getNewServicingResponse,
  getProductsList,
  getRobotActionsList,
  getRobotBlockingList,
  getRobotComponentsList,
  getRobotStateData,
  getRobotStateUpdateResponse,
  getRobotsByStatusInfo,
  getRobotsTakenList,
  getStoreCountryResponse,
  getStoreInfo,
  getStoreMapInfo,
  getTakeRobotResponse,
  isLoadingBrokerUrl,
  isLoadingChangeMotives,
  isLoadingLeaveRobot,
  isLoadingNewServicing,
  isLoadingProductsList,
  isLoadingRobotActions,
  isLoadingRobotBlocking,
  isLoadingRobotComponents,
  isLoadingRobotStateUpdate,
  isLoadingRobotsByStatus,
  isLoadingRobotsTaken,
  isLoadingTakeRobot,
  linkReceived,
  loadingCallMotives,
  loadingCallerHistory,
  loadingNewCallerActionResponse,
  loadingStoreCountry,
  userInfo,
} from 'core/selectors';
import { getStoreMap, postNewMap } from 'core/slices/actionWidget';
import { getActiveAlerts } from 'core/slices/alertsWidget';
import { checkFeaturePermission, getBroker, getCountryByStore, getStore } from 'core/slices/backend';
import { getActiveHardwareAlerts } from 'core/slices/hardwareAlertsWidget';
import {
  getAvailableRobotStatus,
  getCallMotives,
  getCallerHistory,
  getMapSignal,
  getMappingAlerts,
  getProductsByName,
  getRobotActions,
  getRobotBlocking,
  getRobotState,
  getRobotStateMotives,
  getRobotsByStatus,
  getRobotsTaken,
  leaveRobot,
  postActionReason,
  postCallerAction,
  postUserActions,
  takeRobot,
  topicMessageFeedback,
  updateRobotState,
} from 'core/slices/robotMonitoring';
import {
  getChangeMotives,
  getRobotComponents,
  postNewServicing,
} from 'core/slices/robotServicing';
import { getUserInfo } from 'core/slices/session';
import { setSnackAlert } from 'core/slices/snackAlert';
import { connect } from 'react-redux';
import RobotView from '../../../components/robotView/RobotView';

export default connect(
  (state) => ({
    robotActionsList: getRobotActionsList(state),
    isLoadingRobotActions: isLoadingRobotActions(state),
    robotsTakenList: getRobotsTakenList(state),
    isLoadingRobotsTaken: isLoadingRobotsTaken(state),
    takeRobotResponse: getTakeRobotResponse(state),
    isLoadingTakeRobot: isLoadingTakeRobot(state),
    leaveRobotResponse: getLeaveRobotResponse(state),
    isLoadingLeaveRobot: isLoadingLeaveRobot(state),
    robotBlocking: getRobotBlockingList(state),
    isLoadingRobotBlocking: isLoadingRobotBlocking(state),
    newServicingResponse: getNewServicingResponse(state),
    isLoadingNewServicing: isLoadingNewServicing(state),
    changeMotivesList: getChangeMotivesList(state),
    isLoadingChangeMotives: isLoadingChangeMotives(state),
    robotComponentsList: getRobotComponentsList(state),
    isLoadingRobotComponents: isLoadingRobotComponents(state),
    productsList: getProductsList(state),
    isLoadingProductsList: isLoadingProductsList(state),
    brokerUrl: getBrokerUrl(state),
    isLoadingBrokerUrl: isLoadingBrokerUrl(state),
    storeMapInfo: getStoreMapInfo(state),
    storeInfo: getStoreInfo(state),
    isLoadingStoreInfo: getIsLoadingStoreInfo(state),
    userInfo: userInfo(state),
    detailedUserInfo: detailedUserInfo(state),
    activeAlerts: activeAlerts(state),
    mapSignal: getHeatmapSignal(state),
    isLoadingMapSignalInfo: getIsLoadingMapSignalInfo(state),
    featurePermission: getFeaturePermission(state),
    getHeatMapMappingAlerts: getHeatMapMappingAlerts(state),
    getHeatMapMappingAlertsLoading: getHeatMapMappingAlertsLoading(state),
    robotStateInfo: getRobotStateData(state),
    availableStatus: getAvailableStatusData(state),
    robotStateUpdateResponse: getRobotStateUpdateResponse(state),
    loadingNewRobotState: isLoadingRobotStateUpdate(state),
    robotStateMotives: getAvailableRobotStateMotives(state),
    hardwareActiveAlerts: getActiveHardwareAlertsList(state),
    callerHistory: getCallerHistoryList(state),
    loadingCallerHistory: loadingCallerHistory(state),
    newCallerActionResponse: getNewCallerActionResponse(state),
    loadingNewCallerActionResponse: loadingNewCallerActionResponse(state),
    storeCountry: getStoreCountryResponse(state),
    loadingStoreCountry: loadingStoreCountry(state),
    callMotives: getCallMotivesResponse(state),
    loadingCallMotives: loadingCallMotives(state),
    linkReceived: linkReceived(state),
    mappingAlerts: getHeatMapMappingAlerts(state),
    isMappingAlertsLoading: getHeatMapMappingAlertsLoading(state),
    robotsByStatus: getRobotsByStatusInfo(state),
    isLoadingRobotsByStatus: isLoadingRobotsByStatus(state),
  }),
  {
    getRobotActions,
    topicMessageFeedback,
    postUserActions,
    getRobotsTaken,
    takeRobot,
    leaveRobot,
    getRobotBlocking,
    postNewServicing,
    getChangeMotives,
    getRobotComponents,
    setSnackAlert,
    getProductsByName,
    getBroker,
    getActiveAlerts,
    postNewMap,
    getStoreMap,
    getStore,
    getUserInfo,
    getMapSignal,
    getMappingAlerts,
    checkFeaturePermission,
    getRobotState,
    getAvailableRobotStatus,
    updateRobotState,
    getRobotStateMotives,
    getActiveHardwareAlerts,
    postActionReason,
    getCallerHistory,
    postCallerAction,
    getCountryByStore,
    getCallMotives,
    getRobotsByStatus,
  }
)(RobotView);
