import {
  getChainsList,
  getFeaturePermission,
  getRobotListBaldur,
  groupedRobotParameters,
  isLoadingChains,
  isLoadingGroupedRobotParameters,
  isLoadingParameterCategories,
  isLoadingParameterCategoriesDeleteResponse,
  isLoadingParameterCategoriesPatchResponse,
  isLoadingParameterCategoriesPostResponse,
  isLoadingParameterDataTypes,
  isLoadingParameters,
  isLoadingParametersDeleteResponse,
  isLoadingParametersPatchResponse,
  isLoadingParametersPostResponse,
  isLoadingRobotListBaldur,
  isLoadingRobotParameters,
  isLoadingRobotParamsPost,
  parameterCategories,
  parameterCategoriesDeleteResponse,
  parameterCategoriesPatchResponse,
  parameterCategoriesPostResponse,
  parameterDataTypes,
  parameters,
  parametersDeleteResponse,
  parametersPatchResponse,
  parametersPostResponse,
  robotParameters,
  robotParamsPostResponse,
  robotStatuses
} from 'core/selectors';
import { getChains } from 'core/slices/backend';
import { checkFeaturePermission } from 'core/slices/backend';
import { deleteParameterCategories, deleteParameters, getGroupedRobotParameters, getParameterCategories, getParameterDataTypes, getParameters, getRobotParameters, patchParameterCategories, patchParameters, postParameterCategories, postParameters, postRobotParameters } from 'core/slices/baldur/parameters';
import { getRobotsBaldur } from 'core/slices/fleetControl';
import { getRobotStatuses } from 'core/slices/robotStatus';
import { setSnackAlert } from 'core/slices/snackAlert';
import { connect } from 'react-redux';

import ParametersHome from "../../../components/parameters/ParametersHome";

export default connect(
  (state) => ({
    chains: getChainsList(state),
    isLoadingChains: isLoadingChains(state),
    robots: getRobotListBaldur(state),
    isLoadingRobots: isLoadingRobotListBaldur(state),
    robotParameters: robotParameters(state),
    isLoadingRobotParameters: isLoadingRobotParameters(state),
    groupedRobotParameters: groupedRobotParameters(state),
    isLoadingGroupedRobotParameters: isLoadingGroupedRobotParameters(state),
    parameters: parameters(state),
    isLoadingParameters: isLoadingParameters(state),
    parametersPostResponse: parametersPostResponse(state),
    isLoadingParametersPostResponse: isLoadingParametersPostResponse(state),
    parametersPatchResponse: parametersPatchResponse(state),
    isLoadingParametersPatchResponse: isLoadingParametersPatchResponse(state),
    parametersDeleteResponse: parametersDeleteResponse(state),
    isLoadingParametersDeleteResponse: isLoadingParametersDeleteResponse(state),
    parameterDataTypes: parameterDataTypes(state),
    isLoadingParameterDataTypes: isLoadingParameterDataTypes(state),
    parameterCategories: parameterCategories(state),
    isLoadingParameterCategories: isLoadingParameterCategories(state),
    parameterCategoriesPostResponse: parameterCategoriesPostResponse(state),
    isLoadingParameterCategoriesPostResponse: isLoadingParameterCategoriesPostResponse(state),
    parameterCategoriesPatchResponse: parameterCategoriesPatchResponse(state),
    isLoadingParameterCategoriesPatchResponse: isLoadingParameterCategoriesPatchResponse(state),
    parameterCategoriesDeleteResponse: parameterCategoriesDeleteResponse(state),
    isLoadingParameterCategoriesDeleteResponse: isLoadingParameterCategoriesDeleteResponse(state),
    robotParamsPostResponse: robotParamsPostResponse(state),
    isLoadingRobotParamsPost: isLoadingRobotParamsPost(state),
    robotStatuses: robotStatuses(state),
    featurePermission: getFeaturePermission(state),
  }),
  {
    getChains,
    getRobotsBaldur,
    getRobotParameters,
    getGroupedRobotParameters,
    getParameters,
    postParameters,
    patchParameters,
    deleteParameters,
    getParameterDataTypes,
    getParameterCategories,
    postParameterCategories,
    patchParameterCategories,
    deleteParameterCategories,
    postRobotParameters,
    setSnackAlert,
    getRobotStatuses,
    checkFeaturePermission,
  }
)(ParametersHome);
