import { Fragment, useEffect, useState } from 'react';

import {
  Box,
  Card,
  CircularProgress,
  Divider,
  Grid,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from '@mui/material';
import { IconComponent, isIconAvailable } from '@zippeditoolsjs/zippedi-icons';
import { useTranslation } from 'react-i18next';
import TabPanel from '../tools/TabPanel';

import { findIconDefinition, library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import AlertBox from '../tools/AlertBox';
import { a11yProps, colorIcon } from '../Utils';
import { handleElapsedTimePair } from '../utils/commons';

function AlertsWidget(props) {
  const {
    getHistoricAlerts,
    historicAlerts,
    loadingHistoricAlerts,
    getActiveAlerts,
    activeAlerts,
    loadingActiveAlerts,
    store,
    robot,
    isNoRobotSelected,
  } = props;
  library.add(fas);

  const [tabIndex, setTabIndex] = useState(0);
  const [activeMonitorAlerts, setActiveMonitorAlerts] = useState([]);
  const [excludedMonitorAlerts, setExcludedMonitorAlerts] = useState([]);
  const { t } = useTranslation();

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  useEffect(() => {
    getHistoricAlerts({ robot_id: `${store}-${robot}` });
    getActiveAlerts({ robot_id: `${store}-${robot}` });
  }, [store, robot]);

  useEffect(() => {
    const active = [];
    const excluded = [];
    activeAlerts.forEach((alert) => {
      if (alert.monitor_assignation) active.push(alert);
      else excluded.push(alert);
    });
    setActiveMonitorAlerts(active);
    setExcludedMonitorAlerts(excluded);
  }, [activeAlerts]);

  const handleColorAlert = (alert) => {
    return colorIcon(alert.monitor_assignation ? 'error' : 'warn');
  };

  return loadingHistoricAlerts || loadingActiveAlerts ? (
    <Box
      variant="rectangular"
      height={'100%'}
      minHeight={250}
      sx={{
        backgroundColor: '#FFF',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 2,
      }}
    >
      <CircularProgress />
      <Typography>Loading Alerts...</Typography>
    </Box>
  ) : (
    <Card
      sx={{
        height: '100%',
      }}
    >
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          aria-label="basic tabs example"
        >
          <Tab
            label={t('overseer_app.widget.alerts', 'Alerts')}
            {...a11yProps(0, { textTransform: 'initial' })}
          />
          <Tab
            label={t('overseer_app.widget.warnings', 'Warnings')}
            {...a11yProps(1, { textTransform: 'initial' })}
            disabled={isNoRobotSelected}
          />
          <Tab
            label={t('overseer_app.widget.card_alerts_history', 'History')}
            {...a11yProps(2, { textTransform: 'initial' })}
            disabled={isNoRobotSelected}
          />
        </Tabs>
      </Box>
      <TabPanel value={tabIndex} index={0}>
        <Grid container>
          <Grid item xs={12} sx={{ overflowY: 'auto', maxHeight: 410, px: 2 }}>
            {isNoRobotSelected ? (
              <AlertBox
                severity="info"
                content={t('overseer_app.general.no_robot_selected', 'No robot selected')}
                sx={{ my: 1 }}
              />
            ) : activeMonitorAlerts.length > 0 ? (
              activeMonitorAlerts.map((alert, idx) => (
                <Fragment key={`active-alerts-list-${idx}`}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1,
                      p: 1,
                    }}
                  >
                    <Box
                      sx={{
                        marginLeft: 1,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        p: 0.5,
                      }}
                    >
                      {isIconAvailable({ iconName: alert.icon }) ? (
                        <IconComponent
                          iconName={alert.icon}
                          style={{
                            fontSize: '27px',
                            color: handleColorAlert(alert),
                          }}
                        />
                      ) : findIconDefinition({
                        iconName: alert.icon.slice(3),
                      }) ? (
                        <FontAwesomeIcon
                          icon={alert.icon}
                          color={handleColorAlert(alert)}
                          size="xl"
                        />
                      ) : (
                        <IconComponent
                          iconName={'warning'}
                          style={{
                            fontSize: '27px',
                            color: handleColorAlert(alert),
                          }}
                        />
                      )}
                    </Box>
                    <Box>
                      <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>
                        {alert.description}
                      </Typography>
                      <Tooltip title={alert.created_at}>
                        <Typography sx={{ fontSize: '12px' }}>
                          {t('overseer_app.general.creation', 'Creation')}: {handleElapsedTimePair(alert?.created_at, 'dd/MM/yyyy, HH:mm', t)}
                        </Typography>
                      </Tooltip>
                    </Box>
                  </Box>
                  <Divider />
                </Fragment>
              ))
            ) : (
              <AlertBox
                severity="success"
                content={t(
                  'overseer_app.widget.no_active_alerts',
                  'No active alerts'
                )}
                sx={{ my: 1 }}
              />
            )}
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={tabIndex} index={1}>
        {isNoRobotSelected ? (
          <AlertBox
            severity="info"
            content={t('overseer_app.general.no_robot_selected', 'No robot selected')}
            sx={{ my: 1 }}
          />
        ) : (
          <Grid container>
            <Grid item xs={12} sx={{ overflowY: 'auto', maxHeight: 410, px: 2 }}>
              {excludedMonitorAlerts.length > 0 ? (
                excludedMonitorAlerts.map((alert, idx) => (
                  <Fragment key={`active-alerts-list-${idx}`}>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        p: 1,
                      }}
                    >
                      <Box
                        sx={{
                          marginLeft: 1,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          p: 0.5,
                        }}
                      >
                        {isIconAvailable({ iconName: alert.icon }) ? (
                          <IconComponent
                            iconName={alert.icon}
                            style={{
                              fontSize: '27px',
                              color: handleColorAlert(alert),
                            }}
                          />
                        ) : findIconDefinition({
                          iconName: alert.icon.slice(3),
                        }) ? (
                          <FontAwesomeIcon
                            icon={alert.icon}
                            color={handleColorAlert(alert)}
                            size="xl"
                          />
                        ) : (
                          <IconComponent
                            iconName={'warning'}
                            style={{
                              fontSize: '27px',
                              color: handleColorAlert(alert),
                            }}
                          />
                        )}
                      </Box>
                      <Box>
                        <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>
                          {alert.description}
                        </Typography>
                        <Tooltip title={alert.created_at}>
                          <Typography sx={{ fontSize: '12px' }}>
                            {t('overseer_app.general.creation', 'Creation')}: {handleElapsedTimePair(alert?.created_at, 'dd/MM/yyyy, HH:mm', t)}
                          </Typography>
                        </Tooltip>
                      </Box>
                    </Box>
                    <Divider />
                  </Fragment>
                ))
              ) : (
                <AlertBox
                  severity="success"
                  content={t(
                    'overseer_app.widget.no_active_alerts',
                    'No active alerts'
                  )}
                  sx={{ my: 1 }}
                />
              )}
            </Grid>
          </Grid>
        )}
      </TabPanel>
      <TabPanel value={tabIndex} index={2}>
        {isNoRobotSelected ? (
          <AlertBox
            severity="info"
            content={t('overseer_app.general.no_robot_selected', 'No robot selected')}
            sx={{ my: 1 }}
          />
        ) : (
          <Grid container>
            <Grid item xs={12} sx={{ overflowY: 'auto', px: 2, maxHeight: 410 }}>
              {historicAlerts.length > 0 ? (
                historicAlerts.map((alert, idx) => (
                  <Fragment key={`historic-alerts-list-${idx}`}>
                    <Box
                      key={idx}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        p: 1,
                      }}
                    >
                      <Box
                        sx={{
                          marginLeft: 1,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          p: 0.5,
                        }}
                      >
                        {isIconAvailable({ iconName: alert.icon }) ? (
                          <IconComponent
                            iconName={alert.icon}
                            style={{
                              fontSize: '27px',
                              color: handleColorAlert(alert),
                            }}
                          />
                        ) : findIconDefinition({
                          iconName: alert.icon.slice(3),
                        }) ? (
                          <FontAwesomeIcon
                            icon={alert.icon}
                            color={handleColorAlert(alert)}
                            size="xl"
                          />
                        ) : (
                          <IconComponent
                            iconName={'warning'}
                            style={{
                              fontSize: '27px',
                              color: handleColorAlert(alert),
                            }}
                          />
                        )}
                      </Box>
                      <Box>
                        <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>
                          {alert.description}
                        </Typography>
                        <Tooltip title={alert.created_at}>
                          <Typography sx={{ fontSize: '12px' }}>
                            {t('overseer_app.general.creation', 'Creation')}: {handleElapsedTimePair(alert?.created_at, 'dd/MM/yyyy, HH:mm', t)}
                          </Typography>
                        </Tooltip>
                        {alert.supressed_at ? (
                          <Tooltip title={alert.supressed_at}>
                            <Typography sx={{ fontSize: '12px' }}>
                              {t('overseer_app.general.suppression', 'Suppression')}
                              : {handleElapsedTimePair(alert?.supressed_at, 'dd/MM/yyyy, HH:mm', t)}
                            </Typography>
                          </Tooltip>
                        ) : null}
                      </Box>
                    </Box>
                    <Divider />
                  </Fragment>
                ))
              ) : (
                <AlertBox
                  severity="success"
                  content={t(
                    'overseer_app.widget.no_alerts_24',
                    'No alerts in the last 24 hours...'
                  )}
                  sx={{ my: 1 }}
                />
              )}
            </Grid>
          </Grid>
        )}
      </TabPanel>
    </Card>
  );
}

export default AlertsWidget;
