import { Grid, Collapse, Alert, TextField, MenuItem, FormControlLabel, Radio, Box, Divider, FormLabel, RadioGroup } from '@mui/material';
import { forwardRef, useMemo, useState, useEffect, useCallback, useImperativeHandle } from 'react';
import { EDITABLE_LAYERS } from '../tools/useLayout';
import { useTranslation } from 'react-i18next';
import { boldString } from "../Utils";

const ColorRadio = ({ color }) => {
    return <FormControlLabel value={color} control={<Radio size="small" />} label={<Box sx={{ width: 30, height: 15, backgroundColor: color, borderRadius: '12px' }} />} />
}

const COLORS = ['#FD7E14', '#0D6EFD', '#11CAF0', '#9B14FE', '#02B936'];


export default forwardRef(function DialogEditZone({
    data,
    modifiedLayout,
    isUniqueName,
    editAnnotation,
}, ref) {
    const { t } = useTranslation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const originalValues = useMemo(() => modifiedLayout?.[EDITABLE_LAYERS.ZONES]?.[data?.name] || {}, [data]);

    const defaultValues = useMemo(() => ({
        name: data?.name || '',
        clientName: originalValues?.client_name || '',
        color: originalValues?.color || '',
    }), [originalValues, data]);

    const [formState, setFormState] = useState(defaultValues);
    const [errors, setErrors] = useState({});
    const [existingZone, setExistingZone] = useState(null);


    const isUnique = useMemo(() => formState?.name?.toLowerCase() === data?.name?.toLowerCase() ? true : isUniqueName(formState?.name, EDITABLE_LAYERS.ZONES), [formState?.name, isUniqueName, data?.name]);
    const existsError = useMemo(() => Object.keys(errors).filter(key => !!errors[key]).length > 0, [errors]);

    const validateField = useCallback((field, value) => {
        const validationRules = {
            name: { required: true, notAllowSpaces: true },
            color: { required: true, valueInArray: COLORS },
        };
        const rule = validationRules?.[field];
        if (rule && rule.required && (!value || value === '' || value.trim() === ''))
            return 'This field is required';
        if (rule && rule.notAllowSpaces && value?.includes(' '))
            return 'This field does not allow spaces';
        if (rule && rule.valueInArray && !COLORS.includes(value))
            return 'This field must be a valid color';
        return null;
    }, []);

    const handleFieldChange = useCallback((field, value) => {
        setErrors(prev => ({ ...prev, [field]: validateField(field, value) || undefined }));
        setFormState(prev => ({ ...prev, [field]: value }));
    }, [validateField]);

    const validateAllFields = useCallback(() => {
        const errors = {};
        Object.keys(formState).forEach(field => {
            const error = validateField(field, formState[field]);
            if (error) errors[field] = error;
        });
        setErrors(errors);
        if (Object.keys(errors).length > 0) return false;
        return true;
    }, [formState, validateField]);

    const makeChanges = useCallback(() => {
        editAnnotation(EDITABLE_LAYERS.ZONES, {
            _originalName: defaultValues?.name,
            _existsConflict: !isUnique,
            ...formState,
        });
        return true;
    }, [editAnnotation, formState, defaultValues, isUnique]);

    const onSave = useCallback(() => {
        const isValid = validateAllFields();
        if (!isValid) return null;
        return {
            needConfirm: !isUnique,
            callback: makeChanges,
            existingAnnotation: existingZone,
        }
    }, [isUnique, makeChanges, validateAllFields, existingZone]);

    useEffect(() => {
        if (!isUnique) {
            setExistingZone(Object.keys(modifiedLayout?.[EDITABLE_LAYERS.ZONES] || {}).find(layer => layer.toLowerCase() === formState?.name?.toLowerCase()) || formState?.name);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isUnique]);

    useImperativeHandle(ref, () => ({
        callbackOnSave: onSave
    }));

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column', gap: 0 }}>
                <Collapse in={!isUnique}>
                    <Alert severity="warning">
                        {boldString(t('overseer_app.layouts.edition.conflict_text', 'There is already an annotation with the same name in this layer (**{{item}}**). A **warning** will be assigned to the existing layer with this name, and it will need to be **reviewed**.', { item: existingZone }))}
                    </Alert>
                </Collapse>
                <Collapse in={existsError} sx={{ mt: !isUnique && existsError ? 1 : 0 }}>
                    <Alert severity="error">
                        {t('overseer_app.layouts.edition.default_error', 'Some fields are required to modify an annotation in this Layer.')}
                    </Alert>
                </Collapse>
            </Grid>
            <Grid item xs={12}>
                <TextField label={t('overseer_app.layouts.layer', 'Layer')} fullWidth required select value={EDITABLE_LAYERS.ZONES} disabled>
                    <MenuItem value={EDITABLE_LAYERS.ZONES}>{EDITABLE_LAYERS.ZONES}</MenuItem>
                </TextField>
            </Grid>
            <Grid item xs={12}>
                <TextField label={t('overseer_app.layouts.zone', 'Zone')} fullWidth required value={formState?.name} onChange={(e) => handleFieldChange('name', e.target.value)} error={!!errors?.name} helperText={t('overseer_app.layouts.edition.not_allow_spaces', 'This field does not allow spaces')} />
            </Grid>
            <Grid item xs={12}>
                <TextField label={t('overseer_app.layouts.creation.client_zone_name', 'Client\'s zone name')} fullWidth value={formState?.clientName} onChange={(e) => handleFieldChange('clientName', e.target.value)} />
            </Grid>
            <Grid item xs={12}>
                <Divider />
            </Grid>
            <Grid item xs={12}>
                <FormLabel required error={!!errors?.color}>{t('overseer_app.layouts.creation.select_color', 'Select a color')}</FormLabel>
                <RadioGroup row value={formState?.color} onChange={(e) => handleFieldChange('color', e.target.value)} error={!!errors?.color} >
                    {COLORS.map((color) => <ColorRadio key={color} color={color} />)}
                </RadioGroup>
            </Grid>
        </Grid>
    );
})