import { List, ListItemButton, ListItemText, Typography, Box, Chip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { boldString } from "../Utils";
import { IconComponent } from "@zippeditoolsjs/zippedi-icons";
import FallbackInformation from "./FallbackInformation";
import { useCallback } from "react";

function WarningItem({ warning, setDialogEdit }) {
    const handleClick = useCallback(() => {
        setDialogEdit({
            layer: warning?.layer,
            data: {
                name: warning?.target_name
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [warning]);
    const { t } = useTranslation();

    return (
        <ListItemButton divider onClick={handleClick}>
            <ListItemText primary={
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Typography variant='body2' fontWeight='bold' sx={{ textWrap: 'nowrap' }}>{warning?.data?.conflict_name}</Typography>
                    <IconComponent iconName='arrow-forward-outline' />
                    <Typography variant='body2' fontWeight='bold' sx={{ textWrap: 'nowrap' }}>{warning?.target_name}</Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, ml: 'auto' }}>
                        {warning?.timestamp && <Typography variant='body2' sx={{ fontSize: '12px' }}>
                            {new Date(warning?.timestamp).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false })}
                        </Typography>}
                        <Chip label={warning?.layer} size='small' sx={{ fontSize: '12px', height: '18px' }} color='error' />
                    </Box>
                </Box>
            } secondary={boldString(t(`overseer_app.layouts.warning_renamed`, "**{{old}}** was renamed to **{{new}}** and generated conflict", { old: warning?.data?.caused_by, new: warning?.data?.conflict_name }))} sx={{
                '& .MuiListItemText-secondary': {
                    fontSize: '12px',
                    mt: 0.5,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                }

            }} />
        </ListItemButton>
    );
}

export default function WarningsSidebar({ warnings, setDialogEdit }) {
    const { t } = useTranslation();
    const sortedWarnings = warnings?.sort((a, b) => new Date(a?.timestamp) - new Date(b?.timestamp));
    return (
        warnings?.length > 0 ? <List>
            {sortedWarnings?.map((warning, index) => (
                <WarningItem key={index} warning={warning} setDialogEdit={setDialogEdit} />
            ))}
        </List> : <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', p: 2 }}>
            <FallbackInformation>
                {t('overseer_app.layouts.no_warnings', 'No warnings, you can save the layout without problems')}
            </FallbackInformation>
        </Box>
    );
}