import { Dialog, DialogTitle, DialogContent, DialogActions, Button, DialogContentText } from '@mui/material';
import { useTranslation } from 'react-i18next';
export default function DialogConfirmPose({ open, onClose, data, addNewPose }) {
    const { t } = useTranslation();

    const onConfirm = () => {
        Object.keys(data)?.forEach(key => {
            addNewPose(data[key]);
        });
        onClose();
    }
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{t('overseer_app.layouts.creation.confirm_pose', 'Confirm Pose')}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {t('overseer_app.layouts.creation.confirm_pose_description', 'Please confirm the robot’s pose, as this will replace the existing poses in the map (base, exit_jail, and dock).')}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button color="secondary" onClick={onClose}>{t('overseer_app.layouts.cancel', 'Cancel')}</Button>
                <Button variant="contained" color="primary" onClick={onConfirm}>{t('overseer_app.layouts.confirm', 'Confirm')}</Button>
            </DialogActions>
        </Dialog>
    );
}
