import React from 'react'

import LoadingButton from '@mui/lab/LoadingButton';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, useTheme } from '@mui/material'
import { IconComponent } from '@zippeditoolsjs/zippedi-icons';

export default function DeleteDialog(props) {
  const {
    t,
    openDeleteDialog,
    setOpenDeleteDialog,
    selectedParameter,
    deleteParameters,
    isLoadingParametersDeleteResponse,
  } = props;
  const theme = useTheme();

  const handleClose = () => {
    setOpenDeleteDialog(false);
  }

  const handleSaveChanges = () => {
    deleteParameters(JSON.stringify([selectedParameter.parameter_id]));
  };

  return (
    <Dialog
      open={openDeleteDialog}
      onClose={handleClose}
    >
      <DialogTitle>
        {t('overseer_app.parameters.Delete_this_parameter', 'Delete this Parameter?')}
      </DialogTitle>
      <DialogContent dividers>
        {t('overseer_app.parameters.permanently_delete_parameter', 'You are going to permanently delete this parameter.')}
        <br />
        <br />
        <Typography sx={{ display: 'inline' }}>
          {t('overseer_app.parameters.parameter', 'Parameter')}: <span style={{ color: theme.palette.error.main }}>{selectedParameter.code}</span>
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose}>
          {t('overseer_app.general.Cancel', 'Cancel')}
        </Button>
        <LoadingButton
          loading={isLoadingParametersDeleteResponse}
          onClick={handleSaveChanges}
          loadingPosition="start"
          startIcon={<IconComponent
            iconName={'save'}
            style={{ fontSize: "20px" }}
          />}
        >
          {t('overseer_app.general.Save', 'Save')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
