import { Box, Chip, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineDot, timelineItemClasses } from '@mui/lab';
import { useSelector, useDispatch } from "react-redux";
import { getStoreLayout, getStoreMapLayout } from "core/slices/layouts";
import { setSnackAlert } from "core/slices/snackAlert";
import { formatLanguageDate } from "../tools/utils";
import FallbackInformation from './FallbackInformation';
import { useCallback } from "react";

function VersionItem({ active, version, isLast, store, floor, isCurrentVersion, setLoadingNewVersion }) {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();

    const { storeMapLayout } = useSelector(state => state.layouts);

    const onPress = useCallback(() => {
        if (!store || !floor || !version) return;
        dispatch(getStoreLayout({
            store: store,
            floor: floor,
            layout_id: version?.id
        }))
        setLoadingNewVersion(true);
        const { id = null } = storeMapLayout;
        if (id !== version?.map_id) {
            dispatch(getStoreMapLayout({
                store: store,
                floor: floor,
                map_id: version?.map_id
            }))
        }
        dispatch(setSnackAlert({
            message: t('overseer_app.layouts.version_loading', 'Loading version from {{date}}...', {
                date: formatLanguageDate(version?.date, i18n.language),
            }),
            severity: 'info',
            open: true,
        }))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, floor, store, storeMapLayout, version, t]);

    return (
        <TimelineItem className={active ? 'active' : ''}>
            <TimelineSeparator>
                <TimelineDot sx={{
                    boxShadow: 'unset',
                    boxSizing: 'content-box',
                    ...(isCurrentVersion ? {
                        outline: '3px solid rgba(0, 112, 183, 0.12)',
                        backgroundColor: 'info.main',
                    } : {
                        outline: 'unset',
                        backgroundColor: '#FFF',
                        border: '2px solid #E0E0E0',
                    })
                }} />
                {!isLast && <TimelineConnector sx={{ backgroundColor: 'divider' }} />}
            </TimelineSeparator>
            <TimelineContent sx={{
                '&:hover': {
                    cursor: 'pointer',
                    '& #version-date': {
                        textDecoration: 'underline',
                    }
                }
            }}>
                <Box sx={{ display: 'flex', gap: 2 }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0, width: '100%', pr: 0 }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                            <Typography variant="body2" sx={{ color: 'gray.main', fontWeight: 400 }} onClick={onPress} id={`version-date`}>
                                {formatLanguageDate(version?.date, i18n.language)}
                            </Typography>
                            {active && <Chip label={t('General.active', 'Active')} size="small" color="success" />}
                        </Box>
                        <Typography variant="body2" sx={{ fontWeight: 500, color: 'primary.main' }}>
                            {version?.name?.trim() || 'No name'}
                        </Typography>
                    </Box>
                </Box>
            </TimelineContent>
        </TimelineItem>
    )
}

export default function VersionsSidebar({ store, floor, currentVersion, setLoadingNewVersion }) {
    const { versions } = useSelector(state => state.layouts);
    const { t } = useTranslation();
    return (
        <Box sx={{ p: 2, width: '100%', height: '100%' }}>
            {(Object.keys(versions?.versions || {}).length > 0 || versions?.actual) ? <Timeline sx={{
                [`& .${timelineItemClasses.root}:before`]: {
                    flex: 0,
                    padding: 0,
                },
                p: 0,
                m: 0,
            }}>
                {versions?.actual && <VersionItem active version={versions.actual} isLast={versions?.versions?.length === 0} store={store} floor={floor} isCurrentVersion={currentVersion === versions.actual?.id} setLoadingNewVersion={setLoadingNewVersion} />}
                {versions && versions?.versions?.map((version, index) => (
                    <VersionItem key={index} version={version} isLast={index === versions?.versions?.length - 1} store={store} floor={floor} isCurrentVersion={currentVersion === version?.id} setLoadingNewVersion={setLoadingNewVersion} />
                ))}
            </Timeline> : <FallbackInformation>
                {t('overseer_app.layouts.no_versions', 'No versions available at the moment')}
            </FallbackInformation>}
        </Box>
    )
}