import { FormatToSentenceCase } from 'core/utils/parsers';
import React, { useEffect, useState } from 'react'

import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControlLabel, Stack, Switch, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import { isEmptyOrUndefined } from '@zippeditoolsjs/blocks';

import Selector from '../../tools/Selector';

const ParameterInformation = ({ t, newParameter }) => {
  return (
    <Stack justifyContent={isEmptyOrUndefined(newParameter, 'object') ? 'center' : ''} alignItems={isEmptyOrUndefined(newParameter, 'object') ? 'center' : ''} sx={{ flex: 1 }}>
      {isEmptyOrUndefined(newParameter, 'object') ?
        <Typography variant='caption' color='GrayText'>{t('overseer_app.parameters.Select_parameter', 'Select a parameter.')}</Typography>
        :
        <>
          <Typography sx={{ fontWeight: 'bold', mb: 1, textDecoration: 'underline' }}>{t('overseer_app.parameters.Parameter_information', 'Parameter Information')}</Typography>
          {Object.keys(newParameter).map(key => {
            if (key === 'parameter_id') return null; // Don't display the parameter_id
            if (key === 'category_id') return null; // Don't display the category_id

            // TODO: delete when the list data_type doesn't exist anymore
            // Parse the default value if it's a list
            let defaultValue = String(newParameter[key])
            if (newParameter['data_type'] === 'list' && key === 'default_value' && !isEmptyOrUndefined(newParameter[key], 'id')) {
              // Parse the string list of values 
              const parseList = JSON.parse(newParameter[key]);

              // Join the parsed list elements with commas
              defaultValue = parseList.join(', ')
            }

            return (
              <Typography key={key}><strong>{FormatToSentenceCase(key)}: </strong>
                {isEmptyOrUndefined(newParameter[key], 'id') ?
                  <>{t('overseer_app.parameters.No_value_assigned', 'No value assigned.')}</>
                  :
                  defaultValue
                }
              </Typography>
            )
          })
          }
        </>
      }
    </Stack>
  )
}

const singleOrAllRobotsInputs = (t, newParameter, input, setInput, availableRobotsByParam, getDataType, setDataType, checkedSwitch) => {
  return (
    <>
      {newParameter?.data_type === 'secret' ?
        // Data type: secret
        <Stack spacing={1}>
          <TextField
            disabled={!checkedSwitch}
            label='project_id'
            value={checkedSwitch ? input[availableRobotsByParam[newParameter?.code]?.[0]]?.project_id || '' : ''}
            onChange={(event) => {
              let newValues = {};
              availableRobotsByParam[newParameter?.code].forEach(robot_uuid => {
                newValues[robot_uuid] = {
                  ...input[robot_uuid],
                  'project_id': event.target.value
                }
              })
              setInput(newValues)
            }}
          />
          <TextField
            disabled={!checkedSwitch}
            label='secret_id'
            value={checkedSwitch ? input[availableRobotsByParam[newParameter?.code]?.[0]]?.secret_id || '' : ''}
            onChange={(event) => {
              let newValues = {};
              availableRobotsByParam[newParameter?.code].forEach(robot_uuid => {
                newValues[robot_uuid] = {
                  ...input[robot_uuid],
                  'secret_id': event.target.value
                }
              })
              setInput(newValues)
            }}
          />
          <TextField
            disabled={!checkedSwitch}
            label='version_id'
            value={checkedSwitch ? input[availableRobotsByParam[newParameter?.code]?.[0]]?.version_id || '' : ''}
            onChange={(event) => {
              let newValues = {};
              availableRobotsByParam[newParameter?.code].forEach(robot_uuid => {
                newValues[robot_uuid] = {
                  ...input[robot_uuid],
                  'version_id': event.target.value
                }
              })
              setInput(newValues)
            }}
          />
        </Stack>
        :
        // Data type: normal
        <TextField
          disabled={!checkedSwitch}
          key='all-robots-input'
          type={getDataType(newParameter?.data_type)}
          label={t('overseer_app.parameters.Value', 'Value')}
          // TODO: when there's real data validation, change this error and helperText validations
          error={checkedSwitch && !isEmptyOrUndefined(input[availableRobotsByParam[newParameter?.code]?.[0]], 'id') && newParameter?.data_type === 'bool' && input[availableRobotsByParam[newParameter?.code]?.[0]] !== true && input[availableRobotsByParam[newParameter?.code]?.[0]] !== false}
          helperText={checkedSwitch && !isEmptyOrUndefined(input[availableRobotsByParam[newParameter?.code]?.[0]], 'id') && newParameter?.data_type === 'bool' && input[availableRobotsByParam[newParameter?.code]?.[0]] !== true && input[availableRobotsByParam[newParameter?.code]?.[0]] !== false ? t('overseer_app.parameters.Incorrect_bool', 'Incorrect boolean value') : ''}
          value={checkedSwitch ? (newParameter?.data_type === 'bool' ? String(input[availableRobotsByParam[newParameter?.code]?.[0]]) : input[availableRobotsByParam[newParameter?.code]?.[0]]) || '' : ''}
          onChange={(event) => {
            let newValues = {};
            availableRobotsByParam[newParameter?.code].forEach(robot_uuid => {
              newValues[robot_uuid] = setDataType(event.target.value, newParameter?.data_type)
            })
            setInput(newValues)
          }}
        />}
    </>
  )
}

const SingleRobot = (props) => {
  const {
    t,
    isSmDownBreakpoint,
    sortedParams,
    newParameter,
    setNewParameter,
    input,
    setInput,
    getDataType,
    setDataType,
    availableRobotsByParam,
    checkedSwitch,
    deletedParameters,
  } = props;

  return (
    <Stack
      direction={{ xs: 'column', sm: 'row' }}
      spacing={{ xs: 2, md: 3 }}
      divider={<Divider flexItem orientation={isSmDownBreakpoint ? 'horizontal' : 'vertical'} />}
    >
      <Stack spacing={1} sx={{ flex: 1 }}>
        {/* Parameter selector */}
        {/* TODO: replace with zippeditoolsjs selector */}
        <Selector
          variant='outlined'
          labelInput={t('overseer_app.parameters.Parameter', 'Parameter')}
          options={sortedParams}
          optionLabel='code'
          selectedElement={newParameter}
          setSelectedElement={setNewParameter}
          optionsDisabled={(option) => option.disabled}
        />
        {/* Value input */}
        {deletedParameters.includes(newParameter?.code) ?
          null
          :
          singleOrAllRobotsInputs(t, newParameter, input, setInput, availableRobotsByParam, getDataType, setDataType, checkedSwitch)
        }
      </Stack>
      {/* Parameter info */}
      <ParameterInformation t={t} newParameter={newParameter} size={{ xs: 4, sm: 4, md: 5 }} />
    </Stack>
  )
}

const MultipleRobots = (props) => {
  const {
    t,
    isMdDownBrekpoint,
    sortedParams,
    newParameter,
    setNewParameter,
    input,
    setInput,
    getDataType,
    setDataType,
    availableRobotsByParam,
    robotByUUID,
    checkedSwitch,
    deletedParameters,
  } = props;

  const handleInputChange = (value, robot_uuid) => {
    setInput((prev) => ({
      ...prev,
      [robot_uuid]: value
    }));
  };

  return (
    <Stack
      direction={{ xs: 'column', md: 'row' }}
      spacing={2}
      divider={<Divider flexItem orientation={isMdDownBrekpoint ? 'horizontal' : 'vertical'} />}
    >
      <Stack spacing={1} sx={{ flex: 0.7 }}>
        {/* Parameter selector */}
        {/* TODO: replace with the zippeditoolsjs selector */}
        <Selector
          variant='outlined'
          labelInput={t('overseer_app.parameters.Parameter', 'Parameter')}
          options={sortedParams}
          optionLabel='code'
          selectedElement={newParameter}
          setSelectedElement={setNewParameter}
          optionsDisabled={(option) => option.disabled}
        />
        {/* Parameter info */}
        <ParameterInformation t={t} newParameter={newParameter} />
      </Stack>
      {deletedParameters.includes(newParameter?.code) ?
        null
        :
        <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} sx={{ flex: 1 }}>
          {/* All robots */}
          <Stack spacing={1} sx={{ flex: 1 }}>
            <Typography sx={{ fontWeight: 'bold' }}>{t('overseer_app.parameters.All_Robots', 'All Robots')}</Typography>
            {/* Value input */}
            {singleOrAllRobotsInputs(t, newParameter, input, setInput, availableRobotsByParam, getDataType, setDataType, checkedSwitch)}
          </Stack>
          <Divider flexItem orientation={isMdDownBrekpoint ? 'horizontal' : 'vertical'} />
          {/* Individual robots */}
          <Stack spacing={1} sx={{ flex: 1 }}>
            <Typography sx={{ fontWeight: 'bold' }}>{t('overseer_app.parameters.Individual_Robots', 'Individual Robots')}</Typography>
            {/* Value input */}
            {availableRobotsByParam[newParameter?.code]?.map(robot_uuid => {
              // Data type: secret
              if (newParameter?.data_type === 'secret') {
                return (
                  <Stack key={robot_uuid} spacing={1}>
                    <Typography sx={{ fontWeight: 'bold' }}>{robotByUUID[robot_uuid]?.robot_code}</Typography>
                    <TextField
                      disabled={checkedSwitch}
                      label='project_id'
                      value={input[robot_uuid]?.project_id || ''}
                      onChange={(event) => handleInputChange({ ...input[robot_uuid], 'project_id': event.target.value }, robot_uuid)}
                    />
                    <TextField
                      disabled={checkedSwitch}
                      label='secret_id'
                      value={input[robot_uuid]?.secret_id || ''}
                      onChange={(event) => handleInputChange({ ...input[robot_uuid], 'secret_id': event.target.value }, robot_uuid)}
                    />
                    <TextField
                      disabled={checkedSwitch}
                      label='version_id'
                      value={input[robot_uuid]?.version_id || ''}
                      onChange={(event) => handleInputChange({ ...input[robot_uuid], 'version_id': event.target.value }, robot_uuid)}
                    />
                  </Stack>
                )
              } else {
                // Data type: normal
                return (
                  <TextField
                    disabled={checkedSwitch}
                    key={robot_uuid}
                    type={getDataType(newParameter?.data_type)}
                    label={robotByUUID[robot_uuid]?.robot_code}
                    value={(newParameter?.data_type === 'bool' ? String(input[robot_uuid]) : input[robot_uuid]) || ''}
                    // TODO: when there's real data validation, change this error and helperText validations
                    error={!isEmptyOrUndefined(input[robot_uuid], 'id') && newParameter?.data_type === 'bool' && input[robot_uuid] !== true && input[robot_uuid] !== false}
                    helperText={!isEmptyOrUndefined(input[robot_uuid], 'id') && newParameter?.data_type === 'bool' && input[robot_uuid] !== true && input[robot_uuid] !== false ? t('overseer_app.parameters.Incorrect_bool', 'Incorrect boolean value') : ''}
                    onChange={(event) => handleInputChange(setDataType(event.target.value, newParameter.data_type), robot_uuid)}
                  />
                )
              }
            })}
          </Stack>
        </Stack>
      }
    </Stack>
  )
}

export default function AddDialog(props) {
  const {
    t,
    openAddDialog,
    setOpenAddDialog,
    parameters,
    groupedRobotParameters,
    selectedRobots,
    changes,
    setChanges,
    getDataType,
    setDataType,
    oldParameters,
    setOldParameters,
    isSingleRobotSelected,
    robotByUUID,
    deletedParameters,
    tableRobotParameters,
    setTableRobotParameters,
  } = props;
  const theme = useTheme();
  const isSmDownBreakpoint = useMediaQuery(theme.breakpoints.down("sm"))
  const isMdDownBrekpoint = useMediaQuery(theme.breakpoints.down("md"))
  const [newParameter, setNewParameter] = useState('');
  const [input, setInput] = useState({}); // Each key-value pair (robot_uuid: parameter value). E.g.: {"8c26bf65-73be-4a5e-ab1d-1bea1e135cdb": "43.2", "fd9e29bd-fad5-4ab3-bb29-c944d19eab51": "50"}
  const [sortedParams, setSortedParams] = useState([]);
  const [checkedSwitch, setCheckedSwitch] = useState(true);
  const [availableRobotsByParam, setAvailableRobotsByParam] = useState([]); // key: parameter code, value: array with the robots that don't have that parameter 

  // Lifecycle methods

  // * Get the list of available params to add and the ones that need to be disabled
  useEffect(() => {
    let availableParams = [];
    let disableParams = [];

    // Array of all the robot uuids
    const robots = selectedRobots.map(robot => robot.robot_uuid)

    parameters.forEach(parameter => {
      // Set with robot_uuids when the robot doesn't have the parameter in oldParameters and changes.add
      let currentParamAvailableRobots = new Set();
      // Count how many robots have the parameter
      let robotsCount = 0;

      // If the parameter was deleted, make it available to add for all the robots
      // and if the parameter was deleted for one robot, it means it was deleted for all, so continue the loop with the next iteration
      if (deletedParameters.includes(parameter.code)) {
        setAvailableRobotsByParam(prevState => ({
          ...prevState,
          [parameter.code]: robots
        }))
        availableParams.push({ ...parameter, disabled: false })
        return;
      }

      selectedRobots.forEach(robot => {
        // If the parameter came in the GET request or was added in changes.add, make it unavailable
        if ((!isEmptyOrUndefined(oldParameters[robot.robot_uuid], 'object') && (!isEmptyOrUndefined(oldParameters[robot.robot_uuid][parameter.code], 'id') || !isEmptyOrUndefined(oldParameters[robot.robot_uuid][parameter.code], 'array') || !isEmptyOrUndefined(oldParameters[robot.robot_uuid][parameter.code], 'object')))
          || (!isEmptyOrUndefined(changes.edit[robot.robot_uuid], 'object') && (!isEmptyOrUndefined(changes.edit[robot.robot_uuid][parameter.code], 'id') || !isEmptyOrUndefined(changes.edit[robot.robot_uuid][parameter.code], 'array') || !isEmptyOrUndefined(changes.edit[robot.robot_uuid][parameter.code], 'object')))
          || (!isEmptyOrUndefined(changes.add[robot.robot_uuid], 'object') && (!isEmptyOrUndefined(changes.add[robot.robot_uuid][parameter.code], 'id') || !isEmptyOrUndefined(changes.add[robot.robot_uuid][parameter.code], 'array') || !isEmptyOrUndefined(changes.add[robot.robot_uuid][parameter.code], 'object')))) {
          robotsCount += 1;
        } else {
          currentParamAvailableRobots.add(robot.robot_uuid)
        }
      })

      setAvailableRobotsByParam(prevState => ({
        ...prevState,
        [parameter.code]: [...currentParamAvailableRobots]
      }))

      // If robotsCount is equal to the amount of selected robots, that means all the robots have the value assigned and needs to be disabled
      const isParamDisabled = robotsCount === selectedRobots.length

      if (isParamDisabled) {
        disableParams.push({ ...parameter, disabled: true })
      } else {
        availableParams.push({ ...parameter, disabled: false })
      }
    })

    // Sort by parameter code
    availableParams.sort((a, b) => {
      const nameA = a.code.toUpperCase(); // ignore upper and lowercase
      const nameB = b.code.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      // names must be equal
      return 0;
    });

    // Sort by parameter code
    disableParams.sort((a, b) => {
      const nameA = a.code.toUpperCase(); // ignore upper and lowercase
      const nameB = b.code.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      // names must be equal
      return 0;
    });

    setSortedParams([...availableParams, ...disableParams])
  }, [parameters, changes, groupedRobotParameters, selectedRobots.length, oldParameters, deletedParameters, selectedRobots])

  // * Reset the textfields input when the parameter changes
  useEffect(() => {
    setInput({});
  }, [newParameter])


  // Methods

  const handleClose = () => {
    setOpenAddDialog(false);
    setNewParameter('');
    setInput({});
  };

  const handleSaveChanges = () => {
    let oldAdds = structuredClone(changes.add);
    let newAdds = {};
    let value = ''; // For 1 robot
    let values = []; // For multiple robots
    let newDeletes = structuredClone(changes.delete);
    let newOldParams = structuredClone(oldParameters);
    // For modifying the table
    const restTable = []; // Parameter objects that aren't the current parameter
    let currentTableParams = {}; // Current parameter

    tableRobotParameters.forEach(param => {
      if (param.code === newParameter.code) {
        currentTableParams = param;
      } else {
        restTable.push(param);
      }
    })

    Object.entries(input).forEach(([robot_uuid, newParamValue]) => {
      newAdds[robot_uuid] = {
        ...oldAdds[robot_uuid],
        [newParameter?.code]: newParamValue
      }
      value = newParamValue
      values.push({
        "id": robot_uuid,
        "value": newParamValue,
        "robot_uuid": robot_uuid,
        "data_type": newParameter.data_type,
        "version": null,
      })
    })

    // If the parameter was prevously deleted, remove it from changes.delete for every robot and add it to oldParameters only for the robots that had it originally
    if (deletedParameters.includes(newParameter?.code)) {
      selectedRobots.forEach(robot => {
        delete newDeletes?.[robot.robot_uuid]?.[newParameter?.code]

        if (!isEmptyOrUndefined(changes.delete?.[robot.robot_uuid], 'object') && Object.keys(changes.delete?.[robot.robot_uuid]).includes(newParameter?.code)) {
          newOldParams[robot.robot_uuid] = {
            ...oldParameters[robot.robot_uuid],
            [newParameter.code]: changes.delete?.[robot.robot_uuid][newParameter?.code]
          }
        }

        // If the robot_uuid doesn't have any parameters left, delete the object
        if (isEmptyOrUndefined(newDeletes?.[robot.robot_uuid], 'object')) {
          delete newDeletes?.[robot.robot_uuid]
        }
      })
    }

    setOldParameters(newOldParams);

    setChanges(prevState => ({
      ...prevState,
      add: {
        ...oldAdds,
        ...newAdds
      },
      delete: newDeletes
    }))

    // Add the parameter to the table values
    // If currentTableParams is empty, the parameter doesn't exist in the table (for single and multiple robots)
    // Else add the new value to "values" (only for multiple robots)
    if (isEmptyOrUndefined(currentTableParams, 'object')) {
      setTableRobotParameters(prevState => {
        return [{
          "id": newParameter?.code,
          "code": newParameter?.code,
          "data_type": newParameter.data_type,
          "value": value,
          "values": values,
          "category": newParameter.category
        },
        ...prevState
        ]
      });
    } else {
      currentTableParams.values.push(...values);
      setTableRobotParameters([currentTableParams, ...restTable]);
    }

    // Reset the local variables and close the dialog
    handleClose();
  };

  return (
    <Dialog
      fullWidth
      maxWidth={isSingleRobotSelected ? 'md' : 'lg'}
      open={openAddDialog}
      onClose={handleClose}
    >
      {/* Dialog title */}
      <DialogTitle>
        <Stack direction='row' justifyContent='space-between'>
          {t('overseer_app.parameters.New_parameter', 'New Parameter')}
          {!isSingleRobotSelected &&
            <FormControlLabel
              control={
                <Switch color="primary"
                  checked={checkedSwitch}
                  onChange={(event) => { setCheckedSwitch(event.target.checked); setInput({}); }}
                />
              }
              label={t('overseer_app.parameters.Edit_All_Robots', 'Edit All Robots')}
              labelPlacement="end"
            />
          }
        </Stack>
      </DialogTitle>
      {/* Dialog content */}
      <DialogContent dividers>
        {/* Alert: add warning */}
        {!isSingleRobotSelected && <Alert severity='warning' sx={{ mb: 2 }}>{t('overseer_app.parameters.add_warning', 'Only robots that do not have this parameter will be added.')}</Alert>}
        {/* Alert: info */}
        {(newParameter?.data_type === 'list' || newParameter?.data_type === 'str') &&
          <Alert severity='info' sx={{ mb: 2 }}>
            {(newParameter?.data_type === 'list' || newParameter?.data_type === 'str') && <>&nbsp;{t('overseer_app.parameters.dollar_sign_warning', 'Never use a dollar sign ($).')}</>}
            {newParameter?.data_type === 'list' && <>&nbsp;{t('overseer_app.parameters.list_format_warning', 'Please separate values with commas.')}</>}
          </Alert>
        }
        {/* Single robot component */}
        {isSingleRobotSelected ?
          <SingleRobot
            t={t}
            isSmDownBreakpoint={isSmDownBreakpoint}
            sortedParams={sortedParams}
            newParameter={newParameter}
            setNewParameter={setNewParameter}
            input={input}
            setInput={setInput}
            getDataType={getDataType}
            setDataType={setDataType}
            availableRobotsByParam={availableRobotsByParam}
            checkedSwitch={checkedSwitch}
            deletedParameters={deletedParameters}
          />
          :
          <MultipleRobots
            t={t}
            isMdDownBrekpoint={isMdDownBrekpoint}
            sortedParams={sortedParams}
            newParameter={newParameter}
            setNewParameter={setNewParameter}
            input={input}
            setInput={setInput}
            getDataType={getDataType}
            setDataType={setDataType}
            availableRobotsByParam={availableRobotsByParam}
            robotByUUID={robotByUUID}
            checkedSwitch={checkedSwitch}
            deletedParameters={deletedParameters}
          />
        }
      </DialogContent>
      {/* Dialog actions */}
      <DialogActions>
        <Button autoFocus onClick={handleClose}>
          {t('overseer_app.general.Cancel', 'Cancel')}
        </Button>
        <Button onClick={handleSaveChanges}>{t('overseer_app.general.Save', 'Save')}</Button>
      </DialogActions>
    </Dialog>
  )
}
