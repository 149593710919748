
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, DialogContentText } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useMemo, useCallback, useRef, cloneElement, useState } from "react";
import { EDITABLE_LAYERS } from "../tools/useLayout";
import { boldString } from "../Utils";
export default function DialogEdit({ open, onClose, children, data, modifiedLayout, colissionLayers, editAnnotation, layer }) {
    const { t } = useTranslation();
    const [conflictDialog, setConflictDialog] = useState(false);
    const [existingAnnotation, setExistingAnnotation] = useState(null);
    const childrenRef = useRef(null);
    const resolveConflictRef = useRef(null);
    const TEXTS = useMemo(() => {
        return {
            [EDITABLE_LAYERS.CAPTURE]: { title: t('overseer_app.layouts.edition.edit_aisle', 'Edit aisle') },
            [EDITABLE_LAYERS.ZONES]: { title: t('overseer_app.layouts.edition.edit_zone', 'Edit zone') },
        }
    }, [t]);

    const onConfirmConflict = useCallback(() => {
        setConflictDialog(false);
        resolveConflictRef.current(true);
        return true;
    }, []);

    const onCancelConflict = useCallback(() => {
        setConflictDialog(false);
        resolveConflictRef.current(false);
    }, []);

    const onSave = useCallback(async () => {
        if (childrenRef?.current) {
            const result = childrenRef.current.callbackOnSave();
            if (!result) return;
            const { needConfirm, callback, existingAnnotation: __existingAnnotation } = result;
            if (needConfirm) {
                setExistingAnnotation(__existingAnnotation);
                setConflictDialog(true);
                const solvedConfirm = await new Promise(resolve => resolveConflictRef.current = resolve);
                if (!solvedConfirm) return;
            }
            callback();
            onClose();
        }
    }, [onClose, childrenRef]);

    const isUniqueName = useCallback((name, layer) => {
        const layerData = modifiedLayout?.[layer] || {};
        return !Object.keys(layerData)?.some(layer => layer?.toLowerCase() === name?.toLowerCase());
    }, [modifiedLayout]);

    return (
        <>
            <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
                <DialogTitle>
                    {TEXTS?.[layer]?.title || ""}
                </DialogTitle>
                <DialogContent>
                    {children && cloneElement(children, {
                        ref: childrenRef,
                        data,
                        modifiedLayout,
                        colissionLayers,
                        isUniqueName,
                        editAnnotation
                    })}
                </DialogContent>
                <DialogActions>
                    <Button color="secondary" onClick={onClose}>{t('overseer_app.layouts.cancel', 'Cancel')}</Button>
                    <Button variant="contained" color="primary" onClick={onSave} disabled={childrenRef?.current?.disableSaveButton}>{t('overseer_app.layouts.save', 'Save')}</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={conflictDialog} onClose={() => setConflictDialog(false)} aria-hidden={!conflictDialog}>
                <DialogTitle>
                    {t('overseer_app.layouts.edition.conflict_title', 'Conflict detected')}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {boldString(t('overseer_app.layouts.edition.conflict_text', 'There is already an annotation with the same name in this layer (**{{item}}**). A **warning** will be assigned to the existing layer with this name, and it will need to be **reviewed**.', { item: existingAnnotation }))}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCancelConflict} color="secondary">
                        {t('overseer_app.layouts.cancel', 'Cancel')}
                    </Button>
                    <Button onClick={onConfirmConflict} variant="contained" color="primary">
                        {t('overseer_app.layouts.confirm', 'Confirm')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}