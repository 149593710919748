import { setSnackAlert } from 'core/slices/snackAlert';
import React, { useEffect, useState } from 'react'

import { IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { DataGrid } from '@zippeditoolsjs/table';
import { IconComponent } from '@zippeditoolsjs/zippedi-icons';

import AddEditDialog from './AddEditDialog';
import DeleteDialog from './DeleteDialog';

const columns = (props) => {
  const {
    t,
    canUpdate,
    setOpenAddEditDialog,
    setOpenDeleteDialog,
    setChangeType,
    setSelectedCategory,
  } = props;

  return ([
    { field: 'name', headerName: t('overseer_app.parameters.Name', 'Name'), flex: 1 },
    {
      field: 'actions',
      headerName: t('overseer_app.parameters.Actions', 'Actions'),
      flex: 1,
      renderCell: (params) => (
        <>
          <Tooltip title={t('overseer_app.parameters.Edit', 'Edit')}>
            <span>
              <IconButton disabled={!canUpdate} onClick={() => { setOpenAddEditDialog(true); setChangeType('edit'); setSelectedCategory(params.row) }}>
                <IconComponent
                  iconName={'pencil'}
                  style={{ fontSize: "20px" }}
                />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title={t('overseer_app.parameters.Delete', 'Delete')}>
            <span>
              <IconButton disabled={!canUpdate} onClick={() => { setOpenDeleteDialog(true); setSelectedCategory(params.row); }}>
                <IconComponent
                  iconName={'trash'}
                  style={{ fontSize: "20px", color: 'red' }}
                />
              </IconButton>
            </span>
          </Tooltip>
        </>
      )
    }
  ])
}

export default function Categories(props) {
  const {
    t,
    canUpdate,
    getParameterCategories,
    parameterCategories,
    isLoadingParameterCategories,
    postParameterCategories,
    parameterCategoriesPostResponse,
    isLoadingParameterCategoriesPostResponse,
    patchParameterCategories,
    parameterCategoriesPatchResponse,
    isLoadingParameterCategoriesPatchResponse,
    deleteParameterCategories,
    parameterCategoriesDeleteResponse,
    isLoadingParameterCategoriesDeleteResponse,
  } = props;
  // Dialog states
  const [openAddEditDialog, setOpenAddEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  // States
  const [changeType, setChangeType] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState({});

  // Lifecycle methods

  // POST response
  useEffect(() => {
    if (parameterCategoriesPostResponse?.message === 'Success') {
      getParameterCategories();
      setOpenAddEditDialog(false);
      setSnackAlert({
        open: true,
        message: t('overseer_app.parameters.categories_save_successful', 'Categories saved successfully'),
        severity: 'success',
      });
    }
  }, [t, getParameterCategories, parameterCategoriesPostResponse])

  // PATCH response
  useEffect(() => {
    if (parameterCategoriesPatchResponse?.message === 'Success') {
      getParameterCategories();
      setOpenAddEditDialog(false);
      setSnackAlert({
        open: true,
        message: t('overseer_app.parameters.categories_save_successful', 'Categories saved successfully'),
        severity: 'success',
      });
    }
  }, [t, getParameterCategories, parameterCategoriesPatchResponse])

  // DELETE response
  useEffect(() => {
    if (parameterCategoriesDeleteResponse?.message === 'Success') {
      getParameterCategories();
      setOpenDeleteDialog(false);
      setSnackAlert({
        open: true,
        message: t('overseer_app.parameters.categories_delete_successful', 'Categories deleted successfully'),
        severity: 'success',
      });
    }
  }, [t, parameterCategoriesDeleteResponse, getParameterCategories])

  return (
    <Stack sx={{ p: 2 }}>
      <Stack direction='row' justifyContent='space-between' alignItems='center'>
        {/* Title */}
        <Typography variant='h5'>{t('overseer_app.parameters.Categories', 'Categories')}</Typography>
        {/* Add button */}
        <IconButton disabled={!canUpdate} onClick={() => { setOpenAddEditDialog(true); setChangeType('add') }}>
          <IconComponent
            iconName={'add'}
            style={{ fontSize: "20px" }}
          />
        </IconButton>
      </Stack>
      {/* Table */}
      <DataGrid
        sx={{ border: 'none' }}
        rows={parameterCategories}
        columns={columns({ t, canUpdate, setOpenAddEditDialog, setOpenDeleteDialog, setChangeType, setSelectedCategory })}
        loading={isLoadingParameterCategories}
        initialState={{
          pagination: {
            paginationModel: { pageSize: 5, page: 0 },
          },
        }}
        pageSizeOptions={[5, 10, 25]}
        getRowId={(row) => row.category_id}
      />
      <AddEditDialog
        t={t}
        openAddEditDialog={openAddEditDialog}
        setOpenAddEditDialog={setOpenAddEditDialog}
        changeType={changeType}
        selectedCategory={selectedCategory}
        postParameterCategories={postParameterCategories}
        isLoadingParameterCategoriesPostResponse={isLoadingParameterCategoriesPostResponse}
        patchParameterCategories={patchParameterCategories}
        isLoadingParameterCategoriesPatchResponse={isLoadingParameterCategoriesPatchResponse}
      />
      <DeleteDialog
        t={t}
        openDeleteDialog={openDeleteDialog}
        setOpenDeleteDialog={setOpenDeleteDialog}
        selectedCategory={selectedCategory}
        deleteParameterCategories={deleteParameterCategories}
        isLoadingParameterCategoriesDeleteResponse={isLoadingParameterCategoriesDeleteResponse}
      />
    </Stack>
  )
}
