import { Grid, Collapse, Alert, TextField, MenuItem, Divider, FormLabel, RadioGroup, FormControlLabel, Radio, Box } from "@mui/material";
import { useImperativeHandle, forwardRef, useCallback, useState, useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";
const AISLE_LAYER = 'capture';
const ZONES_LAYER = 'zones';

const DEFAULT_VALUES = {
    name: '',
    clientName: '',
    collisionZone: '',
    aisleType: 'aisle',
}

const CircleColor = ({ color = '#FD7E14' }) => (
    <div style={{ position: 'relative', marginLeft: 'auto' }}>
        <div style={{ width: 11, height: 11, backgroundColor: color, borderRadius: '50%', zIndex: 1, position: 'relative' }} />
        <div style={{ position: 'absolute', top: '50%', left: '50%', width: '14px', height: '14px', backgroundColor: color, borderRadius: '50%', zIndex: 0, transform: 'translate(-50%, -50%)', opacity: 0.7 }} />
    </div>
);

export default forwardRef(function DialogCreationAisle({
    colissionLayers,
    addNewLayer,
    dialogData,
    isUniqueName,
}, ref) {
    const [formState, setFormState] = useState(DEFAULT_VALUES);
    const [errors, setErrors] = useState({});

    const { t } = useTranslation();

    const collisionZones = useMemo(() => {
        const { data: { start, end } } = dialogData;
        const startPoint = { x: start[0], y: start[1] };
        const endPoint = { x: end[0], y: end[1] };
        return colissionLayers(ZONES_LAYER, { start: startPoint, end: endPoint });
    }, [dialogData, colissionLayers]);

    useEffect(() => {
        if (collisionZones?.length === 1) {
            setFormState(prev => ({ ...prev, collisionZone: collisionZones[0].layer }));
        }
    }, [collisionZones]);

    const validateField = useCallback((field, value) => {
        const validationRules = {
            name: { required: true, unique: true, notAllowSpaces: true },
            collisionZone: { required: collisionZones.length >= 1 },
            aisleType: { required: true },
        };
        const rule = validationRules?.[field];
        if (rule && rule.required && (!value || value === ''))
            return 'This field is required';
        if (rule && rule.unique && !isUniqueName(value, AISLE_LAYER))
            return 'This name is already in use';
        if (rule && rule.notAllowSpaces && value?.includes(' '))
            return 'This field does not allow spaces';
        return null;
    }, [collisionZones, isUniqueName]);

    const validateAllFields = useCallback(() => {
        const errors = {};
        Object.keys(formState).forEach(field => {
            const error = validateField(field, formState[field]);
            if (error) {
                errors[field] = error;
            }
        });
        setErrors(errors);
        if (Object.keys(errors).length > 0) {
            return false;
        }
        return true;
    }, [formState, validateField]);

    const handleFieldChange = useCallback((field, value) => {
        setErrors(prev => ({ ...prev, [field]: validateField(field, value) || undefined }));
        setFormState(prev => ({ ...prev, [field]: value }));
    }, [validateField]);

    const existsError = useMemo(() => Object.keys(errors).filter(key => !!errors[key]).length > 0, [errors]);

    const onSave = useCallback(() => {
        const isValid = validateAllFields();
        if (!isValid) return false;
        const { data: { start, end } } = dialogData;
        const { name, clientName } = formState;
        addNewLayer(AISLE_LAYER, {
            name: name.trim(),
            clientName: clientName.trim(),
            bbox: [
                { x: start[0], y: start[1] },
                { x: end[0], y: end[1] }
            ],
            type: 'rectangle',
            color: collisionZones.find(zone => zone.layer === formState?.collisionZone)?.color,
            zone: formState?.collisionZone || null,
        });
        return true;
    }, [addNewLayer, dialogData, formState, validateAllFields, collisionZones]);

    useImperativeHandle(ref, () => ({
        callbackOnSave: onSave
    }), [onSave]);

    return <>
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Collapse in={existsError}>
                    <Alert severity="error">
                        {t('overseer_app.layouts.creation.default_error', 'Some fields are required or must be unique to create a new annotation in this Layer.')}
                    </Alert>
                </Collapse>
            </Grid>
            <Grid item xs={6}>
                <TextField label={t('overseer_app.layouts.layer', 'Layer')} fullWidth required select value={AISLE_LAYER} disabled>
                    <MenuItem value={AISLE_LAYER}>{AISLE_LAYER}</MenuItem>
                </TextField>
            </Grid>
            <Grid item xs={6}>
                <TextField label={t('overseer_app.layouts.zone', 'Zone')} fullWidth required={collisionZones.length >= 1} select value={formState?.collisionZone} onChange={(e) => handleFieldChange('collisionZone', e.target.value)} disabled={collisionZones.length <= 1} error={!!errors.collisionZone}>
                    {collisionZones.map(({ layer, color }) => (
                        <MenuItem value={layer} key={layer}>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, width: '100%' }}>
                                {layer}
                                <CircleColor color={color} />
                            </Box>
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>
            <Grid item xs={12}>
                <TextField label={t('overseer_app.layouts.creation.aisle_name', 'Aisle name')} fullWidth required value={formState?.name} onChange={(e) => handleFieldChange('name', e.target.value)} error={!!errors?.name} helperText={t('overseer_app.layouts.creation.not_allow_spaces', 'This field must be unique and does not allow spaces')} />
            </Grid>
            <Grid item xs={12}>
                <TextField label={t('overseer_app.layouts.creation.client_aisle_name', 'Client\'s aisle name')} fullWidth value={formState?.clientName} onChange={(e) => handleFieldChange('clientName', e.target.value)} />
            </Grid>
            <Grid item xs={12}>
                <Divider />
            </Grid>
            <Grid item xs={12}>
                <FormLabel required>{t('overseer_app.layouts.creation.select_aisle_type', 'Select a type')}</FormLabel>
                <RadioGroup row value={formState?.aisleType} onChange={(e) => handleFieldChange('aisleType', e.target.value)}>
                    <FormControlLabel value="aisle" control={<Radio size="small" />} label={t('overseer_app.layouts.aisle', 'Aisle')} />
                    <FormControlLabel value="fixture" control={<Radio size="small" />} label={t('overseer_app.layouts.fixture', 'Fixture')} />
                </RadioGroup>
            </Grid>
        </Grid>
    </>
})