import { Grid, Collapse, Alert, TextField, Divider, FormControlLabel, Radio, Box, FormLabel, RadioGroup, MenuItem } from "@mui/material";
import { useImperativeHandle, forwardRef, useCallback, useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { boldString } from "../Utils";
import natsort from "natsort";

const ColorRadio = ({ color }) => {
    return <FormControlLabel value={color} control={<Radio size="small" />} label={<Box sx={{ width: 30, height: 15, backgroundColor: color, borderRadius: '12px' }} />} />
}

const COLORS = ['#FD7E14', '#0D6EFD', '#11CAF0', '#9B14FE', '#02B936'];

const DEFAULT_VALUES = {
    name: '',
    clientName: '',
    color: COLORS[0]
}

export default forwardRef(function DialogCreationZone({
    updateDrawColor,
    globalLayers,
    dialogData,
    isUniqueName,
    aislesColissioned,
    addNewZone
}, ref) {

    const [formState, setFormState] = useState(DEFAULT_VALUES);
    const [errors, setErrors] = useState({});

    const { t } = useTranslation();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => (() => updateDrawColor?.(COLORS[0])), []);

    const validateField = useCallback((field, value) => {
        const validationRules = {
            name: { required: true, unique: true, notAllowSpaces: true },
            color: { required: true },
        };
        const rule = validationRules?.[field];
        if (rule && rule.required && (!value || value === ''))
            return 'This field is required';
        if (rule && rule.unique && !isUniqueName(value, 'zones'))
            return 'This name is already in use';
        if (rule && rule.notAllowSpaces && value?.includes(' '))
            return 'This field does not allow spaces';
        return null;
    }, [isUniqueName]);

    const validateAllFields = useCallback(() => {
        const errors = {};
        Object.keys(formState).forEach(field => {
            const error = validateField(field, formState[field]);
            if (error) errors[field] = error;
        });
        setErrors(errors);
        if (Object.keys(errors).length > 0) return false;
        return true;
    }, [formState, validateField]);

    const handleFieldChange = useCallback((field, value) => {
        setErrors(prev => ({ ...prev, [field]: validateField(field, value) || undefined }));
        setFormState(prev => ({ ...prev, [field]: value }));
    }, [validateField]);

    const existsError = useMemo(() => Object.keys(errors).filter(key => !!errors[key]).length > 0, [errors]);

    const _aislesColissioned = useMemo(() => {
        const { data: { start, end } } = dialogData;
        const startPoint = { x: start[0], y: start[1] };
        const endPoint = { x: end[0], y: end[1] };
        return aislesColissioned({ start: startPoint, end: endPoint })?.sort(natsort({ insensitive: true }));
    }, [dialogData, aislesColissioned]);

    const onSave = useCallback(() => {
        const isValid = validateAllFields();
        if (!isValid) return false;
        const { data: { start, end } } = dialogData;
        const { name, clientName, color } = formState;
        addNewZone({
            name: name.trim(),
            clientName: clientName.trim(),
            color,
            bbox: [
                { x: start[0], y: start[1] },
                { x: end[0], y: end[1] }
            ],
        }, _aislesColissioned);
        return true;
    }, [validateAllFields, formState, dialogData, addNewZone, _aislesColissioned]);

    useImperativeHandle(ref, () => ({
        callbackOnSave: onSave
    }), [onSave]);

    const dinamycAislesText = useMemo(() => {
        if (_aislesColissioned.length > 1) {
            return _aislesColissioned.join(', ');
        } else if (_aislesColissioned.length === 1) {
            return _aislesColissioned[0];
        }
        return null;
    }, [_aislesColissioned]);

    return <>
        <Grid container spacing={2}>
            <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                <Collapse in={!!dinamycAislesText}>
                    <Alert severity="info">
                        {boldString(t('overseer_app.layouts.creation.colission_aisles', 'The marked area will cover the following aisles: **{{aisles}}**, which will be assigned to the **new zone** that will be created.', { aisles: dinamycAislesText }))}
                    </Alert>
                </Collapse>
                <Collapse in={existsError}>
                    <Alert severity="error">
                        {t('overseer_app.layouts.creation.default_error', 'Some fields are required or must be unique to create a new annotation in this Layer.')}
                    </Alert>
                </Collapse>
            </Grid>
            <Grid item xs={12}>
                <TextField label={t('overseer_app.layouts.layer', 'Layer')} fullWidth required select value={'zones'} onChange={(e) => handleFieldChange('selectedLayer', e.target.value)} error={!!errors?.selectedLayer} disabled>
                    {globalLayers?.map((name) => (
                        <MenuItem value={name} key={name}>{name}</MenuItem>
                    ))}
                </TextField>
            </Grid>
            <Grid item xs={12}>
                <TextField label={t('overseer_app.layouts.creation.zone_name', 'Zone name')} fullWidth required value={formState?.name} onChange={(e) => handleFieldChange('name', e.target.value)} error={!!errors?.name} helperText={t('overseer_app.layouts.creation.not_allow_spaces', 'This field must be unique and does not allow spaces')} />
            </Grid>
            <Grid item xs={12}>
                <TextField label={t('overseer_app.layouts.creation.client_zone_name', 'Client\'s zone name')} fullWidth value={formState?.clientName} onChange={(e) => handleFieldChange('clientName', e.target.value)} />
            </Grid>
            <Grid item xs={12}>
                <Divider />
            </Grid>
            <Grid item xs={12}>
                <FormLabel required>{t('overseer_app.layouts.creation.select_color', 'Select a color')}</FormLabel>
                <RadioGroup row value={formState?.color} onChange={(e) => {
                    handleFieldChange('color', e.target.value);
                    updateDrawColor?.(e.target.value)
                }} error={!!errors?.color} >
                    {COLORS.map((color) => <ColorRadio key={color} color={color} />)}
                </RadioGroup>
            </Grid>
        </Grid>
    </>
})