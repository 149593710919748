import {
  Card,
  Divider,
  Grid,
  IconButton,
  Modal,
  Skeleton,
  Tooltip,
  Typography,
} from '@mui/material';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { IconComponent } from '@zippeditoolsjs/zippedi-icons';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RobotViewContext } from '../../context/RobotView';
import LastRefresh from '../tools/LastRefresh';
import TabPanel from '../tools/TabPanel';
import { a11yProps } from '../Utils';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '110em',
  maxWidth: '85%',
  height: '80%',
  bgcolor: 'background.paper',
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};
export default function CameraWidget(props) {
  const { image, name, isNoRobotSelected } = props;

  const { isSubscribedStatus } = useContext(RobotViewContext);
  const [lastImageTime, setLastImageTime] = useState(null);

  const [openCameraModal, setOpenCameraModal] = useState(false);
  const handleCloseModal = () => setOpenCameraModal(false);

  const { t } = useTranslation();

  useEffect(() => {
    if (image) {
      setLastImageTime(new Date());
    }
  }, [image]);

  const handleExpandClick = () => () => {
    setOpenCameraModal(true);
  };

  return (
    <Card className="card-animation" sx={{ height: '100%' }}>
      {!isNoRobotSelected ? (
        <Grid container>
          <Grid xs={12} sx={{ borderBottom: 1, borderColor: 'divider', mb: 1 }}>
            <Tabs value={0} aria-label={`camera-`}>
              <Tab
                label={t(`overseer_app.widget.${name}`, name)}
                key={`camera-options-tab`}
                {...a11yProps(0, { textTransform: 'initial' })}
              />
              <Box sx={{ flexGrow: 1 }} />
              <Box
                className="width-box"
                sx={{ display: 'flex', overflow: 'hidden' }}
              >
                <Divider orientation="vertical" flexItem />
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontSize: 'smaller',
                    py: '5px',
                    mx: 1,
                    display: 'flex',
                  }}
                >
                  <Tooltip
                    title={t('cws_app.home.expand_width', 'Expand')}
                    placement="bottom"
                  >
                    <IconButton onClick={handleExpandClick()}>
                      <IconComponent
                        iconName={'resize'}
                        style={{
                          fontSize: 20,
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                </Typography>
              </Box>
            </Tabs>
          </Grid>
          <Grid xs={12}>
            <TabPanel value={0} index={0}>
              <Grid
                sx={{
                  p: 1,
                  width: '100%',
                  height: '18rem',
                }}
              >
                {!isSubscribedStatus ? (
                  <Skeleton
                    variant="rectangular"
                    width={'100%'}
                    height={'100%'}
                  />
                ) : image?.img ? (
                  <img
                    alt="camera-img"
                    style={{
                      borderRadius: '5px',
                      height: 'auto',
                      width: '100%',
                      maxHeight: '100%',
                      objectFit: 'contain',
                    }}
                    src={`data:image/jpeg;base64,${image.img}`}
                  />
                ) : (
                  <Box
                    sx={{
                      bgcolor: 'grey.100',
                      height: '10rem',
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    <Typography variant="body2" color="text.secondary">
                      {t('overseer_app.general.no_robot_selected', 'No robot selected')}
                    </Typography>
                  </Box>
                )}
                <LastRefresh lastUpdateTime={lastImageTime} timeInterval={2000} />
              </Grid>
            </TabPanel>
          </Grid>
        </Grid>
      ) : (
        <Grid container>
          <Grid xs={12} sx={{ borderBottom: 1, borderColor: 'divider', mb: 1 }}>
            <Tabs value={0} aria-label={`camera-`}>
              <Tab
                label={t(`overseer_app.widget.${name}`, name)}
                key={`camera-options-tab`}
                {...a11yProps(0, { textTransform: 'initial' })}
              />
              <Box sx={{ flexGrow: 1 }} />
              <Box
                className="width-box"
                sx={{ display: 'flex', overflow: 'hidden' }}
              >
                <Divider orientation="vertical" flexItem />
              </Box>
            </Tabs>
          </Grid>
          <Grid xs={12}>
            <TabPanel value={0} index={0}>
              <Grid
                sx={{
                  p: 1,
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 1,
                }}
              >
                <Box
                  sx={{
                    bgcolor: 'grey.100',
                    height: '10rem',
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <Typography variant="body2" color="text.secondary">
                    {t('overseer_app.general.no_robot_selected', 'No robot selected')}
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Typography variant="caption" color="text.secondary">
                    {t('overseer_app.widget.last_refresh', 'Last refresh')}
                  </Typography>
                  <Typography variant="caption" color="text.secondary">-</Typography>
                </Box>
              </Grid>
            </TabPanel>
          </Grid>
        </Grid>
      )}
      <Modal
        open={openCameraModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          {!isSubscribedStatus ? (
            <Skeleton
              variant="rectangular"
              width={'100%'}
              height={'100%'}
            />
          ) : image?.img ? (
            <img
              alt="camera-img"
              style={{
                borderRadius: '5px',
                height: 'auto',
                width: '100%',
                maxHeight: '100%',
                objectFit: 'contain',
              }}
              src={`data:image/jpeg;base64,${image.img}`}
            />
          ) : (
            <Box
              sx={{
                bgcolor: 'grey.300',
                height: 180,
                width: '100%',
              }}
            />
          )}
          <LastRefresh lastUpdateTime={lastImageTime} timeInterval={2000} />
        </Box>
      </Modal>
    </Card>
  );
}
