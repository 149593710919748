import { styled } from '@mui/material/styles';
import { ToggleButtonGroup, toggleButtonGroupClasses, Paper, Box, Divider } from '@mui/material';
import { IconComponent } from '@zippeditoolsjs/zippedi-icons';
import { useCallback, memo } from 'react';
import HotKeyToggleButton from './HotKeyToggleButton';
import { LAYOUT_TOOLS } from '../tools/useLayoutAnnotations';

const AnnotationsTools = styled(ToggleButtonGroup)(({ theme }) => ({
    [`& .${toggleButtonGroupClasses.grouped}`]: {
        margin: theme.spacing(0.5),
        borderRadius: '4px',
        border: 0,
    },
    [`& .${toggleButtonGroupClasses.middleButton},& .${toggleButtonGroupClasses.lastButton}`]: {
        marginLeft: -1,
        borderLeft: '1px solid transparent',
    },
}));

function ExcludeZoneIcon() {
    return (
        <Box sx={{ position: 'relative' }}>
            <IconComponent iconName="square-outline" style={{ fontSize: '24px' }} />
            <IconComponent iconName="close-outline" style={{ fontSize: '24px', position: 'absolute', top: 0, right: 0 }} />
        </Box>
    )
}

function AisleSideIcon() {
    return (
        <Box sx={{ position: 'relative' }}>
            <IconComponent iconName="square" style={{ fontSize: '24px' }} />
            <IconComponent iconName="arrow-up-outline" style={{ fontSize: '22px', position: 'absolute', top: 1, right: 1, transform: 'rotate(45deg)', color: '#FFF' }} />
        </Box>
    )
}

function AnnotationBar({ activeTool, setActiveTool, callbackActions, disabled }) {
    const onChange = useCallback((event, newValue) => {
        if (disabled) return;
        setActiveTool([newValue, callbackActions?.[newValue] || null]);
    }, [setActiveTool, callbackActions, disabled]);
    return <Paper
        elevation={0}
        sx={{
            display: 'flex',
            width: 'fit-content',
            marginLeft: { xs: '0', sm: 'auto' },
            boxShadow: (theme) => theme.shadows[1],
        }}
    >
        <AnnotationsTools value={activeTool} exclusive onChange={onChange} aria-label="annotations tools">
            <HotKeyToggleButton value={LAYOUT_TOOLS.MOVE} aria-label="move-tool" hotKey="1" label="Move">
                <IconComponent iconName="hand-left" style={{ fontSize: '24px' }} />
            </HotKeyToggleButton>
            <Divider flexItem orientation="vertical" sx={{ mx: 0.5, my: 1 }} />
            <HotKeyToggleButton value={LAYOUT_TOOLS.ZONE} aria-label="zone-tool" hotKey="2" label="Zone">
                <IconComponent iconName="map-outline" style={{ fontSize: '24px' }} />
            </HotKeyToggleButton>
            <HotKeyToggleButton value={LAYOUT_TOOLS.AISLE} aria-label="aisle_fixture-tool" hotKey="3" label="Aisle/Fixture">
                <IconComponent iconName="square-outline" style={{ fontSize: '24px' }} />
            </HotKeyToggleButton>
            <HotKeyToggleButton value={LAYOUT_TOOLS.AISLE_SIDE} aria-label="aisle_side-tool" hotKey="4" label="Aisle Side">
                <AisleSideIcon />
            </HotKeyToggleButton>
            <HotKeyToggleButton value={LAYOUT_TOOLS.HOME} aria-label="home-tool" hotKey="5" label="Home">
                <IconComponent iconName="home" style={{ fontSize: '24px' }} />
            </HotKeyToggleButton>
            <HotKeyToggleButton value={LAYOUT_TOOLS.EXCLUSION_ZONE} aria-label="exclude_zone-tool" hotKey="6" label="Exclusion Zone">
                <ExcludeZoneIcon />
            </HotKeyToggleButton>
            <Divider flexItem orientation="vertical" sx={{ mx: 0.5, my: 1 }} />
            <HotKeyToggleButton disabled value='area' aria-label="area-tool" hotKey="7" label="Area">
                <IconComponent iconName="square-outline" style={{ fontSize: '24px' }} />
            </HotKeyToggleButton>
            <HotKeyToggleButton disabled value='line' aria-label="line-tool" hotKey="8" label="Line">
                <AisleSideIcon />
            </HotKeyToggleButton>
            <HotKeyToggleButton disabled value='pose' aria-label="pose-tool" hotKey="9" label="Pose">
                <IconComponent iconName="poses-layouts" width={24} height={24} />
            </HotKeyToggleButton>
        </AnnotationsTools>
    </Paper>
}

export default memo(AnnotationBar);