import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Autocomplete, Checkbox, CircularProgress, FormControl, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function FilterMultiSelector(props) {
  const {
    required = false,
    error = false,
    id = 'multi-selector',
    isLoading,
    inputVariant,
    inputLabel = 'Select',
    options,
    canSelectAll = true,
    objectName = 'name',
    objectId = 'id',
    inputSelected,
    setInputSelectedOptions,
    isDisabled = false,
    maxLabelLength = 3,
  } = props;

  const [selectedOption, setSelectedOption] = useState([]);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const { t } = useTranslation();
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  useEffect(() => {
    if (inputSelected) {
      setSelectedOption(inputSelected);
    }
  }, [JSON.stringify(inputSelected)]);

  useEffect(() => {
    setInputSelectedOptions(selectedOption);
    setIsAllSelected(selectedOption.length === options.length);
    return () => {
      setInputSelectedOptions([]);
    };
  }, [selectedOption, options]);

  const handleChange = (event, newValue) => {
    if (newValue.find((option) => option[objectId] === 'select-all')) {
      handleSelectAll();
    } else {
      setSelectedOption(newValue);
    }
  };

  const handleSelectAll = () => {
    if (isAllSelected) {
      setSelectedOption([]);
    } else {
      setSelectedOption(options.filter((option) => !option.excluded));
    }
    setIsAllSelected(!isAllSelected);
  };

  const handleRenderValue = (selected) => {
    const previewString = selected
      .slice(0, maxLabelLength)
      .flatMap((obj) =>
        obj !== undefined
          ? [options.find((option) => option[objectId] === obj[objectId])[objectName]]
          : []
      )
      .join(', ');

    return selected.length > maxLabelLength ? `${previewString}, ...` : previewString;
  };

  return (
    <FormControl
      id={`multi-selector-${id}`}
      key={`multi-selector-${id}`}
      variant={inputVariant}
      sx={{ width: '100%' }}
      disabled={isDisabled || isLoading}
      required={required}
      error={error}
    >
      <Autocomplete
        multiple
        id={`autocomplete-${id}`}
        options={canSelectAll ? [{ [objectId]: 'select-all', [objectName]: t('Select All') }, ...options] : options}
        disableCloseOnSelect
        getOptionLabel={(option) => option[objectName]}
        isOptionEqualToValue={(option, value) => option[objectId] === value[objectId]}
        value={selectedOption}
        onChange={handleChange}
        renderOption={(props, option, { selected }) => (
          <li {...props} key={`Li_${option[objectId]}_${option[objectName]}`}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              checked={
                option[objectId] === 'select-all' ? isAllSelected : selected
              }
              style={{ marginRight: 8 }}
            />
            {option[objectName]}
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label={inputLabel}
            variant="outlined"
            placeholder={`Search ${inputLabel}`}
          />
        )}
        getOptionDisabled={(option) => option.excluded}
        renderTags={(tagValue, getTagProps) => handleRenderValue(tagValue)}
      />
      {isLoading && <CircularProgress size={24} sx={{ position: 'absolute', top: 15, right: 9 }} />}
    </FormControl>
  );
}
