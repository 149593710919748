import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';

import { LinearProgress, Paper, Stack, Tab, Tabs } from '@mui/material'
import { isEmptyOrUndefined } from '@zippeditoolsjs/blocks';

import { DEBOUNCE_WAIT_TIME } from '../Utils';
import TabPanel from '../tools/TabPanelv2';
import { useDebounce } from '../utils/debounce';
import Categories from './categories/Categories';
import Parameters from './parameters/Parameters';
import RobotParameters from './robotParameters/RobotParameters';

export default function ParametersHome(props) {
  const {
    getChains,
    chains,
    isLoadingChains,
    getRobotsBaldur,
    robots,
    isLoadingRobots,
    getRobotParameters,
    robotParameters,
    isLoadingRobotParameters,
    getGroupedRobotParameters,
    groupedRobotParameters,
    isLoadingGroupedRobotParameters,
    getParameters,
    parameters,
    isLoadingParameters,
    postParameters,
    parametersPostResponse,
    isLoadingParametersPostResponse,
    patchParameters,
    parametersPatchResponse,
    isLoadingParametersPatchResponse,
    deleteParameters,
    parametersDeleteResponse,
    isLoadingParametersDeleteResponse,
    getParameterDataTypes,
    parameterDataTypes,
    isLoadingParameterDataTypes,
    getParameterCategories,
    parameterCategories,
    isLoadingParameterCategories,
    postParameterCategories,
    parameterCategoriesPostResponse,
    isLoadingParameterCategoriesPostResponse,
    patchParameterCategories,
    parameterCategoriesPatchResponse,
    isLoadingParameterCategoriesPatchResponse,
    deleteParameterCategories,
    parameterCategoriesDeleteResponse,
    isLoadingParameterCategoriesDeleteResponse,
    postRobotParameters,
    isLoadingRobotParamsPost,
    robotParamsPostResponse,
    setSnackAlert,
    getRobotStatuses,
    robotStatuses,
    isLoadingRobotStatuses,
    checkFeaturePermission,
    featurePermission
  } = props;
  const { t } = useTranslation();
  // States
  const [tab, setTab] = useState(0);
  // Robot Parameters States
  const [selectedRobots, setSelectedRobots] = useState([]);
  const [canUpdate, setCanUpdate] = useState(false);
  // Other constants
  const debouncedSelectedRobots = useDebounce(selectedRobots, DEBOUNCE_WAIT_TIME, [])
  const MODIFY_ROBOT_PARAMETERS_PERMISSION = 'modify_robot_parameters';

  // Lifecycle methods

  useEffect(() => {
    checkFeaturePermission(MODIFY_ROBOT_PARAMETERS_PERMISSION);
    getParameters();
    getParameterDataTypes();
    getParameterCategories();
  }, [checkFeaturePermission, getParameters, getParameterDataTypes, getParameterCategories]);

  // Check if the user has the permission to access the parameters
  useEffect(() => {
    if (MODIFY_ROBOT_PARAMETERS_PERMISSION in featurePermission) {
      setCanUpdate(!!featurePermission[MODIFY_ROBOT_PARAMETERS_PERMISSION])
    }
  }, [featurePermission]);

  // Methods

  const handleTabChange = (_, newValue) => {
    setTab(newValue);
  };

  const getDataType = (dataType) => {
    if (dataType === 'int' || dataType === 'float') return 'number'
    return ''
  }

  const setDataType = (value, dataType) => {
    if (value) {
      if (dataType === 'int') {
        return parseInt(value)
      } else if (dataType === 'float') {
        return parseFloat(value)
      } else if (dataType === 'bool') {
        const castValue = value.toLowerCase()
        // If the boolean string is completed (i.e. the user finished typing), return as boolean type
        // Else return the string in lowercase
        if (castValue === 'true' || castValue === 'false') {
          return castValue === 'true'
        }
        return castValue;
      } else if (dataType === 'list') {
        return value.replace(/\s/g, '').split(',')
      } else {
        return String(value)
      }
    }
    return value
  }

  return (
    <Stack component={Paper} sx={{ minHeight: 'calc(100vh - 15em)' }}>
      {!isEmptyOrUndefined(debouncedSelectedRobots, 'array') && (isLoadingGroupedRobotParameters || isLoadingRobotParameters) &&
        <LinearProgress
          sx={{ borderRadius: '10px 10px 0 0' }}
          color="secondary"
        />
      }
      {/* Tabs */}
      <Tabs value={tab} onChange={handleTabChange} aria-label="basic tabs example">
        <Tab label={t('overseer_app.parameters.Robot_parameters', 'Robot Parameters')} />
        <Tab label={t('overseer_app.parameters.Parameters', 'Parameters')} />
        <Tab label={t('overseer_app.parameters.Categories', 'Categories')} />
      </Tabs>
      {/* Robot Parameters Tab Panel */}
      <TabPanel component={Stack} value={tab} index={0} sx={{ p: 2, minHeight: 'inherit' }}>
        <RobotParameters
          t={t}
          debouncedSelectedRobots={debouncedSelectedRobots}
          getChains={getChains}
          chains={chains}
          isLoadingChains={isLoadingChains}
          getRobotsBaldur={getRobotsBaldur}
          robots={robots}
          isLoadingRobots={isLoadingRobots}
          getRobotParameters={getRobotParameters}
          robotParameters={robotParameters}
          isLoadingRobotParameters={isLoadingRobotParameters}
          getGroupedRobotParameters={getGroupedRobotParameters}
          groupedRobotParameters={groupedRobotParameters}
          isLoadingGroupedRobotParameters={isLoadingGroupedRobotParameters}
          parameters={parameters}
          selectedRobots={selectedRobots}
          setSelectedRobots={setSelectedRobots}
          postRobotParameters={postRobotParameters}
          isLoadingRobotParamsPost={isLoadingRobotParamsPost}
          robotParamsPostResponse={robotParamsPostResponse}
          setSnackAlert={setSnackAlert}
          getRobotStatuses={getRobotStatuses}
          robotStatuses={robotStatuses}
          isLoadingRobotStatuses={isLoadingRobotStatuses}
          canUpdate={canUpdate}
          getDataType={getDataType}
          setDataType={setDataType}
        />
      </TabPanel>
      {/* Parameters Tab Panel */}
      <TabPanel component={Paper} value={tab} index={1} sx={{ m: 2, minHeight: 'inherit' }}>
        <Parameters
          t={t}
          canUpdate={canUpdate}
          getParameters={getParameters}
          parameters={parameters}
          isLoadingParameters={isLoadingParameters}
          parameterDataTypes={parameterDataTypes}
          isLoadingParameterDataTypes={isLoadingParameterDataTypes}
          parameterCategories={parameterCategories}
          isLoadingParameterCategories={isLoadingParameterCategories}
          getDataType={getDataType}
          setDataType={setDataType}
          postParameters={postParameters}
          parametersPostResponse={parametersPostResponse}
          isLoadingParametersPostResponse={isLoadingParametersPostResponse}
          patchParameters={patchParameters}
          parametersPatchResponse={parametersPatchResponse}
          isLoadingParametersPatchResponse={isLoadingParametersPatchResponse}
          deleteParameters={deleteParameters}
          parametersDeleteResponse={parametersDeleteResponse}
          isLoadingParametersDeleteResponse={isLoadingParametersDeleteResponse}
        />
      </TabPanel>
      {/* Categories Tab Panel */}
      <TabPanel component={Paper} value={tab} index={2} sx={{ m: 2, minHeight: 'inherit' }}>
        <Categories
          t={t}
          canUpdate={canUpdate}
          getParameterCategories={getParameterCategories}
          parameterCategories={parameterCategories}
          isLoadingParameterCategories={isLoadingParameterCategories}
          postParameterCategories={postParameterCategories}
          parameterCategoriesPostResponse={parameterCategoriesPostResponse}
          isLoadingParameterCategoriesPostResponse={isLoadingParameterCategoriesPostResponse}
          patchParameterCategories={patchParameterCategories}
          parameterCategoriesPatchResponse={parameterCategoriesPatchResponse}
          isLoadingParameterCategoriesPatchResponse={isLoadingParameterCategoriesPatchResponse}
          deleteParameterCategories={deleteParameterCategories}
          parameterCategoriesDeleteResponse={parameterCategoriesDeleteResponse}
          isLoadingParameterCategoriesDeleteResponse={isLoadingParameterCategoriesDeleteResponse}
        />
      </TabPanel>
    </Stack>
  )
}
