import React from 'react'

import LoadingButton from '@mui/lab/LoadingButton';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, ListItemText, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { isEmptyOrUndefined } from '@zippeditoolsjs/blocks';
import { IconComponent } from '@zippeditoolsjs/zippedi-icons';

import { changesColor } from '../../Utils';

export default function ConfirmDialog(props) {
  const {
    t,
    openConfirmDialog,
    setOpenConfirmDialog,
    changes,
    robotByUUID,
    parameters,
    postRobotParameters,
    isLoadingRobotParamsPost,
    selectedRobots,
    oldParameters,
    deletedParameters,
  } = props;
  const theme = useTheme();
  const isSmDownBreakpoint = useMediaQuery(theme.breakpoints.down("sm"))

  // Lifecycle methods


  // Methods 

  const handleClose = () => {
    setOpenConfirmDialog(false);
  };

  const handleSaveChanges = () => {
    let finalChanges = []
    let oldParams = structuredClone(oldParameters);

    // Remove the deleted parameters from oldParameters
    Object.entries(oldParams).forEach(([robot_uuid, parameters]) => {
      Object.entries(parameters).forEach(([code, value]) => {
        if (deletedParameters.includes(code)) {
          delete oldParams[robot_uuid][code]
        }
      })
    })

    selectedRobots.forEach(robot => {
      if (robot.robot_uuid in changes.add || robot.robot_uuid in changes.edit || robot.robot_uuid in changes.delete) {
        finalChanges.push({
          "robot_uuid": robot.robot_uuid,
          "parameters": {
            ...changes.add[robot.robot_uuid],
            ...changes.edit[robot.robot_uuid],
            ...oldParams[robot.robot_uuid]
          }
        })
      }
    })

    postRobotParameters({
      "parameters": finalChanges
    })
  };

  const ChangesComponent = ({ data, title, changeType }) => {
    return (
      <Stack spacing={2} sx={{ flex: 1, mx: { xs: '', sm: 2 } }}>
        {/* Title: Add, Edit or Delete */}
        <Grid container justifyContent='center'>
          <Typography sx={{ color: changesColor(changeType) }}>{title}</Typography>
        </Grid>
        <Stack spacing={1} alignItems={isEmptyOrUndefined(data, 'object') && 'center'} divider={<Divider orientation='horizontal' />}>
          {isEmptyOrUndefined(data, 'object') ?
            <Typography variant='caption' color='GrayText' >{t('overseer_app.parameters.No_changes', 'No changes.')}</Typography>
            :
            Object.entries(data).map(([robot_uuid, params]) => (
              <Stack key={robot_uuid}>
                {/* Robot code */}
                <Typography variant='body2' sx={{ fontWeight: 'bold' }}>{robotByUUID[robot_uuid].robot_code}</Typography>
                {/* Parameters loop */}
                {Object.entries(params).map(([paramName, paramValue]) => {
                  // TODO: this filter is inneficient because it searches for every param for every robot in "changes". Find another way to get the data_type.
                  const parameter = parameters.filter(param => param.code === paramName)?.[0]
                  // Returning when data_type = list
                  if (parameter.data_type === 'list') {
                    return (
                      <Stack key={paramName}>
                        <ListItemText
                          disableTypography
                          primary={<Typography variant='body2'>{paramName}</Typography>}
                          secondary={
                            paramValue.map(item => (
                              <Typography key={item} variant='body2' color='GrayText'>{item}</Typography>
                            ))
                          }
                        />
                      </Stack>
                    )
                  }
                  // Returning when data_type = secret
                  if (parameter.data_type === 'secret') {
                    return (
                      <Stack key={paramName}>
                        <ListItemText
                          disableTypography
                          primary={<Typography variant='body2'>{paramName}</Typography>}
                          secondary={
                            <>
                              <Typography variant='body2' color='GrayText'>project_id: {paramValue.project_id}</Typography>
                              <Typography variant='body2' color='GrayText'>secret_id: {paramValue.secret_id}</Typography>
                              <Typography variant='body2' color='GrayText'>version_id: {paramValue.version_id}</Typography>
                            </>
                          }
                        />
                      </Stack>
                    )
                  }
                  // Normal data_type
                  return (
                    <ListItemText
                      disableTypography
                      key={paramName}
                      primary={<Typography variant='body2'>{paramName}</Typography>}
                      secondary={<Typography variant='body2' color='GrayText'>{String(paramValue)}</Typography>}
                    />
                  )
                })}
              </Stack>
            ))}
        </Stack>
      </Stack>
    )
  }

  return (
    <Dialog
      fullWidth
      maxWidth={'md'}
      open={openConfirmDialog}
      onClose={handleClose}
    >
      {/* Dialog title */}
      <DialogTitle>{t('overseer_app.parameters.save_changes', 'Save These Changes?')}</DialogTitle>
      {/* Dialog content */}
      <DialogContent dividers>
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          spacing={{ xs: 1, sm: 2, md: 4 }}
          divider={<Divider flexItem orientation={isSmDownBreakpoint ? 'horizontal' : 'vertical'} />}
          justifyContent='space-between'
          sx={{ width: '100%' }}
        >
          {/* Added */}
          <ChangesComponent
            data={changes.add}
            title={t('overseer_app.parameters.Add', 'Add')}
            changeType={'add'}
          />
          {/* Edited */}
          <ChangesComponent
            data={changes.edit}
            title={t('overseer_app.parameters.Edit', 'Edit')}
            changeType={'edit'}
          />
          {/* Deleted */}
          <ChangesComponent
            data={changes.delete}
            title={t('overseer_app.parameters.Delete', 'Delete')}
            changeType={'delete'}
          />
        </Stack>
      </DialogContent>
      {/* Dialog actions */}
      <DialogActions>
        <Button autoFocus onClick={handleClose}>
          {t('overseer_app.general.Cancel', 'Cancel')}
        </Button>
        <LoadingButton
          loading={isLoadingRobotParamsPost}
          loadingPosition="start"
          startIcon={<IconComponent
            iconName={'save'}
            style={{ fontSize: "20px" }}
          />}
          onClick={handleSaveChanges}
        >
          {t('overseer_app.general.Save', 'Save')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
