import { Grid, Collapse, Alert, TextField, MenuItem } from "@mui/material";
import { useImperativeHandle, forwardRef, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

const DEFAULT_COLOR = null
const EXCLUSION_LAYER = 'nav_excluded'

const DEFAULT_VALUES = {
    color: DEFAULT_COLOR,
    name: '',
    clientName: '',
}

export default forwardRef(function DialogCreationExclusion({
    addNewLayer,
    dialogData,
    isUniqueName,
}, ref) {

    const [formState, setFormState] = useState(DEFAULT_VALUES);
    const [errors, setErrors] = useState({});

    const { t } = useTranslation();

    const validateField = useCallback((field, value) => {
        const validationRules = {
            name: { required: true, unique: true, notAllowSpaces: true },
        };
        const rule = validationRules?.[field];
        if (rule && rule.required && (!value || value === ''))
            return 'This field is required';
        if (rule && rule.unique && !isUniqueName(value, EXCLUSION_LAYER))
            return 'This name is already in use';
        if (rule && rule.notAllowSpaces && value?.includes(' '))
            return 'This field does not allow spaces';
        return null;
    }, [isUniqueName]);

    const validateAllFields = useCallback(() => {
        const errors = {};
        Object.keys(formState).forEach(field => {
            const error = validateField(field, formState[field]);
            if (error) {
                errors[field] = error;
            }
        });
        setErrors(errors);
        if (Object.keys(errors).length > 0) {
            return false;
        }
        return true;
    }, [formState, validateField]);

    const handleFieldChange = useCallback((field, value) => {
        setErrors(prev => ({ ...prev, [field]: validateField(field, value) || undefined }));
        setFormState(prev => ({ ...prev, [field]: value }));
    }, [validateField]);


    const existsError = useMemo(() => Object.keys(errors).filter(key => !!errors[key]).length > 0, [errors]);

    const onSave = useCallback(() => {
        const isValid = validateAllFields();
        if (!isValid) return false;
        const { data: { start, end } } = dialogData;
        const { name, clientName, color } = formState;
        addNewLayer(EXCLUSION_LAYER, {
            name: name.trim(),
            clientName: clientName.trim(),
            color,
            bbox: [
                { x: start[0], y: start[1] },
                { x: end[0], y: end[1] }
            ],
            type: 'rectangle',
        });
        return true;
    }, [addNewLayer, dialogData, formState, validateAllFields]);

    useImperativeHandle(ref, () => ({
        callbackOnSave: onSave
    }), [onSave]);

    return <>
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Collapse in={existsError}>
                    <Alert severity="error">
                        {t('overseer_app.layouts.creation.default_error', 'Some fields are required or must be unique to create a new annotation in this Layer.')}
                    </Alert>
                </Collapse>
            </Grid>
            <Grid item xs={12}>
                <TextField label="Layer" fullWidth required select value={EXCLUSION_LAYER} disabled>
                    <MenuItem value={EXCLUSION_LAYER}>{EXCLUSION_LAYER}</MenuItem>
                </TextField>
            </Grid>
            <Grid item xs={12}>
                <TextField label="Exclusion zone name" fullWidth required value={formState?.name} onChange={(e) => handleFieldChange('name', e.target.value)} error={!!errors?.name} helperText={t('overseer_app.layouts.creation.not_allow_spaces', 'This field does not allow spaces')} />
            </Grid>
            <Grid item xs={12}>
                <TextField label="Client's exclusion zone name" fullWidth value={formState?.clientName} onChange={(e) => handleFieldChange('clientName', e.target.value)} />
            </Grid>
        </Grid>
    </>
})