import { Card, CardContent, LinearProgress, Tabs, Tab, Badge } from '@mui/material';
import { useState, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { setSnackAlert } from "core/slices/snackAlert";
import { a11yProps } from '../Utils';
import VersionsSidebar from './VersionsSidebar';
import LayersSidebar from './LayersSidebar';
import FallbackInformation from './FallbackInformation';
import WarningsSidebar from './WarningsSidebar';

const SIDEBAR_TABS = {
    LAYERS: 0,
    VERSIONS: 1,
    WARNINGS: 2,
}

export default function Sidebar({
    isLoading,
    isFullLoading,
    store,
    floor,
    currentVersion,
    layers,
    toggles,
    disabled,
    setDialogEdit,
    existWarnings,
    warnings,
    deleteAnnotation,
}) {
    const [currentTab, setCurrentTab] = useState(SIDEBAR_TABS.LAYERS);
    const [loadingNewVersion, setLoadingNewVersion] = useState(false);

    const { t } = useTranslation();
    const { isLoadingStoreLayout, isLoadingStoreMapLayout, storeMapLayout, storeLayout } = useSelector(state => state.layouts);
    const dispatch = useDispatch();

    useEffect(() => {
        if (loadingNewVersion && !isLoadingStoreLayout && !isLoadingStoreMapLayout) {
            const alertDict = {
                success: {
                    message: t('overseer_app.layouts.version_loaded', 'Version loaded successfully!'),
                    severity: 'success',
                },
                error: {
                    message: t('overseer_app.layouts.version_not_loaded', 'Version not loaded!'),
                    severity: 'error',
                },
            };
            dispatch(setSnackAlert({
                ...alertDict[storeMapLayout?.id && storeLayout?.id ? 'success' : 'error'],
                open: true,
            }));
            setLoadingNewVersion(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoadingStoreLayout, isLoadingStoreMapLayout]);

    const showInformation = useMemo(() => store && floor, [store, floor]);
    const tabs = useMemo(() => {
        return {
            [SIDEBAR_TABS.LAYERS]: {
                label: t('overseer_app.layouts.layers', 'Layers'),
                component: <LayersSidebar layers={layers || {}} toggles={toggles} setDialogEdit={setDialogEdit} deleteAnnotation={deleteAnnotation} />,
                disabled: !storeMapLayout?.id,
            },
            [SIDEBAR_TABS.VERSIONS]: {
                label: t('overseer_app.layouts.versions', 'Versions'),
                component: <VersionsSidebar store={store} floor={floor} currentVersion={currentVersion} setLoadingNewVersion={setLoadingNewVersion} />,
                disabled: false,
            },
            [SIDEBAR_TABS.WARNINGS]: {
                label: t('overseer_app.layouts.warnings', 'Warnings'),
                component: <WarningsSidebar warnings={warnings} setDialogEdit={setDialogEdit} />,
                disabled: !storeMapLayout?.id,
                badge: existWarnings && !isLoading,
            },
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentVersion, floor, layers, store, t, toggles, storeMapLayout, existWarnings, isLoading, warnings]);

    useEffect(() => {
        if (!disabled && !storeMapLayout?.id) {
            setCurrentTab(SIDEBAR_TABS.VERSIONS);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [storeMapLayout?.id]);

    return (
        <Card sx={{ flexGrow: 1, position: 'relative', pb: 2, display: 'flex', flexDirection: 'column' }}>
            {isLoading && <LinearProgress sx={{ width: '100%', top: '0', left: '0', position: 'absolute' }} color="secondary" />}
            <Tabs value={currentTab} sx={{ borderBottom: 1, borderColor: 'divider' }} onChange={(event, newValue) => setCurrentTab(newValue)} variant="scrollable" scrollButtons={false}>
                {Object.entries(tabs).map(([key, value]) => (
                    <Tab key={key} wrapped label={value?.badge ? <Badge color="error" variant="dot">{value.label}</Badge> : value.label} {...a11yProps(key, { textTransform: 'initial' })} disabled={disabled || value.disabled} />
                ))}
            </Tabs>
            <CardContent sx={{ flexGrow: 1, overflow: 'auto', p: 0 }}>
                {(isFullLoading || !showInformation) && (
                    <FallbackInformation showLoading={showInformation}>
                        {showInformation ? t('overseer_app.layouts.loading_info', 'Loading information...') : t('overseer_app.layouts.need_to_select_store_information', 'Select a store and floor to view the information')}
                    </FallbackInformation>
                )}
                {(showInformation && !isFullLoading) && (tabs[currentTab].component || <></>)}
            </CardContent>
        </Card>
    )
}