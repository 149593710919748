import { Dialog, DialogActions, DialogTitle, DialogContent, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import { boldString } from "../Utils";
export default function DialogNewMap({ open, onClose, onSubmit }) {
    const { t } = useTranslation();
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{t('overseer_app.layouts.new_map_detected', 'A new map has been detected')}</DialogTitle>
            <DialogContent>
                <Typography variant="body1">{boldString(t('overseer_app.layouts.new_map_detected_description', 'Do you want to **KEEP** the current layout?'))}</Typography>
                <br />
                <Typography variant="body1">{boldString(t('overseer_app.layouts.new_map_detected_description_2', 'If you reject, the current layout will be deleted, but the changes will only be saved when clicking **Save changes**'))}</Typography>
            </DialogContent>
            <DialogActions>
                <Button color="secondary" onClick={onClose}>{t('overseer_app.layouts.cancel', 'Cancel')}</Button>
                <Button variant="contained" color="primary" onClick={onSubmit}>{t('overseer_app.layouts.continue', 'Continue')}</Button>
            </DialogActions>
        </Dialog >
    )
}