import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SchedulerViewContext } from '../../../context/SchedulerView';
import MultiSelector from '../../tools/MultiSelector';
import ScanningForm from './ScanningForm';
import ScheduleForm from './ScheduleForm';

import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Stepper from '@mui/material/Stepper';
import { IconComponent } from '@zippeditoolsjs/zippedi-icons';
import { handleGetUserUtcOffset } from '../../utils/commons';
import { DIALOG_TYPES, NO_EDIT_SESSION_HOURS_BEFORE } from '../Utils';
import ConfirmCancelSessionForm from './ConfirmCancelForm';
import ReasonForm from './ReasonForm';

export default function SessionForm(props) {
  const {
    isDisabled,
    openModal,
    setOpenModal,
    actualRowClicked = {},
    dateCellSelected,
    sessionSelected,
    selectedRobots,
    isLoadingLayout,
    actionReasons,
    isLoadingActionReasons,
    getActionReasons,
    postNewSessions,
    updateNewSessions,
    cancelSessions,
    removeNewSessionResponse,
    newSessionResponse,
    loadingNewSessions,
    onClose,
    setSnackAlert,
    cancelMessage,
    removeCancelSessionResponse,
    getStoresUtcOffset,
    storesUtcOffset,
    isLoadingStoresUtcOffset,
  } = props;

  const {
    selectedChain,
    dialogType,
  } = useContext(SchedulerViewContext);

  const [selectedRobot, setSelectedRobot] = useState([]);
  const [selectedStore, setSelectedStore] = useState('');
  const [selectedFloor, setSelectedFloor] = useState('1');
  const [sessionsSteps, setSessionsSteps] = useState(dialogType !== DIALOG_TYPES.ONLY_VIEW ? ['Schedule', 'Scanning', 'Comments'] : ['Schedule', 'Scanning']);
  const [openCancelWarning, setOpenCancelWarning] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [confirmRemoveSession, setConfirmRemoveSession] = useState('');
  const [minStartTime, setMinStartTime] = useState(null);

  const paperRef = useRef(null);
  const reasonForm = useRef({});
  const [reasonError, setReasonError] = useState(false);
  const scheduleForm = useRef({});
  const scanningForm = useRef({});

  const { t } = useTranslation();

  // Hooks
  useEffect(() => {
    if (openModal) {
      getActionReasons({ command: [DIALOG_TYPES.MULTIPLE_ADD, DIALOG_TYPES.ADD].includes(dialogType) ? 'create_session' : 'update_session' });
    }
  }, [openModal]);

  useEffect(() => {
    if (dialogType === DIALOG_TYPES.ONLY_VIEW) {
      setSessionsSteps(['Schedule', 'Scanning']);
    } else {
      setSessionsSteps(['Schedule', 'Scanning', 'Comments']);
    }
  }, [dialogType]);

  useEffect(() => {
    const selectedRobotAux = selectedRobots.find(robot => compareRobots(robot, actualRowClicked));

    if (selectedRobotAux) setSelectedRobot([selectedRobotAux]);

    setSelectedStore(selectedRobotAux?.store);
    setSelectedFloor(selectedRobotAux?.floor ?? 1);

    if (openModal) {
      setActiveStep(0);
      scheduleForm.current.isTouched = undefined;
      scanningForm.current.isTouched = undefined;
    }
  }, [openModal]);

  useEffect(() => {
    if (openModal && selectedRobots.length > 0 && actualRowClicked?.store && !isLoadingStoresUtcOffset) {
      const selectedRobotAux = selectedRobots.find(robot => compareRobots(robot, actualRowClicked));
      handleMinStartTime(selectedRobotAux?.store, selectedRobotAux);
    }
  }, [selectedRobots, openModal, actualRowClicked]);


  useEffect(() => {
    if (!loadingNewSessions) {
      if (newSessionResponse?.message === 'Success, Session created') {
        const snack = {
          open: true,
          message: `Session successfully created for ${selectedRobot[0].robot_code}`,
          severity: 'success',
        };
        setSnackAlert(snack);
        onClose({ canReload: true });
        removeNewSessionResponse();
        removeWarnings();
      } else if (newSessionResponse?.message === "Success, Session patched") {
        const snack = {
          open: true,
          message: `Session successfully updated for ${selectedRobot[0].robot_code}`,
          severity: 'success',
        };
        setSnackAlert(snack);
        onClose({ canReload: true });
        removeNewSessionResponse();
        removeWarnings();
      } else if (newSessionResponse?.message === "Conflicts found in sessions") {
        const snack = {
          open: true,
          message: 'Another session is already scheduled for this robot at this time, please check the selected dates',
          severity: 'error',
        };
        setSnackAlert(snack);
        removeNewSessionResponse();
        setActiveStep(0);

        const {
          conflicts_list
        } = newSessionResponse.data;

        let warnings = []
        conflicts_list.forEach(conflict => {
          warnings = warnings.concat(conflict.conflicted_sessions.map(conflicted_session => {
            const sessionType = conflicted_session.type;
            const startTime = dayjs(conflicted_session.local_start_time, 'HH:mm').format('hh:mm A');
            const parsedWeekDays = conflicted_session.week_days.map((dayNumber => dayjs().day(dayNumber).format('dddd'))).join(', ');
            let customMessage = '';
            if (sessionType === 'Periodic') {
              customMessage = `For all days ${parsedWeekDays} from ${conflicted_session.local_start_date} to ${conflicted_session.local_end_date}.`;
            } else if (sessionType === 'Unique') {
              customMessage = `For this day ${conflicted_session.local_start_date}.`;
            }
            return <span>Conflict with <b>{sessionType}</b> session starting at <b>{startTime}</b> and lasting <b>{conflicted_session.max_minutes}</b> minutes. {customMessage}</span>
          }
          )
          )
        });
        scheduleForm.current = {
          ...scheduleForm.current,
          warnings
        }
      } else if (cancelMessage?.message === 'Success, Session cancelled') {
        const snack = {
          open: true,
          message: `${cancelMessage.data?.length} Session(s) successfully cancelled`,
          severity: 'success',
        };
        setSnackAlert(snack);
        onClose({ canReload: true });
        removeCancelSessionResponse();
      }
    }
  }, [loadingNewSessions, newSessionResponse]);

  useEffect(() => {
    if (!isLoadingStoresUtcOffset && storesUtcOffset?.[selectedStore]) {
      setMinStartTime(handleCalculateMinStartTime());
    }
  }, [storesUtcOffset, isLoadingStoresUtcOffset, openModal]);


  // Handlers

  const compareRobots = (firstRobot, secondRobot) => {
    let firstRobotCode, secondRobotCode;

    if (secondRobot?.multirobot_id) {
      firstRobotCode = `${firstRobot?.store}-${firstRobot?.floor}-${firstRobot?.multirobot_id}`
      secondRobotCode = `${secondRobot?.store}-${secondRobot?.floor}-${secondRobot?.multirobot_id}`
    } else {
      // TODO: remove this logic when multirobot_id always comes in.
      firstRobotCode = firstRobot?.robot_uuid
      secondRobotCode = secondRobot?.robot_uuid
    }

    return firstRobotCode === secondRobotCode
  }

  const handleCalculateMinStartTime = () => {
    const storeUtcOffset = storesUtcOffset[selectedStore];
    const utcToday = dayjs().subtract(handleGetUserUtcOffset(), 'hours');
    const storeToday = utcToday.add(storeUtcOffset, 'hours');
    const minStartTime = storeToday.add(NO_EDIT_SESSION_HOURS_BEFORE, 'hours');
    return dayjs(dateCellSelected.date).isAfter(minStartTime) ? null : minStartTime;
  }

  const removeWarnings = () => {
    scheduleForm.current = {
      ...scheduleForm.current,
      warnings: '',
    }
  }
  const totalSteps = () => {
    return sessionsSteps.length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const handleNext = () => {
    if (!isLastStep()) {
      setActiveStep(activeStep + 1);
    } else if (isDisabled) {
      handleCloseForm({ canCheckClose: false });
    } else {
      handleCompleteForm();
    }
  };

  const handleBack = () => {
    if (activeStep === 0) {
      handleCloseForm();
      return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleCompleteForm = () => {
    const {
      coordinates,
      robot_uuid,
      store,
      floor,
      multirobot_id,
    } = selectedRobot[0];

    const {
      repeatsOn: week_days,
      startTime: local_start_time,
      endTime: local_end_time,
      startDate: local_start_date,
      endDate: local_end_date,
      isTouched: isTouchedSchedule,
    } = scheduleForm.current;

    let max_minutes = dayjs(local_end_time, 'HH:mm').diff(dayjs(local_start_time, 'HH:mm'), 'minutes');
    max_minutes = max_minutes < 0 ? (max_minutes + (24 * 60)) : max_minutes;
    const {
      planner,
      selectedZones: zones,
      ohMode: overhead_mode,
      selectedAisles: omitted_aisles,
      isTouched: isTouchedScanning,
    } = scanningForm.current;
    const {
      comment,
      reason,
    } = reasonForm.current;
    const comments = JSON.stringify({ comment, reason });
    const session_id = dialogType === DIALOG_TYPES.EDIT ? sessionSelected.session_id : undefined;
    const periodic_session_id = dialogType === DIALOG_TYPES.EDIT ? sessionSelected.periodic_session_id : undefined;
    const minTimeAllowed = handleCalculateMinStartTime();
    const local_start_date_time = dayjs(`${local_start_date} ${local_start_time}`, 'YYYY-MM-DD HH:mm');

    if (local_start_date_time.isBefore(minTimeAllowed)) {
      setSnackAlert({
        open: true,
        message: `Start time is earlier than allowed (max ${NO_EDIT_SESSION_HOURS_BEFORE} hours before the current time)`,
        severity: 'error',
      });
      setActiveStep(0);
      return;
    } else if (!isTouchedSchedule && !isTouchedScanning) {
      setSnackAlert({
        open: true,
        message: 'Please fill in the form',
        severity: 'error',
      });
      setActiveStep(0);
      return;
    }
    else if (!reason) {
      setSnackAlert({
        open: true,
        message: 'Please select a reason',
        severity: 'error',
      });
      setActiveStep(2);
      setReasonError(true);
      return;
    } else {
      const sessions = {
        sessions: [{
          coordinates,
          local_start_date,
          local_start_time,
          robot_uuid,
          multirobot_id,
          store,
          floor,
          zones,
          omitted_aisles,
          comments,
          week_days,
          max_minutes,
          overhead_mode,
          local_end_date,
          session_id,
          periodic_session_id,
          planner,
        }]
      }

      if (dialogType === DIALOG_TYPES.EDIT) {
        updateNewSessions(sessions);
      } else if (dialogType === DIALOG_TYPES.EDIT && confirmRemoveSession === 'CANCEL') {
        const cancelForm = {
          session_id: sessionSelected.session_id,
          local_start_date: local_end_date,
          comments,
        }
        cancelSessions({ sessions: cancelForm });
      } else if ([DIALOG_TYPES.ADD, DIALOG_TYPES.MULTIPLE_ADD].includes(dialogType)) {
        postNewSessions(sessions);
      }
    }
  }

  const handleIsCancelDetected = () => {
    return !scheduleForm.current.isTouched && !scanningForm.current.isTouched && scheduleForm.current.isEndDateBefore;
  }

  const handleCloseForm = ({ canCheckClose = true } = {}) => {
    const properlyClose = (canCheckClose && !isDisabled) ? window.confirm('Are you sure you want to close the form?') : true;
    if (properlyClose) {
      scanningForm.current = {};
      scheduleForm.current = {};
      reasonForm.current = {};
      setOpenModal(false)
    }
  }

  const handleMinStartTime = (store, robot) => {
    if (dateCellSelected.date && !isLoadingStoresUtcOffset && storesUtcOffset?.[store] === undefined) {
      const storeUtcOffset = storesUtcOffset?.[selectedStore];
      if (storeUtcOffset === undefined && robot?.coordinates) getStoresUtcOffset({ store: store, coordinates: robot.coordinates });
    }
  }

  const handleScanningMaxHeight = () => {
    const MAX_PAPER_HEIGHT = 515;
    const MAX_CONTENT_HEIGHT = 320;
    const paperHeight = paperRef.current?.clientHeight ?? MAX_CONTENT_HEIGHT;
    const newHeight = paperHeight * 0.35;
    return paperHeight >= MAX_PAPER_HEIGHT ? 'auto' : newHeight;
  }

  return (
    <>
      <Dialog open={openModal} maxWidth="lg" width='auto' PaperProps={{ ref: paperRef }}>
        <DialogTitle>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <Typography variant="subtitle1">
              {
                dialogType === DIALOG_TYPES.ADD ? t('overseer_app.scheduler.add_session', 'Add Session') :
                  dialogType === DIALOG_TYPES.EDIT ? t('overseer_app.scheduler.edit_session', 'Edit Session') :
                    dialogType === DIALOG_TYPES.MULTIPLE_ADD ? t('overseer_app.scheduler.add_multi_sessions', 'Add Multi Sessions') :
                      dialogType === DIALOG_TYPES.ONLY_VIEW ? t('overseer_app.scheduler.view_session', 'Only View') :
                        ''}
            </Typography>
            <Tooltip title="Close">
              <IconButton onClick={handleCloseForm} sx={{ p: 0 }}>
                <IconComponent iconName={'close-circle-outline'} />
              </IconButton>
            </Tooltip>
          </Box>
        </DialogTitle>
        <DialogContent sx={{ height: `min(100%, calc(${document.getElementById('layout-map-box')?.clientHeight ?? 400}px + 7em))`, overflowY: 'auto', maxHeight: '45em', overflow: 'hidden', pb: 0 }}>
          <Grid container>
            <Grid item xs={12}>
              <Grid container columnSpacing={1} sx={{ pt: 1 }}>
                <Grid item xs={3}>
                  {selectedChain?.length > 0 ?
                    <MultiSelector
                      options={selectedChain.map((chain) => ({ name: chain }))}
                      isDisabled={true}
                      inputSelected={selectedChain}
                      setInputSelectedOptions={() => { }}
                      inputLabel={'Chain'}
                      objectName={'name'}
                      objectId={'name'}
                    />
                    : <Box sx={{ height: '2.5em', border: '1px solid #777', borderRadius: '5px', p: 2 }}> <Typography variant="subtitle2">Chain</Typography></Box>
                  }
                </Grid>
                <Grid item xs={4}>
                  {selectedRobot?.length > 0 &&
                    <MultiSelector
                      options={selectedRobot}
                      isDisabled={true}
                      inputSelected={selectedRobot.map(robot => robot.robot_uuid)}
                      setInputSelectedOptions={() => { }}
                      inputLabel={'Robot'}
                      objectName={'robot_code'}
                      objectId={'robot_uuid'}
                    />
                  }
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ borderTop: 1, borderColor: 'divider', mt: 2, pt: 1 }}>
                <Stepper nonLinear activeStep={activeStep} sx={{ mb: 2 }}>
                  {sessionsSteps.map((label, index) => (
                    <Step key={label} >
                      <StepButton color="inherit" onClick={handleStep(index)}>
                        {label}
                      </StepButton>
                    </Step>
                  ))}
                </Stepper>
                <Box sx={{ mt: 2, mb: 1, py: 1 }}>
                  <Box sx={activeStep === 0 ? {} : { visibility: 'hidden', width: 0, height: 0, opacity: 0 }}>
                    <ScheduleForm
                      isDisabled={isDisabled}
                      minStartTime={minStartTime}
                      scheduleForm={scheduleForm}
                      inputStartDate={dateCellSelected.date}
                      inputEndDate={sessionSelected?.local_end_date}
                      inputStartTime={sessionSelected?.local_start_time ? dayjs(sessionSelected.local_start_time, "hh:mm A") : undefined}
                      inputEndTime={sessionSelected?.local_start_time && sessionSelected.max_minutes ? dayjs(sessionSelected.local_start_time, "hh:mm A").add(sessionSelected.max_minutes, 'minutes') : undefined}
                      weekDays={sessionSelected?.week_days}
                      sessionType={sessionSelected?.type}
                    />
                  </Box>
                  <Box sx={activeStep === 1 ? { my: 2, overflow: 'auto', height: handleScanningMaxHeight(), maxHeight: '29em' } : { visibility: 'hidden', width: 0, height: 0 }}>
                    <ScanningForm
                      isDisabled={isDisabled}
                      scanningForm={scanningForm}
                      store={selectedStore}
                      floor={selectedFloor}
                      isLoadingLayout={isLoadingLayout}
                      inputSelectedAisles={sessionSelected?.omitted_aisles}
                      inputSelectedZones={sessionSelected?.zones}
                      inputOH={sessionSelected?.overhead_mode}
                      inputPlanner={sessionSelected?.planner}
                    />
                  </Box>
                  <Box sx={activeStep === 2 ? { py: 2 } : { visibility: 'hidden', width: 0, height: 0 }}>
                    <ReasonForm
                      error={reasonError}
                      setError={setReasonError}
                      isDisabled={isDisabled}
                      modalForm={reasonForm}
                      actionReasons={actionReasons}
                      isLoadingActionReasons={isLoadingActionReasons}
                    />
                    {
                      // not isTouched
                      handleIsCancelDetected() &&
                      <Box sx={(theme) => { return { border: `1px solid ${theme.palette['warning']?.main}55`, borderRadius: '5px', padding: '1em', backgroundColor: `${theme.palette['warning']?.main}11` } }}>
                        <ConfirmCancelSessionForm
                          title={<Typography sx={{ textAlign: 'justify' }}>
                            It was detected that you only modified the end date field, you are about to cancel all the dates after <b>{scheduleForm.current.endDate}</b>, <b>This action can not be undone</b>, are you sure you want to continue?
                          </Typography>}
                          confirmRemoveSession={confirmRemoveSession}
                          setConfirmRemoveSession={setConfirmRemoveSession}
                          openWarning={openCancelWarning}
                          setOpenWarning={setOpenCancelWarning}
                        />
                      </Box>
                    }
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <Divider sx={{ with: '80%!important', mb: 1 }} />
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Button
                color="inherit"
                variant='outlined'
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                {activeStep === 0 ? 'Cancel' : 'Back'}
              </Button>
              <Box sx={{ flex: '1 1 auto' }} />
              <Button
                variant='contained'
                disabled={(activeStep === sessionsSteps.length - 1 && handleIsCancelDetected() && confirmRemoveSession !== 'CANCEL') || loadingNewSessions}
                color={handleIsCancelDetected() && activeStep === sessionsSteps.length - 1 ? 'secondary' : 'primary'}
                onClick={handleNext}>{loadingNewSessions ? <CircularProgress size={20} color='secondary' /> : activeStep === sessionsSteps.length - 1 ? isDisabled ? 'Close' : handleIsCancelDetected() ? 'Cancel' : 'Finish' : 'Next'}
              </Button>
            </Box>
          </Box>
        </DialogActions>
      </Dialog >
    </>

  );
}
