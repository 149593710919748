import { connect } from "react-redux";
import LayoutsView from "../../../components/layoutsView/LayoutsView";
import { getAvailableStores, getAvailableFloors, getGlobalLayers, getLayoutVersions, getStoreMapLayout, getStoreLayout, postStoreLayout } from "core/slices/layouts";
import { setSnackAlert } from "core/slices/snackAlert";
import {
    isLoadingAvailableStores,
    getLocalAvailableStores,
    getLocalAvailableFloors,
    isLoadingAvailableFloors,
    getLocalGlobalLayers,
    isLoadingGlobalLayers,
    isLoadingLayoutVersions,
    isLoadingStoreMapLayout,
    getLocalStoreMapLayout,
    getLocalStoreLayout,
    isLoadingStoreLayout,
    getLocalSendStoreLayout,
    isLoadingSendStoreLayout,
} from 'core/selectors';

export default connect((state) => ({
    availableStores: getLocalAvailableStores(state),
    isLoadingAvailableStores: isLoadingAvailableStores(state),
    availableFloors: getLocalAvailableFloors(state),
    isLoadingAvailableFloors: isLoadingAvailableFloors(state),
    globalLayers: getLocalGlobalLayers(state),
    isLoadingGlobalLayers: isLoadingGlobalLayers(state),
    isLoadingVersions: isLoadingLayoutVersions(state),
    isLoadingStoreMapLayout: isLoadingStoreMapLayout(state),
    storeMapLayout: getLocalStoreMapLayout(state),
    storeLayout: getLocalStoreLayout(state),
    isLoadingStoreLayout: isLoadingStoreLayout(state),
    sendLayoutStore: getLocalSendStoreLayout(state),
    isLoadingSendStoreLayout: isLoadingSendStoreLayout(state),
}), {
    getAvailableStores,
    getAvailableFloors,
    getGlobalLayers,
    getLayoutVersions,
    getStoreMapLayout,
    getStoreLayout,
    postStoreLayout,
    setSnackAlert
})(LayoutsView);
