import { Grid, Collapse, Alert, TextField, MenuItem } from "@mui/material";
import { useImperativeHandle, forwardRef, useCallback, useState, useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";

const CAPTURE_LAYER = 'capture';
const ORIENTATIONS = ['N', 'S', 'E', 'W', 'P', 'I'];
const DEFAULT_VALUES = {
    aisle: '',
    clientName: '',
    orientation: '',
    part: '',
    parameters: '',
}

export default forwardRef(function DialogCreationAisleSide({
    layers,
    colissionLayers,
    dialogData,
    isUniqueName,
    addNewLayer
}, ref) {
    const [formState, setFormState] = useState(DEFAULT_VALUES);
    const [errors, setErrors] = useState({});

    const { t } = useTranslation();
    const collisionZones = useMemo(() => {
        const { data: { start, end } } = dialogData;
        const startPoint = { x: start[0], y: start[1] };
        const endPoint = { x: end[0], y: end[1] };
        return colissionLayers(CAPTURE_LAYER, { start: startPoint, end: endPoint }, 'line', 'partial');
    }, [dialogData, colissionLayers]);

    useEffect(() => {
        if (collisionZones?.length === 1) {
            setFormState(prev => ({ ...prev, aisle: collisionZones[0].layer }));
        }
    }, [collisionZones]);

    const availableOrientations = useMemo(() => {
        const available = ORIENTATIONS.filter(orientation => {
            return !layers?.[CAPTURE_LAYER]?.layers?.[formState?.aisle]?.subLayers?.find(layer => layer === `${formState?.aisle}-${orientation}`);
        });
        return available;
    }, [layers, formState?.aisle]);

    const isNeccesaryPart = useMemo(() => {
        const subLayers = layers?.[CAPTURE_LAYER]?.layers?.[formState?.aisle]?.subLayers;
        const regex = new RegExp(`^${formState?.aisle}-${formState?.orientation}-\\d+?$`);
        return subLayers?.filter(layer => regex.test(layer))?.length >= 1
    }, [layers, formState]);

    const validateField = useCallback((field, value) => {
        const rules = {
            aisle: { required: true },
            orientation: { required: true },
            part: { required: isNeccesaryPart, mustBeNumber: true, minValue: 1, isUnique: true },
        };
        const rule = rules?.[field];
        if (!rule) return null;
        if (rule.required && (!value || value === ''))
            return 'This field is required';
        if (rule.mustBeNumber && (value && (!/^\d+$/.test(value) || value < rule.minValue)))
            return 'This field must be a number greater than 0';
        if (rule.isUnique && rule.required && !isUniqueName(`${formState?.aisle}-${formState?.orientation}-${value}`, CAPTURE_LAYER, formState?.aisle))
            return 'This combination of aisle and orientation already exists';
        return null;
    }, [isNeccesaryPart, isUniqueName, formState?.aisle, formState?.orientation]);

    const validateAllFields = useCallback(() => {
        const errors = {};
        Object.keys(formState).forEach(field => {
            const error = validateField(field, formState[field]);
            if (error) errors[field] = error;
        });
        setErrors(errors);
        if (Object.keys(errors).length > 0) return false;
        return true;
    }, [formState, validateField]);

    useEffect(() => {
        // Trigger concatenation of orientation and part to validate if it is unique or required
        if ((formState?.aisle && collisionZones?.length > 1) || formState?.part || formState?.orientation) {
            validateAllFields();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formState?.part, formState?.orientation]);

    const onSave = useCallback(() => {
        const isValid = validateAllFields();
        if (!isValid) return false;
        const { data: { start, end } } = dialogData;
        const { aisle } = formState;
        addNewLayer(CAPTURE_LAYER, {
            name: aisle,
            clientName: formState?.clientName,
            bbox: [{
                x: start[0],
                y: start[1]
            }, {
                x: end[0],
                y: end[1]
            }],
            type: 'line',
            orientation: formState?.orientation,
            aisle_part: formState?.part,
        });
        return true
    }, [validateAllFields, dialogData, formState, addNewLayer]);

    const handleFieldChange = useCallback((field, value) => {
        setErrors(prev => ({ ...prev, [field]: validateField(field, value) || undefined }));
        setFormState(prev => ({ ...prev, [field]: value }));
    }, [validateField]);


    const existsError = useMemo(() => Object.keys(errors).filter(key => !!errors[key]).length > 0, [errors]);

    useImperativeHandle(ref, () => ({
        callbackOnSave: onSave
    }), [onSave]);

    return <>
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Collapse in={existsError}>
                    <Alert severity="error">
                        {t('overseer_app.layouts.creation.default_error', 'Some fields are required or must be unique to create a new annotation in this Layer.')}
                        {errors?.part && ' ' + t('overseer_app.layouts.creation.combination_error', 'Combination of orientation and part already exists or the part is not valid')}
                    </Alert>
                </Collapse>
            </Grid>
            <Grid item xs={3}>
                <TextField label={t('overseer_app.layouts.aisle', 'Aisle')} fullWidth required select value={formState?.aisle} onChange={(e) => setFormState(prev => ({ ...prev, aisle: e.target.value }))} disabled={collisionZones?.length <= 1} error={!!errors.aisle}>
                    {collisionZones?.map(({ layer }) => (
                        <MenuItem value={layer} key={layer}>{layer}</MenuItem>
                    ))}
                </TextField>
            </Grid>
            <Grid item xs={4}>
                <TextField required label={t('overseer_app.layouts.aisle_orientation', 'Orientation')} fullWidth value={formState?.orientation} onChange={(e) => handleFieldChange('orientation', e.target.value)} error={!!errors.orientation} select disabled={!formState?.aisle}>
                    {ORIENTATIONS.map(orientation => (
                        <MenuItem value={orientation} key={orientation} disabled={!availableOrientations?.includes(orientation)}>{orientation}</MenuItem>
                    ))}
                </TextField>
            </Grid>
            <Grid item xs={5}>
                <TextField label={t('overseer_app.layouts.aisle_part', 'Aisle Part')} fullWidth value={formState?.part} onChange={(e) => handleFieldChange('part', e.target.value)} error={!!errors.part} required={isNeccesaryPart} disabled={!formState?.aisle || !formState?.orientation} />
            </Grid>
            <Grid item xs={12}>
                <TextField label={t('overseer_app.layouts.creation.linear_client_name', 'Client\'s linear name')} fullWidth />
            </Grid>
            <Grid item xs={12}>
                <TextField multiline rows={2} label={t('overseer_app.layouts.parameters', 'Parameters')} fullWidth value={formState?.parameters} onChange={(e) => handleFieldChange('parameters', e.target.value)} error={!!errors.parameters} />
            </Grid>
        </Grid>
    </>
})