import {
  handleAvailable,
  handleRequest,
  handleUnavailable,
} from '../reducerHandlers';
import {
  LAYOUTS_AVAILABLE,
  LAYOUTS_REQUEST,
  LAYOUTS_UNAVAILABLE,
  SNACK_SET,
  LAYOUTS_STORES_REQUEST,
  LAYOUTS_STORES_AVAILABLE,
  LAYOUTS_STORES_UNAVAILABLE,
  LAYOUTS_STORES_FLOORS_REQUEST,
  LAYOUTS_STORES_FLOORS_AVAILABLE,
  LAYOUTS_STORES_FLOORS_UNAVAILABLE,
  LAYOUTS_GLOBAL_LAYERS_REQUEST,
  LAYOUTS_GLOBAL_LAYERS_AVAILABLE,
  LAYOUTS_GLOBAL_LAYERS_UNAVAILABLE,
  LAYOUTS_VERSIONS_REQUEST,
  LAYOUTS_VERSIONS_AVAILABLE,
  LAYOUTS_VERSIONS_UNAVAILABLE,
  LAYOUTS_STORE_MAP_LAYOUT_REQUEST,
  LAYOUTS_STORE_MAP_LAYOUT_AVAILABLE,
  LAYOUTS_STORE_MAP_LAYOUT_UNAVAILABLE,
  LAYOUTS_STORE_LAYOUT_REQUEST,
  LAYOUTS_STORE_LAYOUT_AVAILABLE,
  LAYOUTS_STORE_LAYOUT_UNAVAILABLE,
  LAYOUTS_SEND_STORE_LAYOUT_REQUEST,
  LAYOUTS_SEND_STORE_LAYOUT_AVAILABLE,
  LAYOUTS_SEND_STORE_LAYOUT_UNAVAILABLE,
} from './constants';
import { createReducer } from './utils';

const defaultState = {
  robotLayout: {},
  isLoadingRobotLayout: false,
  bucketMapLayout: {},
  isLoadingBucketMapLayout: false,
  availableStores: [],
  isLoadingAvailableStores: false,
  availableStoreFloors: {},
  isLoadingAvailableStoreFloors: false,
  globalLayers: {},
  isLoadingGlobalLayers: false,
  versions: {},
  isLoadingVersions: false,
  storeMapLayout: {},
  isLoadingStoreMapLayout: false,
  storeLayout: {},
  isLoadingStoreLayout: false,
  isSendingStoreLayout: false,
  sendLayoutStore: {},
};

export const reducer = createReducer(defaultState, {
  [LAYOUTS_REQUEST]: handleRequest,
  [LAYOUTS_AVAILABLE]: handleAvailable,
  [LAYOUTS_UNAVAILABLE]: handleUnavailable,
  [LAYOUTS_STORES_REQUEST]: handleRequest,
  [LAYOUTS_STORES_AVAILABLE]: handleAvailable,
  [LAYOUTS_STORES_UNAVAILABLE]: handleUnavailable,
  [LAYOUTS_STORES_FLOORS_REQUEST]: handleRequest,
  [LAYOUTS_STORES_FLOORS_AVAILABLE]: handleAvailable,
  [LAYOUTS_STORES_FLOORS_UNAVAILABLE]: handleUnavailable,
  [LAYOUTS_GLOBAL_LAYERS_REQUEST]: handleRequest,
  [LAYOUTS_GLOBAL_LAYERS_AVAILABLE]: handleAvailable,
  [LAYOUTS_GLOBAL_LAYERS_UNAVAILABLE]: handleUnavailable,
  [LAYOUTS_VERSIONS_REQUEST]: handleRequest,
  [LAYOUTS_VERSIONS_AVAILABLE]: handleAvailable,
  [LAYOUTS_VERSIONS_UNAVAILABLE]: handleUnavailable,
  [LAYOUTS_STORE_MAP_LAYOUT_REQUEST]: handleRequest,
  [LAYOUTS_STORE_MAP_LAYOUT_AVAILABLE]: handleAvailable,
  [LAYOUTS_STORE_MAP_LAYOUT_UNAVAILABLE]: handleUnavailable,
  [LAYOUTS_STORE_LAYOUT_REQUEST]: handleRequest,
  [LAYOUTS_STORE_LAYOUT_AVAILABLE]: handleAvailable,
  [LAYOUTS_STORE_LAYOUT_UNAVAILABLE]: handleUnavailable,
  [LAYOUTS_SEND_STORE_LAYOUT_REQUEST]: handleRequest,
  [LAYOUTS_SEND_STORE_LAYOUT_AVAILABLE]: handleAvailable,
  [LAYOUTS_SEND_STORE_LAYOUT_UNAVAILABLE]: handleUnavailable,
});


export function getRobotLayout(payload) {
  return async (dispatch, _, { services: { dataSource } }) => {
    const keyState = 'robotLayout';
    const loadingName = 'isLoadingRobotLayout';
    dispatch({
      type: LAYOUTS_REQUEST,
      payload: { loadingName: loadingName },
    });
    try {
      const response = await dataSource.getRobotLayout(payload);
      dispatch({
        type: LAYOUTS_AVAILABLE,
        payload: {
          keyState: keyState,
          data: response?.data,
          loadingName: loadingName,
        },
      });
    } catch (error) {
      console.error('error: ', error);
      dispatch({
        type: LAYOUTS_UNAVAILABLE,
        payload: {
          keyState: keyState,
          loadingName: loadingName,
          defaultState: [],
        },
      });
      const snack = {
        open: true,
        message: 'There was an error getting the robot layout. Please try again.',
        severity: 'error',
      };
      dispatch({ type: SNACK_SET, payload: { snack } });
    }
  };
}

export function getBucketMapLayout(payload) {
  return async (dispatch, _, { services: { dataSource } }) => {
    const keyState = 'bucketMapLayout';
    const loadingName = 'isLoadingBucketMapLayout';
    dispatch({
      type: LAYOUTS_REQUEST,
      payload: { loadingName: loadingName },
    });
    try {
      const response = await dataSource.getBucketMapLayout(payload);
      dispatch({
        type: LAYOUTS_AVAILABLE,
        payload: {
          keyState: keyState,
          data: response,
          loadingName: loadingName,
        },
      });
    } catch (error) {
      console.error('error: ', error);
      dispatch({
        type: LAYOUTS_UNAVAILABLE,
        payload: {
          keyState: keyState,
          loadingName: loadingName,
          defaultState: [],
        },
      });
      const snack = {
        open: true,
        message: 'There was an error getting the store map. Please try again.',
        severity: 'error',
      };
      dispatch({ type: SNACK_SET, payload: { snack } });
    }
  };
}

export function getAvailableStores() {
  return async (dispatch, _, { services: { dataSource } }) => {
    const keyState = 'availableStores';
    const loadingName = 'isLoadingAvailableStores';
    dispatch({
      type: LAYOUTS_STORES_REQUEST,
      payload: { loadingName: loadingName },
    });
    try {
      const response = await dataSource.getAvailableStores();
      dispatch({
        type: LAYOUTS_STORES_AVAILABLE,
        payload: {
          keyState: keyState,
          data: response,
          loadingName: loadingName,
        },
      });
    } catch (error) {
      console.error('error: ', error);
      dispatch({
        type: LAYOUTS_STORES_UNAVAILABLE,
        payload: {
          keyState: keyState,
          loadingName: loadingName,
          defaultState: [],
        },
      });
    }
  };
}

export function getAvailableFloors(payload) {
  return async (dispatch, _, { services: { dataSource } }) => {
    const keyState = 'availableStoreFloors';
    const loadingName = 'isLoadingAvailableStoreFloors';
    dispatch({
      type: LAYOUTS_STORES_FLOORS_REQUEST,
      payload: { loadingName: loadingName },
    });

    try {
      const response = await dataSource.getAvailableFloors(payload);
      dispatch({
        type: LAYOUTS_STORES_FLOORS_AVAILABLE,
        payload: {
          keyState: keyState,
          data: response?.[0]?.floors,
          loadingName: loadingName,
        },
      });
    } catch (error) {
      console.error('error: ', error);
      dispatch({
        type: LAYOUTS_STORES_FLOORS_UNAVAILABLE,
        payload: {
          keyState: keyState,
          loadingName: loadingName,
          defaultState: [],
        },
      });
    }
  };
}

export function getGlobalLayers() {
  return async (dispatch, _, { services: { dataSource } }) => {
    const keyState = 'globalLayers';
    const loadingName = 'isLoadingGlobalLayers';
    dispatch({
      type: LAYOUTS_REQUEST,
      payload: { loadingName: loadingName },
    });
    try {
      const response = await dataSource.getGlobalLayers();
      dispatch({
        type: LAYOUTS_AVAILABLE,
        payload: {
          keyState: keyState,
          data: response,
          loadingName: loadingName,
        },
      });
    } catch (error) {
      console.error('error: ', error);
      dispatch({
        type: LAYOUTS_UNAVAILABLE,
        payload: {
          keyState: keyState,
          loadingName: loadingName,
          defaultState: [],
        },
      });
    }
  };
}

export function getLayoutVersions(payload) {
  return async (dispatch, _, { services: { dataSource } }) => {
    const keyState = 'versions';
    const loadingName = 'isLoadingVersions';
    dispatch({
      type: LAYOUTS_VERSIONS_REQUEST,
      payload: { loadingName: loadingName },
    });
    try {
      const response = await dataSource.getLayoutVersions(payload);
      dispatch({
        type: LAYOUTS_VERSIONS_AVAILABLE,
        payload: {
          keyState: keyState,
          data: response,
          loadingName: loadingName,
        },
      });
    } catch (error) {
      console.error('error: ', error);
      dispatch({
        type: LAYOUTS_VERSIONS_UNAVAILABLE,
        payload: {
          keyState: keyState,
          loadingName: loadingName,
          defaultState: [],
        },
      });
    }
  }
}

export function getStoreMapLayout(payload) {
  return async (dispatch, _, { services: { dataSource } }) => {
    const keyState = 'storeMapLayout';
    const loadingName = 'isLoadingStoreMapLayout';
    dispatch({
      type: LAYOUTS_REQUEST,
      payload: { loadingName: loadingName },
    });
    try {
      const response = await dataSource.getStoreMapLayout(payload);
      dispatch({
        type: LAYOUTS_AVAILABLE,
        payload: {
          keyState: keyState,
          data: response,
          loadingName: loadingName,
        },
      });
    } catch (error) {
      console.error('error: ', error);
      dispatch({
        type: LAYOUTS_UNAVAILABLE,
        payload: {
          keyState: keyState,
          loadingName: loadingName,
          defaultState: {},
        },
      });
      const snack = {
        open: true,
        message: 'There was an error getting the store map. Please try again.',
        severity: 'error',
      };
      dispatch({ type: SNACK_SET, payload: { snack } });
    }
  }
}

export function getStoreLayout(payload) {
  return async (dispatch, _, { services: { dataSource } }) => {
    const keyState = 'storeLayout';
    const loadingName = 'isLoadingStoreLayout';
    dispatch({
      type: LAYOUTS_REQUEST,
      payload: { loadingName: loadingName },
    });
    try {
      const response = await dataSource.getStoreLayout(payload);
      dispatch({
        type: LAYOUTS_AVAILABLE,
        payload: {
          keyState: keyState,
          data: response,
          loadingName: loadingName,
        },
      });
    } catch (error) {
      console.error('error: ', error);
      dispatch({
        type: LAYOUTS_UNAVAILABLE,
        payload: {
          keyState: keyState,
          loadingName: loadingName,
          defaultState: {
            layouts: {}
          },
        },
      });
    }
  }
}

export function postStoreLayout(payload) {
  return async (dispatch, _, { services: { dataSource } }) => {
    const keyState = 'sendLayoutStore';
    const loadingName = 'isSendingStoreLayout';
    dispatch({
      type: LAYOUTS_SEND_STORE_LAYOUT_REQUEST,
      payload: { loadingName: loadingName },
    });
    try {
      const response = await dataSource.postStoreLayout(payload);
      dispatch({
        type: LAYOUTS_SEND_STORE_LAYOUT_AVAILABLE,
        payload: {
          keyState: keyState,
          data: response,
          loadingName: loadingName,
        },
      });
    } catch (error) {
      console.error('error: ', error);
      dispatch({
        type: LAYOUTS_SEND_STORE_LAYOUT_UNAVAILABLE,
        payload: {
          keyState: keyState,
          loadingName: loadingName,
          defaultState: {},
        },
      });
    }
  }
}
