import { useRef, useState, useEffect } from 'react';
import { Box, Tooltip, ToggleButton } from '@mui/material';

export default function HotKeyToggleButton({ value, ariaLabel, hotKey, label, children, disabled = false }) {
    const toggleButtonRef = useRef(null);
    const [isPressed, setIsPressed] = useState(false);
    useEffect(() => {
        if (!toggleButtonRef.current) return;
        const observer = new MutationObserver(() => {
            setIsPressed(toggleButtonRef.current.ariaPressed === 'true');
        });
        const onKeyDown = (event) => {
            if (event.key === hotKey) {
                const activeElement = document.activeElement;
                if (['INPUT', 'TEXTAREA', 'SELECT', 'LI', 'BUTTON'].includes(activeElement?.tagName) || activeElement?.isContentEditable || ['dialog', 'presentation', 'combobox'].includes(activeElement?.role)) {
                    return;
                }
                toggleButtonRef.current.click();
            }
        };
        window.document.addEventListener('keydown', onKeyDown);
        observer.observe(toggleButtonRef.current, { attributes: true, attributeFilter: ['aria-pressed'] });
        setIsPressed(toggleButtonRef.current.ariaPressed === 'true');
        return () => {
            observer.disconnect();
            window.document.removeEventListener('keydown', onKeyDown);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <Box sx={{ position: 'relative' }}>
            <Tooltip title={label} arrow disableInteractive>
                <span>
                    <ToggleButton value={value} aria-label={ariaLabel} ref={toggleButtonRef} disabled={disabled} sx={{
                        '&:disabled': {
                            border: 'none',
                            opacity: 0.5,
                        },
                    }}>
                        {children}
                    </ToggleButton>
                </span>
            </Tooltip>
            <span style={{ position: 'absolute', right: 0, fontSize: '10px', color: isPressed ? '#1E1D1E' : '#717173', transform: 'translate(-150%, -50%)', bottom: 0, opacity: disabled ? 0.5 : 1, userSelect: 'none', pointerEvents: 'none' }}>{hotKey}</span>
        </Box>
    );
}