import { setSnackAlert } from 'core/slices/snackAlert';
import React, { useEffect, useState } from 'react'

import { IconButton, Stack, Tooltip, Typography } from '@mui/material'
import { DataGrid } from '@zippeditoolsjs/table';
import { IconComponent } from '@zippeditoolsjs/zippedi-icons';

import AddEditDialog from './AddEditDialog';
import DeleteDialog from './DeleteDialog';

const columns = (props) => {
  const {
    t,
    canUpdate,
    setOpenAddEditDialog,
    setOpenDeleteDialog,
    setChangeType,
    setSelectedParameter,
  } = props;

  return ([
    { field: 'code', headerName: t('overseer_app.parameters.Code', 'Code'), flex: 2 },
    { field: 'category', headerName: t('overseer_app.parameters.Category', 'Category'), flex: 1 },
    { field: 'required', headerName: t('overseer_app.parameters.Required', 'Required'), flex: 1 },
    { field: 'editable', headerName: t('overseer_app.parameters.Editable', 'Editable'), flex: 1 },
    { field: 'data_type', headerName: t('overseer_app.parameters.Data_type', 'Data type'), flex: 1 },
    {
      field: 'default_value',
      headerName: t('overseer_app.parameters.Default_value', 'Default value'),
      flex: 2,
      valueGetter: (params) => {
        // Check if the value is null or undefined
        if (params.value === null || params.value === undefined) {
          return '-'
        } else {
          if (params.row.data_type === 'list' && params.value && params.value.length !== 0) {
            let currentValue = params.value
            // Transform string to list
            if (typeof params.value === 'string') {
              currentValue = currentValue.replace(/'/g, '"');
              currentValue = JSON.parse(currentValue);
            }
            return currentValue.join(', ')
          } else {
            return params.value
          }
        }
      }
    },
    {
      field: 'possible_values',
      headerName: t('overseer_app.parameters.Possible_values', 'Possible values'),
      flex: 2,
      valueGetter: (params) => {
        if (params.value === null || params.value === undefined) {
          return '-'
        } else {
          return params.value?.values
        }
      }
    },
    {
      field: 'actions',
      headerName: t('overseer_app.parameters.Actions', 'Actions'),
      flex: 1,
      renderCell: (params) => (
        <>
          <Tooltip title={t('overseer_app.parameters.Edit', 'Edit')}>
            <span>
              <IconButton disabled={!canUpdate} onClick={() => { setOpenAddEditDialog(true); setChangeType('edit'); setSelectedParameter(params.row); }}>
                <IconComponent
                  iconName={'pencil'}
                  style={{ fontSize: "20px" }}
                />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title={t('overseer_app.parameters.Delete', 'Delete')}>
            <span>
              <IconButton disabled={!canUpdate} onClick={() => { setOpenDeleteDialog(true); setSelectedParameter(params.row); }}>
                <IconComponent
                  iconName={'trash'}
                  style={{ fontSize: "20px", color: 'red' }}
                />
              </IconButton>
            </span>
          </Tooltip>
        </>
      )
    }
  ])
}

export default function Parameters(props) {
  const {
    t,
    canUpdate,
    getParameters,
    parameters,
    isLoadingParameters,
    parameterDataTypes,
    isLoadingParameterDataTypes,
    parameterCategories,
    isLoadingParameterCategories,
    getDataType,
    setDataType,
    postParameters,
    parametersPostResponse,
    isLoadingParametersPostResponse,
    patchParameters,
    parametersPatchResponse,
    isLoadingParametersPatchResponse,
    deleteParameters,
    parametersDeleteResponse,
    isLoadingParametersDeleteResponse,
  } = props;
  // Dialog states
  const [openAddEditDialog, setOpenAddEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  // States
  const [changeType, setChangeType] = useState(null);
  const [selectedParameter, setSelectedParameter] = useState({}); // Selected parameter (row of the DataGrid)

  // Lifecycle methods

  // POST response
  useEffect(() => {
    if (parametersPostResponse?.message === 'Success') {
      getParameters();
      setOpenAddEditDialog(false);
      setSnackAlert({
        open: true,
        message: t('overseer_app.parameters.save_successful', 'Parameters saved successfully'),
        severity: 'success',
      });
    }
  }, [t, parametersPostResponse, getParameters])

  // PATCH response
  useEffect(() => {
    if (parametersPatchResponse?.message === 'Success') {
      getParameters();
      setOpenAddEditDialog(false);
      setSnackAlert({
        open: true,
        message: t('overseer_app.parameters.save_successful', 'Parameters saved successfully'),
        severity: 'success',
      });
    }
  }, [t, parametersPatchResponse, getParameters])

  // DELETE response
  useEffect(() => {
    if (parametersDeleteResponse?.message === 'Success') {
      getParameters();
      setOpenDeleteDialog(false);
      setSnackAlert({
        open: true,
        message: t('overseer_app.parameters.delete_successful', 'Parameters deleted successfully'),
        severity: 'success',
      });
    }
  }, [t, parametersDeleteResponse, getParameters])

  return (
    <Stack sx={{ p: 2 }}>
      <Stack direction='row' justifyContent='space-between' alignItems='center'>
        {/* Title */}
        <Typography variant='h5'>{t('overseer_app.parameters.Parameters', 'Parameters')}</Typography>
        {/* Add button */}
        <IconButton disabled={!canUpdate} onClick={() => { setOpenAddEditDialog(true); setChangeType('add') }}>
          <IconComponent
            iconName={'add'}
            style={{ fontSize: "20px" }}
          />
        </IconButton>
      </Stack>
      {/* Table */}
      <DataGrid
        sx={{ border: 'none' }}
        rows={parameters}
        columns={columns({ t, canUpdate, setOpenAddEditDialog, setOpenDeleteDialog, setChangeType, setSelectedParameter })}
        loading={isLoadingParameters}
        initialState={{
          pagination: {
            paginationModel: { pageSize: 5, page: 0 },
          },
        }}
        pageSizeOptions={[5, 10, 25]}
        getRowId={(row) => row.code}
      />
      <AddEditDialog
        t={t}
        openAddEditDialog={openAddEditDialog}
        setOpenAddEditDialog={setOpenAddEditDialog}
        changeType={changeType}
        parameterDataTypes={parameterDataTypes}
        isLoadingParameterDataTypes={isLoadingParameterDataTypes}
        parameterCategories={parameterCategories}
        isLoadingParameterCategories={isLoadingParameterCategories}
        getDataType={getDataType}
        setDataType={setDataType}
        selectedParameter={selectedParameter}
        postParameters={postParameters}
        isLoadingParametersPostResponse={isLoadingParametersPostResponse}
        patchParameters={patchParameters}
        isLoadingParametersPatchResponse={isLoadingParametersPatchResponse}
      />
      <DeleteDialog
        t={t}
        openDeleteDialog={openDeleteDialog}
        setOpenDeleteDialog={setOpenDeleteDialog}
        selectedParameter={selectedParameter}
        deleteParameters={deleteParameters}
        isLoadingParametersDeleteResponse={isLoadingParametersDeleteResponse}
      />
    </Stack>
  )
}
