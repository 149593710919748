import React, { useEffect, useState } from 'react'

import LoadingButton from '@mui/lab/LoadingButton';
import { Autocomplete, Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControlLabel, FormGroup, Stack, Switch, TextField, Tooltip, Typography, useTheme } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2';
import { isEmptyOrUndefined } from '@zippeditoolsjs/blocks';
import { IconComponent } from '@zippeditoolsjs/zippedi-icons';

export default function AddEditDialog(props) {
  const {
    t,
    openAddEditDialog,
    setOpenAddEditDialog,
    changeType,
    parameterDataTypes,
    isLoadingParameterDataTypes,
    parameterCategories,
    isLoadingParameterCategories,
    getDataType,
    setDataType,
    selectedParameter,
    postParameters,
    isLoadingParametersPostResponse,
    patchParameters,
    isLoadingParametersPatchResponse,
  } = props;
  const theme = useTheme();
  const [code, setCode] = useState('');
  const [description, setDescription] = useState('');
  const [category, setCategory] = useState(null);
  const [required, setRequired] = useState(false);
  const [editable, setEditable] = useState(false);
  const [dataType, setDataTypeSelect] = useState(null);
  const [defaultValue, setDefaultValue] = useState('');
  const [possibleValues, setPossibleValues] = useState([]);

  // Lifecycle methods

  // Set the values of the existing parameter when editing
  useEffect(() => {
    if (changeType === 'edit' && openAddEditDialog) {
      setCode(selectedParameter.code);
      setDescription(selectedParameter.description);
      setCategory(parameterCategories.filter(category => category.category_id === selectedParameter.category_id)[0]);
      setRequired(selectedParameter.required);
      setEditable(selectedParameter.editable);
      setDataTypeSelect(parameterDataTypes.filter(type => type.data_type === selectedParameter.data_type)[0]);

      // Format possibleValues
      if (!isEmptyOrUndefined(selectedParameter.possible_values, 'object')) {
        setPossibleValues(selectedParameter.possible_values.values);
      }

      if (!isEmptyOrUndefined(selectedParameter.default_value, 'array')) {
        if (selectedParameter.data_type === 'list') {
          // Format defaultValue
          let formattedDefaultValue = selectedParameter.default_value;
          if (typeof selectedParameter.default_value === 'string') {
            formattedDefaultValue = formattedDefaultValue.replace(/'/g, '"');
            formattedDefaultValue = JSON.parse(formattedDefaultValue);
          }
          setDefaultValue(formattedDefaultValue);
        } else if (selectedParameter.data_type === 'bool') {
          const newDefault = selectedParameter.default_value === 'true' ? true : false;
          setDefaultValue(newDefault);
        } else if (selectedParameter.data_type === 'secret') {
          setDefaultValue(JSON.parse(selectedParameter.default_value));
        } else {
          setDefaultValue(setDataType(selectedParameter.default_value, selectedParameter.data_type));
        }
      }
    }
  }, [openAddEditDialog])

  // Reset states when the dialog closes
  useEffect(() => {
    if (!openAddEditDialog) {
      resetStates();
    }
  }, [openAddEditDialog])

  // Methods

  // Reset all states
  const resetStates = () => {
    setCode('');
    setDescription('');
    setCategory(null);
    setRequired(false);
    setEditable(false);
    setDataTypeSelect(null);
    setDefaultValue('')
    setPossibleValues([]);
  }

  // Reset defaultValue and possbleValues
  const resetDefaultAndPossibleValues = (newDataType) => {
    if (!isEmptyOrUndefined(newDataType, 'id')) {
      if (newDataType.data_type === 'bool') setDefaultValue(false);
      else if (newDataType.data_type === 'secret') setDefaultValue({});
      else if (newDataType.data_type === 'list') setDefaultValue([]);
      else setDefaultValue('');
      setPossibleValues([]);
    }
  }

  const handleClose = () => {
    resetStates();
    setOpenAddEditDialog(false);
  };

  // Check if defaultValue exists in possibleValues if both are not null
  const isPossibleValuesValid = () => {
    let isValid = true;

    if (dataType?.data_type === 'list') {
      if (!isEmptyOrUndefined(defaultValue, 'array') && !isEmptyOrUndefined(possibleValues, 'array')) {
        isValid = defaultValue.every(value => possibleValues.includes(value));
      }
    } else {
      if (!isEmptyOrUndefined(defaultValue, 'array') && !isEmptyOrUndefined(possibleValues, 'array')) {
        isValid = possibleValues.includes(defaultValue);
      }
    }

    return isValid
  }

  const handleSaveChanges = () => {
    let data = {
      code: code,
      category_id: category.category_id,
      required: required,
      editable: editable,
      data_type: dataType.data_type,
      possible_values: { values: possibleValues }
    }

    // Add description
    if (isEmptyOrUndefined(description, 'id')) {
      data["description"] = null
    } else {
      data["description"] = description
    }

    // Add possible_values
    if (isEmptyOrUndefined(possibleValues, 'array')) {
      data["possible_values"] = null
    } else {
      data["possible_values"] = { values: possibleValues }
    }

    // Add default_value and cast depending on the data type
    // TODO: remove the list validation when data_type = list doesn't exist anymore
    if (isEmptyOrUndefined(defaultValue, 'array')) {
      data["default_value"] = null
    } else {
      if (dataType.data_type === 'list' || dataType.data_type === 'secret') {
        data['default_value'] = JSON.stringify(defaultValue);
      } else {
        data['default_value'] = String(defaultValue);
      }
    }

    // PATCH function for editing an existing parameter
    if (changeType === 'edit') {
      data["parameter_id"] = selectedParameter.parameter_id;
      patchParameters({
        parameters: [data]
      })
    } else {
      // POST function for adding a new parameter
      postParameters({
        parameters: [data]
      })
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth='md'
      open={openAddEditDialog}
      onClose={handleClose}
    >
      {/* Dialog title */}
      {changeType === 'edit' ?
        <DialogTitle>{t('overseer_app.parameters.Edit_parameter', 'Edit Parameter')}</DialogTitle>
        :
        <DialogTitle>{t('overseer_app.parameters.New_parameter', 'New Parameter')}</DialogTitle>
      }
      {/* Dialog content */}
      <DialogContent dividers>
        <Stack
          component={Grid}
          container
          direction={{ xs: 'column', md: 'row' }}
          spacing={{ xs: 2, md: 3 }}
          justifyContent='space-between'
          divider={<Divider flexItem orientation={'vertical'} />}
        >
          <Stack component={Grid} spacing={2} xs={12} md={5.5} size={{ xs: 12, md: 5.5 }}>
            <Typography variant='h6'>{t('overseer_app.general.Details', 'Details')}</Typography>
            {/* Code */}
            <TextField
              value={code}
              label={t('overseer_app.parameters.Code', 'Code')}
              onChange={(event) => setCode(event.target.value)}
            />
            {/* Description */}
            <TextField
              label={`${t('overseer_app.general.Description', 'Description')} (${t('overseer_app.general.optional', 'optional')})`}
              value={description || ''}
              onChange={(event) => setDescription(event.target.value)}
            />
            {/* Category */}
            <Autocomplete
              variant='outlined'
              options={parameterCategories}
              value={category}
              onChange={(event, newValue) => {
                setCategory(newValue);
              }}
              loading={isLoadingParameterCategories}
              loadingText={t('overseer_app.general.Loading', 'Loading...')}
              getOptionLabel={option => option.name}
              renderInput={(params) => <TextField {...params} label={t('overseer_app.parameters.Category', 'Category')} />}
            />
          </Stack>
          <Stack component={Grid} spacing={2} xs={12} md={5.5} size={{ xs: 12, md: 5.5 }}>
            <Typography variant='h6'>{t('overseer_app.parameters.Value_Handling', 'Value Handling')}</Typography>
            {/* Switches */}
            <FormGroup>
              <FormControlLabel control={<Switch checked={required} onChange={event => setRequired(event.target.checked)} />} label={t('overseer_app.parameters.Required', 'Required')} />
              <FormControlLabel control={<Switch checked={editable} onChange={event => setEditable(event.target.checked)} />} label={t('overseer_app.parameters.Editable', 'Editable')} />
            </FormGroup>
            {/* Data type */}
            <Autocomplete
              variant='outlined'
              options={parameterDataTypes}
              value={dataType}
              onChange={(event, newValue) => {
                setDataTypeSelect(newValue);
                resetDefaultAndPossibleValues(newValue);
              }}
              loading={isLoadingParameterDataTypes}
              loadingText={t('overseer_app.general.Loading', 'Loading...')}
              getOptionLabel={option => option.data_type}
              renderInput={(params) => <TextField {...params} label={t('overseer_app.parameters.Data_type', 'Data type')} />}
            />
            {/* Default value */}
            {/* Default value: bool */}
            {dataType?.data_type === 'bool' ?
              <FormGroup>
                <FormControlLabel control={<Switch checked={[true, false].includes(defaultValue) ? defaultValue : false} onChange={event => setDefaultValue(event.target.checked)} />} label={t('overseer_app.parameters.Default_value', 'Default value')} />
              </FormGroup>
              : dataType?.data_type === 'secret' ?
                <>
                  {/* Default value: secret */}
                  <Typography>{t('overseer_app.parameters.Default_value', 'Default value')}</Typography>
                  <TextField
                    label='project_id'
                    value={defaultValue['project_id']}
                    onChange={(event) => setDefaultValue({ ...defaultValue, 'project_id': event.target.value })}
                  />
                  <TextField
                    label='secret_id'
                    value={defaultValue['secret_id']}
                    onChange={(event) => setDefaultValue({ ...defaultValue, 'secret_id': event.target.value })}
                  />
                  <TextField
                    label='version_id'
                    value={defaultValue['version_id']}
                    onChange={(event) => setDefaultValue({ ...defaultValue, 'version_id': event.target.value })}
                  />
                </>
                : dataType?.data_type === 'list' ?
                  <>
                    {/* Default value: list */}
                    <Autocomplete
                      freeSolo
                      multiple
                      clearIcon={false}
                      options={[]}
                      value={defaultValue || []}
                      onChange={(e, value) => setDefaultValue(value)}
                      renderTags={(value, props) =>
                        value.map((option, index) => (
                          <Chip label={option} {...props({ index })} />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          label={`${t('overseer_app.parameters.Default_value', 'Default value')} (${t('overseer_app.general.optional', 'optional')})`}
                          helperText={t('overseer_app.parameters.possible_values_press_enter', 'Press enter after every value.')}
                          {...params}
                        />
                      )}
                    />
                    {/* Possible values */}
                    <Autocomplete
                      freeSolo
                      multiple
                      clearIcon={false}
                      options={[]}
                      value={possibleValues}
                      onChange={(e, value) => setPossibleValues(value)}
                      renderTags={(value, props) =>
                        value.map((option, index) => (
                          <Chip label={option} {...props({ index })} />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          type={getDataType(dataType?.data_type)}
                          label={`${t('overseer_app.parameters.Possible_values', 'Possible values')} (${t('overseer_app.general.optional', 'optional')})`}
                          helperText={
                            isPossibleValuesValid() ?
                              t('overseer_app.parameters.possible_values_press_enter', 'Press enter after every value.')
                              :
                              <>
                                {t('overseer_app.parameters.possible_values_press_enter', 'Press enter after every value.')}
                                <br />
                                <Typography variant='caption' color={theme.palette.error.main}>{t('overseer_app.parameters.Missing_default_value', 'Missing default value.')}</Typography>
                              </>


                          }
                          {...params}
                        />
                      )}
                    />
                  </>
                  : !isEmptyOrUndefined(dataType, 'object') ?
                    <>
                      {/* Default value: rest */}
                      <TextField
                        label={`${t('overseer_app.parameters.Default_value', 'Default value')} (${t('overseer_app.general.optional', 'optional')})`}
                        value={defaultValue}
                        onChange={(event) => setDefaultValue(setDataType(event.target.value, dataType.data_type))}
                        type={getDataType(dataType?.data_type)}
                      />
                      {/* Possible values */}
                      <Autocomplete
                        freeSolo
                        multiple
                        clearIcon={false}
                        options={[]}
                        value={possibleValues}
                        onChange={(e, value) => setPossibleValues(value.map(v => setDataType(v, dataType.data_type)))}
                        renderTags={(value, props) =>
                          value.map((option, index) => (
                            <Chip label={setDataType(option, dataType.data_type)} {...props({ index })} />
                          ))
                        }
                        renderInput={(params) => (
                          <TextField
                            type={getDataType(dataType?.data_type)}
                            label={`${t('overseer_app.parameters.Possible_values', 'Possible values')} (${t('overseer_app.general.optional', 'optional')})`}
                            helperText={
                              isPossibleValuesValid() ?
                                t('overseer_app.parameters.possible_values_press_enter', 'Press enter after every value.')
                                :
                                <>
                                  {t('overseer_app.parameters.possible_values_press_enter', 'Press enter after every value.')}
                                  <br />
                                  <Typography variant='caption' color={theme.palette.error.main}>{t('overseer_app.parameters.Missing_default_value', 'Missing default value.')}</Typography>
                                </>


                            }
                            {...params}
                          />
                        )}
                      />
                    </>
                    :
                    null
            }
          </Stack>
        </Stack>
      </DialogContent>
      {/* Dialog actions */}
      <DialogActions>
        <Button autoFocus onClick={handleClose}>
          {t('overseer_app.general.Cancel', 'Cancel')}
        </Button>
        <Tooltip
          title={
            isEmptyOrUndefined(code, 'id')
              || isEmptyOrUndefined(category, 'object')
              || isEmptyOrUndefined(dataType, 'object')
              || !isPossibleValuesValid()
              ? t('overseer_app.parameters.Missing_required_values', 'Missing required values')
              : ''
          }
        >
          <span>
            <LoadingButton
              loading={isLoadingParametersPostResponse || isLoadingParametersPatchResponse}
              onClick={handleSaveChanges}
              loadingPosition="start"
              startIcon={<IconComponent
                iconName={'save'}
                style={{ fontSize: "20px" }}
              />}
              disabled={
                isEmptyOrUndefined(code, 'id')
                || isEmptyOrUndefined(category, 'object')
                || isEmptyOrUndefined(dataType, 'object')
                || !isPossibleValuesValid()
              }
            >
              {t('overseer_app.general.Save', 'Save')}
            </LoadingButton>
          </span>
        </Tooltip>
      </DialogActions>
    </Dialog>
  )
}
