import { IconButton, Popover, Box, FormControlLabel, Switch } from '@mui/material';
import { useState, useCallback } from 'react'
import { IconComponent } from '@zippeditoolsjs/zippedi-icons';

export default function SettingsButton({ useClientAisles, setUseClientAisles }) {
    const [openSettings, setOpenSettings] = useState(null);

    const toggleUseClientAisles = useCallback(() => {
        setUseClientAisles(prev => !prev);
    }, [setUseClientAisles]);

    return (
        <>
            <IconButton sx={{ position: 'absolute', bottom: '10px', left: '10px', zIndex: 1000 }} onClick={(e) => setOpenSettings(e.currentTarget)} id="map-settings-button">
                <IconComponent iconName="settings" />
            </IconButton>
            <Popover
                open={!!openSettings}
                onClose={() => setOpenSettings(null)}
                anchorEl={openSettings}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                }}
            >
                <Box sx={{ p: 1 }}>
                    <FormControlLabel checked={useClientAisles} onChange={toggleUseClientAisles} control={<Switch size='small' />} label="Aisle names by client" sx={{ fontSize: '10px' }} />
                </Box>
            </Popover>
        </>
    );
}