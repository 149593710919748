import { Dialog, DialogContent, DialogTitle, DialogActions, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useCallback, useMemo, useRef, cloneElement } from "react";
import { useSelector } from "react-redux";
import natsort from 'natsort';

import { LAYOUT_TOOLS } from "../tools/useLayoutAnnotations";

export default function DialogCreation({ open, onClose, dialogData, addNewLayer, layers, children }) {
    const { t } = useTranslation();
    const childrenRef = useRef(null);

    const currentTool = useMemo(() => dialogData?.tool, [dialogData]);

    const { globalLayers } = useSelector(state => state.layouts);
    const sortedGlobalLayers = useMemo(() => globalLayers?.layers?.map(({ name }) => name)?.sort(natsort({ insensitive: true })) || [], [globalLayers]);

    const TEXTS = useMemo(() => {
        return {
            [LAYOUT_TOOLS.ZONE]: { title: t('overseer_app.layouts.creation.new_zone', 'New Zone') },
            [LAYOUT_TOOLS.AISLE]: { title: t('overseer_app.layouts.creation.new_aisle', 'New Aisle') },
            [LAYOUT_TOOLS.EXCLUSION_ZONE]: { title: t('overseer_app.layouts.creation.new_exclusion_zone', 'New Exclusion Zone') },
            [LAYOUT_TOOLS.AISLE_SIDE]: { title: t('overseer_app.layouts.creation.add_linear', 'Add linear') },
        }
    }, [t]);

    const onSave = useCallback(() => {
        if (childrenRef.current) {
            const result = childrenRef.current.callbackOnSave();
            if (result) {
                onClose();
            }
        }
    }, [onClose, childrenRef]);

    const isUniqueName = useCallback((name, layer, subLayer = null) => {
        const layerData = layers?.[layer]?.layers || {};
        if (subLayer) {
            const subLayerData = layers?.[layer]?.layers?.[subLayer]?.subLayers || [];
            return !subLayerData?.some(subLayer => subLayer?.toLowerCase() === name?.toLowerCase());
        }
        return !Object.keys(layerData)?.some(layer => layer?.toLowerCase() === name?.toLowerCase());
    }, [layers]);

    return <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
        <DialogTitle sx={{ marginBottom: -1 }}>
            {TEXTS?.[currentTool]?.title || t('overseer_app.layouts.creation.new_annotation', 'New Annotation')}
        </DialogTitle>
        <DialogContent>
            {children && cloneElement(children, {
                ref: childrenRef,
                addNewLayer,
                dialogData,
                globalLayers: sortedGlobalLayers,
                isUniqueName,
            })}
        </DialogContent>
        <DialogActions>
            <Button color="secondary" onClick={onClose}>{t('overseer_app.layouts.cancel', 'Cancel')}</Button>
            <Button variant="contained" color="primary" onClick={onSave}>{t('overseer_app.layouts.save', 'Save')}</Button>
        </DialogActions>
    </Dialog>
}