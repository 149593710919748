import CallIcon from '@mui/icons-material/Call';
import DoneIcon from '@mui/icons-material/Done';
import {
  Box,
  Button,
  Card,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tab,
  Tabs,
  Tooltip,
  Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AlertBox from '../tools/AlertBox';
import TabPanel from '../tools/TabPanel';
import { capitalizeFirstLetter } from '../tools/utils';
import { a11yProps } from '../Utils';
import CallerDialog from './CallerDialog';

export default function CallerWidget(props) {
  const {
    callMotives,
    getCallerHistory,
    callerHistory,
    postCallerAction,
    loadingCallerResponse,
    newCallerActionResponse,
    storeInfo,
    storeLang,
    detailedUserInfo,
    isNoRobotSelected,
  } = props;

  const { t } = useTranslation();

  const [openState, setOpenState] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [contactId, setContactId] = useState(null);
  const [phones, setPhones] = useState(null);
  const [store, setStore] = useState(null);

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  useEffect(() => {
    if (storeInfo) {
      setStore(storeInfo.code);
      setContactId(storeInfo.store_contact_id);
      setPhones(storeInfo.phones);
    }
  }, [storeInfo]);

  useEffect(() => {
    if (store) {
      getCallerHistory(store);
    };
  }, [newCallerActionResponse]);

  const checkCallIcon = (row) => {
    let icon;
    if (row.ended_at !== null) {
      if (row.answered) icon = <DoneIcon style={{ color: 'green' }} />;
      else icon = <CallIcon style={{ color: 'red' }} />;
    } else icon = <CallIcon style={{ color: '#ffa400' }} />;
    return icon;
  };

  return (
    <Card
      sx={{
        height: '100%',
      }}
    >
      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          aria-label="caller tabs"
        >
          <Tab
            label={t('overseer_app.widget.caller', 'Caller')}
            {...a11yProps(0, { textTransform: 'initial' })}
          />
        </Tabs>
        <Tooltip
          title={
            !phones
              ? t(
                'overseer_app.widget.no_phones',
                'There are no contact telephone numbers for this store'
              )
              : loadingCallerResponse
                ? t('overseer_app.widget.call_in_progress', 'Call in progress')
                : ''
          }
          disableHoverListener={!loadingCallerResponse || phones}
        >
          <span>
            <Button
              variant="contained"
              style={{
                textTransform: 'none',
                marginRight: '0.5em',
              }}
              onClick={() => setOpenState(true)}
              disabled={loadingCallerResponse || !phones}
            >
              {t('overseer_app.widget.call_store', 'Call Store')}
            </Button>
          </span>
        </Tooltip>
      </Box>
      <TabPanel value={tabIndex} index={0}>
        {isNoRobotSelected ? (
          <AlertBox
            severity="info"
            sx={{
              mt: 1,
              height: '100%',
              minHeight: '10em',
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'center',
              width: '100%',
              backdropFilter: 'none',
            }}
            content={t('overseer_app.general.no_robot_selected', 'No robot selected')}
          />
        ) : (
          <Grid container>
            <Grid item xs={12} sx={{ overflowY: 'auto', maxHeight: '22em' }}>
              <List>
                {callerHistory &&
                  callerHistory.map((call, index) => {
                    // Get the number of hours and minutes ago that the calls where made
                    let hoursDiff = 0
                    let remainingMinutes = 0
                    if (call.started_at !== null) {
                      const now = new Date();

                      // Parse the started_at time string into a Date object and convert to the users computer timezone
                      let startedAt = new Date(call.started_at);
                      startedAt = startedAt - (startedAt.getTimezoneOffset() * 60000)
                      startedAt = new Date(startedAt);

                      // Calculate the time difference in milliseconds
                      const timeDiffMs = now.getTime() - startedAt.getTime();

                      // Convert milliseconds to minutes and hours
                      const minutesDiff = Math.floor(timeDiffMs / (1000 * 60));
                      hoursDiff = Math.floor(minutesDiff / 60);
                      remainingMinutes = minutesDiff % 60;
                    }
                    return (
                      <ListItem key={index}>
                        <ListItemIcon>{checkCallIcon(call)}</ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography variant="body1">
                              {call.last_status
                                ? capitalizeFirstLetter(
                                  call?.last_status.split('_').join(' ')
                                )
                                : call.calling ?
                                  t('overseer_app.caller.Calling', 'Calling')
                                  : ''
                              }
                            </Typography>
                          }
                          secondary={
                            <Box>
                              <Typography variant="body2">
                                <strong>
                                  {t('overseer_app.caller.Motive', 'Motive')}:
                                </strong>{' '}
                                {call.motive != null ?
                                  t(`overseer_app.alerts.${call.motive}`)
                                  :
                                  '-'
                                }
                              </Typography>
                              <Typography variant="body2">
                                <strong>
                                  {t('overseer_app.general.time', 'Time')}:
                                </strong>{' '}
                                {`${call.started_at_simplified} UTC `}
                                <i>{`(${t('overseer_app.caller.time_difference', `${hoursDiff} hours ${remainingMinutes} minutes ago`, { hoursDiff, remainingMinutes })})`}</i>
                              </Typography>
                              <Typography variant="body2">
                                <strong>
                                  {t(
                                    'overseer_app.caller.Phone_called',
                                    'Phone called'
                                  )}
                                  :
                                </strong>{' '}
                                {call.called_phone}
                              </Typography>
                              <Typography variant="body2">
                                <strong>
                                  {t('overseer_app.general.User', 'User')}:
                                </strong>{' '}
                                {call.username}
                              </Typography>
                            </Box>
                          }
                        />
                      </ListItem>)
                  })}
              </List>
            </Grid>
          </Grid>
        )}
      </TabPanel>
      <CallerDialog
        open={openState}
        setOpenState={setOpenState}
        callMotives={callMotives}
        callerHistory={callerHistory}
        postCallerAction={postCallerAction}
        loadingCallerResponse={loadingCallerResponse}
        store={store}
        contactId={contactId}
        phones={phones}
        storeLang={storeLang}
        detailedUserInfo={detailedUserInfo}
      />
    </Card>
  );
}
