import qs from 'querystring';

class HttpError extends Error {
  constructor(statusCode, message, endpoint) {
    super(message);
    this.name = 'HttpError';
    this.endpoint = endpoint;
    this.statusCode = statusCode;
    try {
      this.messageParsed = JSON.parse(message);
    } catch (e) {
      this.messageParsed = message ? message.replaceAll('"', '') : message;
    }
  }
}

export default function HTTPService() {
  const HTTP_METHOD_GET = 'GET';
  const HTTP_METHOD_POST = 'POST';
  const HTTP_METHOD_DELETE = 'DELETE';
  const HTTP_METHOD_PATCH = 'PATCH';
  const HTTP_METHOD_PUT = 'PUT';
  const HTTP_HEADER_ACCEPT = 'Accept';
  const HTTP_HEADER_CONTENT_TYPE = 'Content-Type';
  const HTTP_CREDENTIALS = 'credentials';
  const HTTP_CREDENTIALS_TYPE = 'include';

  const MIME_TYPE_JSON = 'application/json;charset=utf-8';

  return {
    getAuthenticated,
    postAuthenticated,
    deleteAuthenticated,
    patchAuthenticated,
    putAuthenticated,
  };

  function getAuthenticated({ url, query }) {
    return makeRequest({
      method: HTTP_METHOD_GET,
      url,
      query,
    });
  }

  function postAuthenticated({ url, body, accessToken, content }) {
    return makeRequest({
      method: HTTP_METHOD_POST,
      url,
      body,
      content,
    });
  }

  function patchAuthenticated({ url, body, accessToken, content }) {
    return makeRequest({
      method: HTTP_METHOD_PATCH,
      url,
      body,
      content,
    });
  }

  function putAuthenticated({ url, body, accessToken, content }) {
    return makeRequest({
      method: HTTP_METHOD_PUT,
      url,
      body,
      content,
    });
  }

  function deleteAuthenticated({ url, query, body, accessToken, content }) {
    return makeRequest({
      method: HTTP_METHOD_DELETE,
      url,
      query,
      body,
      content,
    });
  }

  async function makeRequest({
    method,
    url,
    content,
    query = {},
    body = {},
    headers = {},
  }) {
    const finalUrl =
      query && Object.keys(query).length
        ? `${url}?${qs.stringify(query)}`
        : url;

    const finalHeaders = Object.keys(headers).reduce((acc, headerKey) => {
      acc.append(headerKey, headers[headerKey]);
      return acc;
    }, new Headers());

    finalHeaders.set(HTTP_HEADER_ACCEPT, MIME_TYPE_JSON);

    if (
      content !== 'raw' &&
      (method === HTTP_METHOD_POST ||
        method === HTTP_METHOD_PATCH ||
        method === HTTP_METHOD_PUT ||
        method === HTTP_METHOD_DELETE)
    ) {
      finalHeaders.set(HTTP_HEADER_CONTENT_TYPE, MIME_TYPE_JSON);
    }

    const requestDescription =
      method === HTTP_METHOD_POST ||
        method === HTTP_METHOD_PATCH ||
        method === HTTP_METHOD_PUT ||
        method === HTTP_METHOD_DELETE
        ? {
          method,
          headers: finalHeaders,
          body: content !== 'raw' ? JSON.stringify(body) : body,
          [HTTP_CREDENTIALS]: HTTP_CREDENTIALS_TYPE,
        }
        : {
          method,
          headers: finalHeaders,
          [HTTP_CREDENTIALS]: HTTP_CREDENTIALS_TYPE,
        };

    let response;
    try {
      response = await fetch(finalUrl, requestDescription);

      if (!response.ok) {
        const responseText = await response.clone().text();
        throw new HttpError(response.status, responseText, finalUrl);
      }

      const contentType = response.headers.get('content-type');
      let responseBody = '';
      if (contentType && contentType.includes('text/plain')) {
        const reader = response.body.getReader();
        const decoder = new TextDecoder();
        while (true) {
          const { done, value } = await reader.read();
          if (done) {
            break;
          }
          responseBody += decoder.decode(value, { stream: true });
        }
      } else if (contentType && contentType.includes('application/json')) {
        responseBody = await response.json();
      } else {
        console.log(
          `%c No handler for content type: ${contentType} in ${finalUrl}`,
          'color: #BB0042'
        );
        responseBody = await response.text();
      }

      return responseBody;
    } catch (error) {
      if (error instanceof HttpError) {
        throw error;
      }
      console.log(
        `%c error fetching with error: ${error} in ${finalUrl}`,
        'color: #BB0042'
      );
      throw new Error(error);
    }
  }
}
